import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';
import { addPageData } from "../../store/form/actions";
import Comment from "./Comment";


const styles = theme => ({
    checkbox: {
        marginRight: 40,
        color: '#afafaf',
    },
    listtext: {
        fontSize: '13px'
    },
    checked: {},
    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
});


class Singlechoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            single_answer: ''
        };

    }

    componentDidMount() {

        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            let index = this.findIndex(this.props.form.answer_data[this.props.data.key].answer[0], this.props.data.data);
            // console.log(index)
            this.setState({ single_answer: index })
        } else {
            if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {

                let index = this.findIndex(this.props.data.auto, this.props.data.data);
                this.setState({ single_answer: index })
                this.props.dispatchaddPageData(this.props.data.key, typeof (this.props.data.auto[0]) == 'object' ? this.props.data.auto : [this.props.data.auto], this.props.data.question_text)
            }


        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {
        //console.log(prevProps.data.auto, this.props.data.auto)
        if (this.props.data.auto != prevProps.data.auto) {
            //if (this.props.data.auto && !this.props.form.answer_data[this.props.data.key]) {
            let index = this.findIndex(this.props.data.auto, this.props.data.data);
            this.setState({ single_answer: index })
            this.props.dispatchaddPageData(this.props.data.key, typeof (this.props.data.auto[0]) == 'object' ? this.props.data.auto : [this.props.data.auto], this.props.data.question_text)
            //}


        }
    }

    findIndex = (data, container) => {
        function hasObject(element, target) {
            console.log(element, target);
            return element.toString() == target.toString();
        }

        let i = container.findIndex((n) => {
            let a = hasObject(n, data);
            if (a == true) {
                return true
            }
        });
        console.log(i)
        return i
    }
    handleChange = index => (event) => {


        this.setState({ single_answer: index })

        this.props.getDataFromComponent({ 'single_answer': index }, this.props.data.key, this.props.data.question_text, this.props.data.data)
    };

    doNothing=()=>{}

    render() {
        const { classes, form, data, isAnnotationModeOn } = this.props;

        try {
            return (
                <div className={classes.root}>
                    <List component="nav">
                        {this.props.data.data.map((n, i) => {

                            return (
                                <ListItem 
                                    dense
                                    button={!isAnnotationModeOn ? true : false}
                                    onClick={!isAnnotationModeOn ? this.props.data.read_only == true ? this.doNothing() : this.handleChange(i) : undefined}
                                >
                                    <Radio
                                        disabled={this.props.data.read_only}
                                        checked={this.state.single_answer === i}
                                        onChange={!isAnnotationModeOn ? this.props.data.read_only == true ? this.doNothing() : this.handleChange(i) : undefined}
                                        value={i}
                                        id={`single-choice-${this.props.data.key}`}
                                        classes={{
                                            root: this.props.data.style || classes.checkbox,
                                            checked: classes.checked,
                                        }}
                                    />

                                    <div>
                                        {/*<div style={{textAlign:'left',display:'inline'}}>*/}
                                        {/*<ListItemText className={classes.listtext}  primary={`${n[0]}:`} />*/}

                                        {/*</div>*/}
                                        <div style={{ textAlign: 'left', display: 'inline' }}>

                                            <ListItemText className={classes.listtext} disableTypography={true}
                                                primary={<p id={`${data.key}-${i}-answer-text`} style={{
                                                    marginBottom: 0,
                                                    fontWeight: 'normal'
                                                }}>{n[1]}</p>} />
                                        </div>


                                    </div>
                                </ListItem>
                            )
                        })}


                    </List>
                </div>
            );
        } catch (e) {
            return (<h5>Error encountered</h5>)
        }

    }
}


const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {
    dispatchaddPageData: (key, answer, question_text) => addPageData({ key, answer, question_text }),
};


Singlechoice.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Singlechoice));
