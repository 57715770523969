import { FormActionTypes } from "./constants";
import { RequestActionTypes } from "../request/constants";

export const initialState = {
  page_action: "",
  result_data: [],
  answer_data: {},
  current_index: 0,
  form_length: "",
  request_data: [],
  password_alert: false,
  isReady: false,
  submissionUrl: "",
  backgroundColor: "",
  report_type: "",
  loading: false,
  comments: {},
};

export const reducer = (state = initialState, action) => {
  let data = {};
  let answer = {};
  let current_index;
  data = action.payload;
  switch (action.type) {
    case FormActionTypes.GET_MAIN_PAGE_SUCCESS:
      return {
        ...state,
        current_index: 0,
        submissionUrl: data.submissionUrl,
        password_alert: false,
        backgroundColor: data.backgroundColor,
        report_type: data.report_type,
        loading: false,
      };
    case FormActionTypes.GET_MAIN_PAGE_FAILED:
      return { ...state, request_data: [], isReady: false };
    case FormActionTypes.GET_COMMENTS_SUCCESS:
      return { ...state, comments: data };
    case FormActionTypes.ADD_PAGE_ANSWER:
      let components = state.request_data[state.current_index].components;
      let component = components.filter((com) => com.key == data.key)[0];

      let comment = null;
      if (answer[data.key] && answer[data.key].comment)
        comment = answer[data.key].comment;
      if (component && !component.comment) comment = false;

      answer = state.answer_data;
      answer[data.key] = {
        ...answer[data.key],
        key: data.key,
        answer: data.answer,
        comment: comment,
        question_text: data.question_text,
        format: data.format,
      };
      return { ...state, answer_data: answer };
    
    case FormActionTypes.ADD_PAGE_COMMENT:

      const newComment = data.comment;
      let newComments;
      if (state.comments[data.key] === undefined) {
        newComments = [newComment]
      } else {
        newComments = [...state.comments[data.key], newComment];
      }

      const updatedComments = { ...state.comments, [data.key]: newComments }

      
      return { ...state, comments: updatedComments };
    case FormActionTypes.DELETE_FILE_UPLOAD:
      answer = state.answer_data;
      answer[data.key] = {
        ...answer[data.key],
        answer: [],
      };
      return { ...state, answer_data: answer };

    case FormActionTypes.ADD_PAGE_ANSWER_CALENDAR:
      let answer_calendar = state.answer_data;
      answer_calendar[data.key] = {
        key: data.key,
        answer: data.answer,
        question_text: data.question_text,
        format: data.format,
        rawValue: data.rawValue,
        outputFormat: data.outputFormat,
        timezone: data.timezone,
      };
      return { ...state, answer_data: answer_calendar };

    case FormActionTypes.ADD_PAGE_ANSWER_TABLE:
      let answer_table = state.answer_data;

      answer_table[data.key] = {
        key: data.key,
        question_text: data.question_text,
        format: data.format,
        exportFormat: data.exportFormat,
        tableAnswerKeys: data.tableAnswerKeys,
        colNames: data.colNames,
        answer: {
          ...data.answer // Spread either the row keys or column keys
        }
      }
      return { ...state, answer_data: answer_table }

    case FormActionTypes.GET_NEXT_PAGE:
      return { ...state, isReady: true };
    case FormActionTypes.GET_NEXT_PAGE_SUCCESS:
      let targetIndex = action.data.queryParams.index;
      current_index = state.current_index;
      return {
        ...state,
        current_index: targetIndex,
        isReady: true,
        password_alert: false,
        loading: false,
      };
    case FormActionTypes.GO_BACK_TO_PRE:
      let pre_stage = state.request_data;
      current_index = state.current_index;
      return {
        ...state,
        current_index: current_index - 1,
        loading: false,
      };
    case FormActionTypes.GO_BACK_TO_ANY:
      return { ...state, current_index: data.index, loading: false };
    case FormActionTypes.UNMOUNT_FROM:
      return {
        ...state,
        page_action: "",
        result_data: [],
        answer_data: {},
        current_index: 0,
        form_length: "",
        request_data: [],
        password_alert: false,
        isReady: false,
        submissionUrl: "",
        report_type: "",
      };
    case RequestActionTypes.REQUEST_COMPLETED:
      return { ...state };
    case FormActionTypes.PASSWORD_ALERT:
      return { ...state, password_alert: true };
    case FormActionTypes.CLEAR_PASSWORD_ALERT:
      return { ...state, password_alert: false };
    case FormActionTypes.FORM_LOADING:
      return { ...state, loading: true };
    case FormActionTypes.UPDATE_FORM_STATE:
        const { formSession, formMeta } = data;
        
        // Create init form session and form meta objects
        let formSessionObj = {
          projectSessionId: ""
        };

        let formMetaObj = {};

        // If form meta exists
        // Set the request data and form length
        if (formMeta && formMeta.data) {
          formMetaObj["request_data"] = formMeta.data.pages;
          formMetaObj["form_length"] = formMeta.data.form_length;
        }

        // If form session exists, set the project session id and the answer data
        if (formSession && formSession.data && formSession.data.data) {
          formSessionObj["projectSessionId"] = formSession.data.projectSessionId ? formSession.data.projectSessionId : "";
          formSessionObj["answer_data"] = formSession.data.data
        }

        // Create the updated state object and return
        let updatedState = {
          ...state,
          ...formMetaObj,
          ...formSessionObj,
          isReady: true,
        }

        return updatedState;
    default:
      return state;
  }
};

export default reducer;
