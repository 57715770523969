import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  convertTableDataToCorrectFormat,
  getTableAnswerKeys,
  isCellDisabled,
  isCellReadOnly,
  parseAnswerData,
} from "./Util/Helpers";
import { emptyTableWithDatetime } from "./Util/HandleInitialiseTable";

// MUI
import Grid from "@material-ui/core/Grid";
import { styled, createMuiTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Input types
import TextInput from "./Inputs/TextInput";
import DatetimeInput from "./Inputs/DatetimeInput";
import NumberInput from "./Inputs/NumberInput";
import DropdownInput from "./Inputs/DropdownInput";

const theme = createMuiTheme({});

const ColumnNameText = styled("span")({
  color: "rgba(0, 0, 0, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  width: "100%",
  whiteSpace: "nowrap",
});

const Table = styled(Grid)({
  margin: "16px 0px",
  paddingBottom: "16px",
  [theme.breakpoints.up("sm")]: {
    overflow: "auto",
  },
});

const Row = styled(Grid)({
  marginBottom: "15px",
  [theme.breakpoints.only("xs")]: {
    marginBottom: "60px !important",
  },
});

const RowItem = styled(Grid)({
  minWidth: "200px",
});

const AnnotationCell = styled("div")({
  border: "1px solid #d5d5d5",
  padding: "18.5px 14px",
  borderRadius: "4px",
  fontSize: "14px",
  marginTop: "5px",
  overflow: "hidden",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap"
});

function TableInput(props) {
  const { data, getDataFromComponent, tableErrors, setRequiredTableErrors, isAnnotationModeOn } = props;

  const [columns, setColumns] = useState(null);
  const [numRows, setNumRows] = useState(null);
  const [exportType, setExportType] = useState(null);

  // Get the table answer keys
  const [tableAnswerKeys, setTableAnswerKeys] = useState(getTableAnswerKeys(data));

  const [tableAnswers, setTableAnswers] = useState(null);

  // Initial on load use effect
  useEffect(() => {
    // Set data structure
    setColumns(data.data);
    setNumRows(parseInt(data.numRows));
    setExportType(data.exportType);

    let answerData = props.form.answer_data[data.key];

    // If there already exists answer data,
    // Try to set it into the state
    // Else create an empty table
    if (answerData) {
      try {
        let parsedAnswerData = parseAnswerData(
          answerData,
          data.data,
          data.exportType,
          data.rowLabels
        );
        if (parsedAnswerData == null) throw "Error loading answers";
        setTableAnswers(parsedAnswerData);
      } catch (e) {
        // If there is an error loading the table answers from the form session
        let noAnswerTable = emptyTableWithDatetime(data);
        updateStateAndTable(noAnswerTable);
      }
    } else {
      // This is a new table with no answerData
      let noAnswerTable = emptyTableWithDatetime(data);
      updateStateAndTable(noAnswerTable);
    }
  }, []);

  // Use effect for handling if the table structure changes
  useEffect(() => {
    let answerKeys = getTableAnswerKeys(data);

    setTableAnswerKeys(answerKeys);
    // Converts the data into the correct format
    let updatedTable = {
      table_answer: convertTableDataToCorrectFormat(
        exportType,
        tableAnswers,
        columns,
        data.rowLabels
      ),
    };

    if (updatedTable.table_answer != null) {
      // Save the data in the redux state
      getDataFromComponent(
        updatedTable,
        data.key,
        data.question_text,
        data.data,
        null,
        null,
        answerKeys
      );
    }
  }, [data.data, data.exportType, data.numRows, data.rowLabels]);

  // Updates both the local and global states
  // With the given answer table
  const updateStateAndTable = (givenAnswerTable) => {
    setTableAnswers(givenAnswerTable); // Set to empty table

    let updated_table = {
      table_answer: convertTableDataToCorrectFormat(
        data.exportType,
        givenAnswerTable,
        data.data,
        data.rowLabels
      ),
    };

    getDataFromComponent(
      updated_table,
      data.key,
      data.question_text,
      data.data,
      null,
      null,
      tableAnswerKeys
    );
  };

  // Used to determine if on xs small or not
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  // Updates a value in the table of answers
  const handleUpdateValue = (rIdx, cIdx, value, isCalc) => {
    let newTable = [...tableAnswers];

    if (tableAnswers[rIdx][cIdx].value != null) {
      newTable[rIdx][cIdx].value.calculatedValue = value;

      // If it isn't a calc refresh, overwrite the default Value
      if (!isCalc) newTable[rIdx][cIdx].value.defaultValue = value;
    } else {
      newTable[rIdx][cIdx].calculatedValue = value;

      // If it isn't a calc refresh, overwrite the default Value
      if (!isCalc) newTable[rIdx][cIdx].defaultValue = value;
    }

    updateStateAndTable(newTable);

    let newTableErrors = { ...tableErrors };
    delete newTableErrors[data.key];
    setRequiredTableErrors(newTableErrors);
  };

  // Render the valid input type
  const renderInputType = (col, rowIdx, colIdx) => {
    let dataObject = {
      answers: tableAnswers,
      column: col,
      rowIndex: rowIdx,
      columnIndex: colIdx,
      updateValue: handleUpdateValue,
      isReadOnly:
        data.read_only || isCellReadOnly(data.tableCells, col.id, rowIdx),
      tableCells: data.tableCells,
      size: "normal"
    };

    if (!isAnnotationModeOn) {
      switch (col.type) {
        case "text":
          return <TextInput data={dataObject} />;
        case "datetime":
          return <DatetimeInput data={dataObject} />;
        case "number":
          return <NumberInput data={dataObject} />;
        case "dropdown":
          return <DropdownInput data={dataObject} />;
        default:
          return "Not a valid type";
      }
    } else {
        return <AnnotationCell id={`${data.key}-${rowIdx}-${colIdx}`}>{tableAnswers[rowIdx][colIdx].calculatedValue}</AnnotationCell>
      }

  };

  // Render each column
  const renderColumn = (rowIndex) => {
    return (
      <Row
        item
        container
        style={{ gap: isMobile ? "10px" : "15px" }}
        wrap={!isMobile ? "nowrap" : "wrap"}
        key={rowIndex}
        xs={12}
      >
        {columns.map((col, columnIndex) => {
          return (
            <RowItem
              item
              xs={12}
              key={rowIndex.toString() + columnIndex.toString()}
            >
              <Grid item xs={12}>
                {isMobile ? (
                  <ColumnNameText id={`${data.key}-column-${columnIndex}`}>
                    {col.columnName != ""
                      ? `${col.columnName} ${col.required ? "*" : ""}`
                      : "Untitled"}
                  </ColumnNameText>
                ) : (
                  rowIndex === 0 && (
                    <ColumnNameText id={`${data.key}-column-${columnIndex}`}>
                      {col.columnName != ""
                        ? `${col.columnName} ${col.required ? "*" : ""}`
                        : "Untitled"}
                    </ColumnNameText>
                  )
                )}
              </Grid>
              {!isCellDisabled(data.tableCells, col.id, rowIndex) && (
                <Grid item xs={12}>
                  {renderInputType(col, rowIndex, columnIndex)}
                </Grid>
              )}
            </RowItem>
          );
        })}
      </Row>
    );
  };

  try {
    return (
      columns &&
      numRows &&
      exportType &&
      tableAnswers && (
        <Table container>
          {[...Array(numRows)].map((e, i) => renderColumn(i))}
        </Table>
      )
    );
  } catch (e) {
    return <h5>Error encountered</h5>;
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {};

TableInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableInput);
