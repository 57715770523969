export const answerDecoder = (answer, answer_list) => {
  let result = [];
  const page_answers = answer_list;
  switch (Object.keys(answer)[0]) {
    case "multiple_answer":
       let arr =[]
      for (let i in answer.multiple_answer) {
        if (answer.multiple_answer[i] == true) {
          let search_result = page_answers.find(function(element) {
            return element[0] == i;
          });
          arr.push(search_result);
        }
      }
      return arr;

    case "single_answer":
      return [page_answers[answer.single_answer]];
    case "text_answer":
      return [answer.text_answer];
    case "number_answer":
      return answer.number_answer ? [parseFloat(answer.number_answer)] : [];
    case "map_answer":
      return [
        ["lat", answer.map_answer.lat],
        ["lng", answer.map_answer.lng]
      ];
    case "drawable_map_answer":
      return [answer.drawable_map_answer];
    case "calendar_answer":
      return [answer.calendar_answer];
    case "single_select":
      return [
        page_answers.find(function(element) {
          return element[1] == answer.single_select;
        })
      ];
    case "upload_media":
      return [answer.upload_media];
    case "list_input":
      result = ["list"];
      for(let i in answer.list_input){
        result.push(answer.list_input[i])
      }
      return result
    case "esignature_answer":
      return [answer.esignature_answer];
    case "table_answer":
      return answer.table_answer;
    case "repeatable_sections_answer":
      return answer.repeatable_sections_answer;
    default:
      return [answer];
  }
};

export const answerEncoder = answer => {
  // console.log(answer)
  let result = "";
  switch (Object.keys(answer)[0]) {
    case "multiple_answer":
      for (let i in answer.multiple_answer) {
        if (answer.multiple_answer[i] == true) {
          result = result + "1";
        } else {
          result = result + "0";
        }
      }
      return result;
    case "single_answer":
      return answer.single_answer;
    case "text_answer":
      return answer.text_answer;
    case "number_answer":
      return answer.number_answer;
    case "map_answer":
      return answer.map_answer;
    case "calendar_answer":
      return answer.calendar_answer;
    case "upload_media":
      return answer.upload_media;
    case "list_input":
      return answer.list_input;
    default:
      return answer;
  }
};
