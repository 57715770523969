import React, { useEffect } from "react";

// Material UI
import { Box } from "../Util/AnnotationStyledComponents";

function ThreadBox({ children, isFocused, isMobile }) {
    useEffect(() => {
        try {
            document.getElementById("focusedAnnotation").scrollIntoView();
        } catch (e) {}
    }, [isFocused]);

    return (
        <Box id={isFocused && "focusedAnnotation"} isIndented={isFocused} isMobile={isMobile}>
            {children}
        </Box>
    );
}

export default ThreadBox;
