import React from "react";
import { Map, MapControl, Marker, Popup, ScaleControl, TileLayer, withLeaflet } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import GeoSearch from "./GeoSearch";
import { withStyles } from "@material-ui/core/styles/index";
import { addPageData } from "../../store/form/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-leaflet-fullscreen/dist/styles.css";
import FullscreenControl from "react-leaflet-fullscreen";
import Control from "react-leaflet-control";
import Comment from "./Comment";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LocationOffOutlinedIcon from '@material-ui/icons/LocationOffOutlined';

const styles = theme => ({
    Button: {
        color: "#2196F3",
        backgroundColor: "#444444",
        "&:hover": {
            backgroundColor: "#2f2f2f"
        }
    }
});

class CustomMap extends React.Component {
    constructor() {
        super();
        this.mapRef = React.createRef();
        this.refmarker = React.createRef();
        this.state = {
            zoom: 13,
            hasLocation: false,
            latlng: {
                lat: -33.89911682453446,
                lng: 151.2072569896768
            },
            draggable: true,
            showMarker: true
        };
    }

    // mapRef = createRef(<Map>)
    handleClick = e => {
        // console.log(this.mapRef)
        const map = this.mapRef.current;
        if (map != null) {
            map.leafletElement.locate();
            this.setState({ latlng: map.leafletElement.locate().options.center });
        }
    };

    componentDidMount() {
        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            const current = this.state.latlng;
            current.lat = this.props.form.answer_data[
              this.props.data.key
              ].answer[0][1];
            current.lng = this.props.form.answer_data[
              this.props.data.key
              ].answer[1][1];
            this.setState({
                hasLocation: true,
                latlng: current
            });
        } else if (this.props.data.auto) {
            // Handle pre-filled answer accordingly

            // Parse the lat and long
            const parsedLatLng = JSON.parse(this.props.data.auto);
            if (!parsedLatLng || parsedLatLng.length < 2) return;

            // Construct lat lng object
            let current = {
                "lat": parsedLatLng[0],
                "lng": parsedLatLng[1],
            };

            // Set in state
            this.setState({
                hasLocation: true,
                latlng: current
            });

            // Add into form answer data
            this.props.getDataFromComponent(
                { map_answer: current },
                this.props.data.key,
                this.props.data.question_text
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.auto.toString() != this.props.data.auto.toString()) {
            if (
              this.props.data.auto &&
              !this.props.form.answer_data[this.props.data.key]
            ) {
                const current = this.state.latlng;
                current.lat = this.props.data.auto[0];
                current.lng = this.props.data.auto[1];
                this.setState({
                    hasLocation: true,
                    latlng: current
                });
                this.props.getDataFromComponent(
                  { map_answer: current },
                  this.props.data.key,
                  this.props.data.question_text
                );
            }
        }
    }

    handleLocationFound = e => {
        this.setState({
            hasLocation: true,
            latlng: e.latlng
        });
        this.props.getDataFromComponent(
          { map_answer: e.latlng },
          this.props.data.key,
          this.props.data.question_text
        );
    };
    toggleDraggable = () => {
        this.setState({ draggable: !this.state.draggable });
    };
    placeMarker = e => {
        this.setState({
            hasLocation: true,
            zoom: e.target._zoom,
            latlng: e.latlng
        });

        this.props.getDataFromComponent(
          { map_answer: { lat: e.latlng.lat, lng: e.latlng.lng } },
          this.props.data.key,
          this.props.data.question_text
        );
    };
    updatePosition = () => {
        const marker = this.refmarker.current;
        if (marker != null) {
            this.setState({
                marker: marker.leafletElement.getLatLng()
            });
        }
        this.props.getDataFromComponent(
          {
              map_answer: {
                  lat: marker.leafletElement.getLatLng().lat,
                  lng: marker.leafletElement.getLatLng().lng
              }
          },
          this.props.data.key,
          this.props.data.question_text
        );
        // console.log(marker.leafletElement.getLatLng())
    };

    handleShowMarker = () => {
        this.setState({
            showMarker : !this.state.showMarker
        })
    }

    render() {
        const marker = this.state.hasLocation ? (
          <Marker
            draggable={
                this.props.data.read_only == true ? false : this.state.draggable
            }
            onDragend={this.updatePosition}
            position={this.state.latlng}
            ref={this.refmarker}
          >
              <Popup minWidth={90}>
                    <span onClick={this.toggleDraggable}>
                        {this.state.draggable ? "DRAG MARKER" : "MARKER FIXED"}
                    </span>
              </Popup>
          </Marker>
        ) : null;
        const SearchBar = withLeaflet(SearchMap);
        const { classes, data } = this.props;
        return (
          <div>
              <Map
                style={{ width: "100%", borderRadius: "8px" }}
                center={this.state.latlng}
                length={4}
                onClick={this.props.data.read_only == true ? null : this.placeMarker}
                onLocationfound={this.handleLocationFound}
                ref={this.mapRef}
                zoom={this.state.zoom}
              >
                  <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  />
                  <FullscreenControl position="topleft" />
                  <GeoSearch />
                  <Control position="topleft">
                      <div className={"customize-button"} onClick={this.handleClick}>
                          <img src={"/images/location.svg"} />
                      </div>
                  </Control>
                  
                  <Control position="bottomleft">
                      <ScaleControl></ScaleControl>
                  </Control>
                  {this.state.showMarker ? marker : ""}
              </Map>

          </div>
        );
    }
}

class SearchMap extends MapControl {
    createLeafletElement() {
        return GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: "bar",
            showMarker: true,
            showPopup: true,
            autoClose: true,
            retainZoomLevel: false,
            animateZoom: true,
            keepResult: false,
            searchLabel: "Search"
        });
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {
    dispatchaddPageData: (key, answer, question_text) =>
      addPageData({ key, answer, question_text })
};

CustomMap.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomMap));