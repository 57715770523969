import React, { useState, useEffect } from "react";

// MUI
import { Dialog, DialogContent, DialogTitle, Grid, styled, DialogActions, Button, CircularProgress, Hidden, useMediaQuery, createMuiTheme } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { ArrowBack, BorderColor, CheckCircle } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

// Util
import axios from "axios";
import config from "../../../config";
import FormRenderItem from "./FormRenderItem";
import { BackIcon, CompletedIcon, CustomGrid, EditingIcon, FormItem, RefreshIcon, SpanItem, Transition } from "./CommonComponents";
import { format } from "../../utils/Date";
import { StyledSkeleton } from "../../TextAnnotations/Util/AnnotationStyledComponents";

import { Button as VardogyirButton } from "vardogyir-ui";

const theme = createMuiTheme({});

const NavigationGrid = styled(Grid)({
    marginRight: "32px !important",
});

const StyledButton = styled(Button)(({ bgColor, textColor, hoverBgColor }) => ({
    background: bgColor,
    color: textColor,
    padding: "8px 16px",
    "&:hover": {
        background: hoverBgColor,
    },
}));

const StyledDialogContent = styled(DialogContent)({
    overflow: "hidden !important",
});

const StyledAccordion = styled(Accordion)({
    boxShadow: "none",
});

const ListIcon = styled(FormatListBulletedIcon)({
    height: "16px",
    width: "auto",
});

const TopTitle = styled("span")({
    fontSize: "14px",
    fontWeight: "bold",
});

function NavigationMenu({
    pagesMeta,
    visitedPages,
    handleNext,
    form,
    parsePlaceholder,
    processIdToken,
    projectSessionId,
    formId,
    formTitle,
    handleSaveProgress,
    isSaving,
    showResubmitDialog,
    closeResubmitDialog,
    handleSubmit,
    timezone,
    threadId
}) {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        defaultMatches: true,
    });
    const [formIdToSessionMap, setFormIdToSessionMap] = useState({});
    const [formIdPathMap, setFormIdPathMap] = useState({});
    const [formIdToNameMap, setFormIdToNameMap] = useState({});
    const [reversedPath, setReversedPath] = useState({});

    const [loadingPaneInfo, setLoadingPaneInfo] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const [showRedirectDialog, setShowRedirectDialog] = useState("");

    useEffect(async () => {
        setLoadingPaneInfo(true);

        if (projectSessionId) {
            const idToken = await processIdToken(); // Grab id token
            const json = await getNavigationPaneInfo(projectSessionId, idToken);

            if (json && json.data && json.data.data) {
                let forwardPath = json.data.data.formIdPathMap;
                setFormIdPathMap(forwardPath);
                setReversedPath(invertMap(forwardPath));
                setFormIdToSessionMap(json.data.data.formIdToSessionMap);
                setFormIdToNameMap(json.data.data.formIdToNameMap);
            }
        } else {
            let noSessionMap = {};
            noSessionMap[formId] = formTitle;
            setFormIdToNameMap(noSessionMap);
        }

        setLoadingPaneInfo(false);
    }, []);

    const invertMap = (givenPath) => {
        let inv = {};
        for (let [key, value] of Object.entries(givenPath)) {
            inv[value] = key;
        }
        return inv;
    };

    const getNavigationPaneInfo = async (projectSessionId, idToken) => {
        let url = `${config.API_ADDRESS}/get_form_navigation_info?projectSessionId=${projectSessionId}&form_id=${formId}`;
        if (threadId) url += `&threadId=${threadId}`;
        let headers = {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken,
            },
        };

        return await axios(url, headers);
    };

    const getFormLinkNavigationMenu = () => {
        if (loadingPaneInfo) {
            return (
                <NavigationGrid item xs={2}>
                    {getLoader()}
                </NavigationGrid>
            );
        }

        let componentIds = getComponentIdsOrder();

        if (isMobile) {
            return (
                <Grid item xs>
                    {getContent(componentIds)}
                </Grid>
            );
        } else {
            return (
                <NavigationGrid item xs={2}>
                    <Grid container spacing={1} paddingRight={2} style={{ position: "sticky", top: "100px" }}>
                        <Grid item xs={12}>
                            {getContent(componentIds)}
                        </Grid>
                    </Grid>
                </NavigationGrid>
            );
        }
    };

    const getContent = (componentIds) => {
        return (
            <StyledAccordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container display="flex" alignItems="center" justifyContent="flex-start" spacing={1}>
                        <CustomGrid item>
                            <ListIcon />
                        </CustomGrid>
                        <CustomGrid item>
                            <TopTitle>Sections</TopTitle>
                        </CustomGrid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        {componentIds.map((c) => {
                            return (
                                <Grid item xs={12}>
                                    <FormRenderItem
                                        key={c}
                                        formId={c}
                                        currFormId={formId}
                                        formIdToSessionMap={formIdToSessionMap}
                                        formIdToNameMap={formIdToNameMap}
                                        pagesMeta={pagesMeta}
                                        visitedPages={visitedPages}
                                        handleNext={handleNext}
                                        form={form}
                                        parsePlaceholder={parsePlaceholder}
                                        projectSessionId={projectSessionId}
                                        setShowRedirectDialog={setShowRedirectDialog}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        );
    };

    const getComponentIdsOrder = () => {
        let firstCompId = findFirstCompId();
        let order = [];
        let curr = firstCompId;

        while (curr) {
            order.push(curr);
            curr = formIdPathMap[curr];
            if (!(curr in formIdToSessionMap)) break;
        }
        return order;
    };

    const findFirstCompId = () => {
        let curr = formId;

        while (curr) {
            if (curr in reversedPath) {
                curr = reversedPath[curr];
            } else {
                return curr;
            }
        }
    };

    const getResubmitDialog = () => {
        return (
            <Dialog open={showResubmitDialog} maxWidth="sm" TransitionComponent={Transition}>
                <DialogTitle>
                    <h4 style={{ textAlign: "start" }}>Resubmitting will overwrite existing sessions </h4>
                </DialogTitle>

                <StyledDialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <p style={{ textAlign: "start" }}>
                                This form has already been submitted. By resubmitting this form <b>any existing form session data will be overwritten</b>.
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            {getSessionList()}
                        </Grid>
                    </Grid>
                </StyledDialogContent>

                <DialogActions>
                    <Grid container spacing={1} display="flex" justify="flex-end">
                        <Grid item>
                            <StyledButton variant="outlined" onClick={closeResubmitDialog}>
                                CANCEL
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton variant="contained" startIcon={<Refresh />} onClick={handleSubmit} bgColor="#000" textColor="#FFF" hoverBgColor="rgba(0, 0, 0, 0.9)">
                                RESUBMIT
                            </StyledButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    };

    const getSessionList = () => {
        let isAfter = false;
        let compIdsOrder = getComponentIdsOrder();

        return (
            <Grid container spacing={1}>
                {compIdsOrder.map((cId) => {
                    let isActive = cId === formId;
                    let sessionInfo = formIdToSessionMap[cId];
                    if (!sessionInfo) sessionInfo = {};

                    let item = (
                        <Grid item xs={12}>
                            <FormItem isActive={isActive} notInteractive color={isActive ? "rgba(33, 150, 243, 1)" : isAfter ? "rgba(225, 0, 0, 1)" : "rgba(111, 111, 111, 1)"}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item>
                                                {isActive ? <RefreshIcon isActive={isActive} fontSize="small" /> : isAfter ? <BackIcon fontSize="small" /> : <CompletedIcon fontSize="small" />}
                                            </Grid>
                                            <CustomGrid item xs>
                                                <SpanItem>
                                                    {formIdToNameMap[cId] ? formIdToNameMap[cId] : `Untitled`}{" "}
                                                    {`${getSubmittedDateText(sessionInfo)}${isActive ? " will be resubmitted" : isAfter ? " will be overwritten" : ""} `}
                                                </SpanItem>
                                            </CustomGrid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormItem>
                        </Grid>
                    );

                    if (isActive) isAfter = true;
                    return item;
                })}
            </Grid>
        );
    };

    const getSubmittedDateText = (session) => {
        try {
            if ((session && session.status && session.status !== "SUCCESS") || !session || !session.endAt) return ` (not yet submitted) `;
            let formatted = format(session.endAt, timezone, "relative");
            return ` (submitted ${formatted}) `;
        } catch (e) {
            return "";
        }
    };

    const getLoader = () => {
        return (
            <Grid container spacing={1} style={{ position: "sticky", top: "110px" }}>
                {[1, 2, 3].map(() => {
                    return (
                        <Grid item xs={12}>
                            <StyledSkeleton variant="rect" margin="0px !important" />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const handleRedirect = () => {
        let url = `/form/${showRedirectDialog}/${formIdToSessionMap[showRedirectDialog].formSessionId}`;
        window.open(url, "_self");
    };

    const getFormNavigateDialog = () => {
        // Need to check if it's ahead
        let isAfter = true;

        let curr = formId;
        while (curr) {
            curr = reversedPath[curr];
            if (curr === showRedirectDialog) {
                isAfter = false;
                break;
            }
        }

        return (
            <Dialog open={showRedirectDialog} maxWidth="sm" TransitionComponent={Transition}>
                <DialogTitle>
                    <h4 style={{ textAlign: "start" }}>{isAfter ? "Resubmit this form?" : "Save progress"}</h4>
                </DialogTitle>

                <DialogContent>
                    {isAfter ? (
                        <p style={{ textAlign: "start" }}>
                            You are about to navigate forward from your session for <b>{formIdToNameMap[formId] ? formIdToNameMap[formId] : `Untitled`}</b>. Any changes you have made in this form will
                            not be reflected in the workflow unless you resubmit this form.
                        </p>
                    ) : (
                        <p style={{ textAlign: "start" }}>
                            You are about to navigate to your session for <b>{formIdToNameMap[showRedirectDialog] ? formIdToNameMap[showRedirectDialog] : `Untitled`}</b>. Do you want to save your
                            current session for <b>{formIdToNameMap[formId] ? formIdToNameMap[formId] : `Untitled`}</b>?
                        </p>
                    )}
                </DialogContent>

                <DialogActions>
                    <Grid container spacing={1} display="flex" justify="flex-end">
                        {isAfter ? (
                            <>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} display="flex" justify="flex-end">
                                        <Grid item>
                                            <VardogyirButton theme={"S_blue"} onClick={() => setShowRedirectDialog(false)} disabled={isSaving} startIcon={<ArrowBack />}>
                                                CANCEL
                                            </VardogyirButton>
                                        </Grid>
                                        <Grid item>
                                            <VardogyirButton theme={"S_blue"} onClick={handleRedirect} disabled={isSaving} startIcon={<CloseIcon />}>
                                                DO NOT SAVE
                                            </VardogyirButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} display="flex" justify="flex-end">
                                        <Grid item>
                                            <VardogyirButton
                                                theme={"P_red"}
                                                className={"save-form-render-item-btn-red"}
                                                onClick={async () => {
                                                    await handleSaveProgress(false);
                                                    handleRedirect();
                                                }}
                                                disabled={isSaving}
                                                startIcon={<SaveIcon />}
                                            >
                                                SAVE WITHOUT RESUBMIT
                                                {isSaving && <CircularProgress size={24} className="buttonProgress" />}
                                            </VardogyirButton>
                                        </Grid>
                                        <Grid item>
                                            <StyledButton variant="contained" startIcon={<Refresh />} onClick={handleSubmit} bgColor="#000" textColor="#FFF" hoverBgColor="rgba(0, 0, 0, 0.9)">
                                                RESUBMIT
                                            </StyledButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item>
                                    <VardogyirButton theme={"S_blue"} onClick={() => setShowRedirectDialog(false)} disabled={isSaving} startIcon={<ArrowBack />}>
                                        CANCEL
                                    </VardogyirButton>
                                </Grid>
                                <Grid item>
                                    <VardogyirButton theme={"S_blue"} onClick={handleRedirect} disabled={isSaving} startIcon={<CloseIcon />}>
                                        DO NOT SAVE
                                    </VardogyirButton>
                                </Grid>
                                <Grid item>
                                    <VardogyirButton
                                        theme={"P_blue"}
                                        className={"save-form-render-item-btn"}
                                        onClick={async () => {
                                            await handleSaveProgress(false);
                                            handleRedirect();
                                        }}
                                        disabled={isSaving}
                                        startIcon={<SaveIcon />}
                                    >
                                        SAVE
                                        {isSaving && <CircularProgress size={24} className="buttonProgress" />}
                                    </VardogyirButton>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            {getFormLinkNavigationMenu()}
            {getResubmitDialog()}
            {getFormNavigateDialog()}
        </>
    );
}

export default NavigationMenu;
