import React from 'react'
import { styled } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { format } from '../utils/Date';
import { getRandomColor } from '../ColourTextHelper';
 

const MainContainer = styled("div")(({ backgroundColor, color }) => ({
    backgroundColor,
    borderRadius: "6px",
    padding: "0.5rem",
    marginBottom: "1rem",
    overflowWrap: "break-word",
    width: "fit-content",
    maxWidth: "100%",
    color
}))

const AuthorInfo = styled("span")({
  color: "#666666",
  fontSize: "12px"
})

const AuthorName = styled("span")({
  fontWeight: "bold",
})

const CommentDate = styled("span")({

})


const renderAuthorInfo = (legacy, user, date, timezone, key, commentIdx) => {
  if (legacy) {
    return (
      <AuthorName id={`${key}-comment-user-${commentIdx}`}>
        Anonymous
      </AuthorName>
    );
  } else {
    return (
      <>
        <AuthorName id={`${key}-comment-user-${commentIdx}`}>{user}</AuthorName>{" "}
        <Tooltip title={format(date, timezone)} placement="top">
          <CommentDate id={`${key}-comment-timestamp-${commentIdx}`}>
            {format(date, timezone, "relative")}
          </CommentDate>
        </Tooltip>
      </>
    )
  }
}


const CommentBubble = ({ comment, user, date, timezone, legacy, key, commentIdx }) => {

  const backgroundColor = getRandomColor(user);
  const authorInfo = legacy ? `Anonymous` : `${user} - ${format(date, timezone, "relative")}`

  return (
    <MainContainer backgroundColor={backgroundColor}>
        <AuthorInfo>{renderAuthorInfo(legacy, user, date, timezone, key, commentIdx)}</AuthorInfo>
        <br/>
        <span style={{ color: "black"}} id={`${key}-comment-text-${commentIdx}`}>{comment}</span>
    </MainContainer>
  )
}

export default CommentBubble