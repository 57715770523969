import React, { useState, useEffect } from "react";

// MUI
import { styled } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from "@material-ui/pickers";

const StyledDateTimePicker = styled(DateTimePicker)(({ disabled }) => ({
  fontSize: "1em",
  "& .MuiOutlinedInput-notchedOutline": {
    top: "-8px !important",
  },
  ...(disabled && {
    "& .MuiInputBase-root": {
      background: "rgba(248, 248, 248, 1) !important",
      color: "black !important",
    },
  }),
}));

const StyledDatePicker = styled(DatePicker)(({ disabled }) => ({
  fontSize: "1em",
  "& .MuiOutlinedInput-notchedOutline": {
    top: "-8px !important",
  },
  ...(disabled && {
    "& .MuiInputBase-root": {
      background: "rgba(248, 248, 248, 1) !important",
      color: "black !important",
    },
  }),
}));

function DatetimeInput({ data }) {
  const { answers, column, rowIndex, columnIndex, updateValue, isReadOnly, size } = data;

  const [dateTimeVal, setDateTimeVal] = useState(null);
  const [outputFormat, setOutputFormat] = useState(null);

  useEffect(() => {
    if (column.dtFormat)
      setOutputFormat(parseFormat(column.dtFormat.outputFormat));
    else setOutputFormat("dd/MM/yy HH:mm"); // Else just set the default one
  }, []);

  useEffect(() => {
    let val = answers[rowIndex][columnIndex].value;
    if (val && val.calculatedValue != "") {
      setDateTimeVal(answers[rowIndex][columnIndex].value.calculatedValue);
    }
  }, [answers]);

  const parseFormat = (f) => {
    switch (f) {
      case "dd LLLL YYYY":
        return "dd LLLL yyyy";
      case "dd LLLL YYYY kk:mm":
        return "dd LLLL yyyy HH:mm";
      case "dd/MM/YY":
        return "dd/MM/yy";
      case "dd/MM/YY kk:mm":
        return "dd/MM/yy HH:mm";
      case "dd/MM/YYYY":
        return "dd/MM/yyyy";
      case "dd/MM/YYYY kk:mm":
        return "dd/MM/yyyy HH:mm";
      case "dd-MM-YYYY":
        return "dd-MM-yyyy";
      case "dd-MM-YYYY kk:mm":
        return "dd-MM-yyyy HH:mm";
      case "MM/dd/YYYY":
        return "MM/dd/yyyy";
      case "MM/dd/YYYY kk:mm":
        return "MM/dd/yyyy HH:mm";
      case "YYYY-MM-dd":
        return "yyyy-MM-dd";
      case "YYYY-MM-dd kk:mm":
        return "yyyy-MM-dd HH:mm";
      case "Unix":
        return "utc";
      default:
        return "dd LLLL yyyy HH:mm";
    }
  };

  return (
    outputFormat && (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {outputFormat.includes("m") ? (
          <StyledDateTimePicker
            variant={"inline"}
            inputVariant="outlined"
            ampm={false}
            autoOk={true}
            fullWidth
            disabled={isReadOnly}
            value={dateTimeVal}
            format={outputFormat}
            id={`table-row-${data.rowIndex}-col-${data.columnIndex}`}
            onChange={(e) => {
              if (e.getTime() != null || !isNaN(e.getTime())) {
                updateValue(rowIndex, columnIndex, e.getTime(), false);
              }
            }}
            size={size}
          />
        ) : (
          <StyledDatePicker
            variant={"inline"}
            inputVariant="outlined"
            autoOk={true}
            fullWidth
            disabled={isReadOnly}
            value={dateTimeVal}
            format={outputFormat}
            id={`table-row-${data.rowIndex}-col-${data.columnIndex}`}
            onChange={(e) => {
              if (e.getTime() != null || !isNaN(e.getTime())) {
                updateValue(rowIndex, columnIndex, e.getTime(), false);
              }
            }}
            size={size}
          />
        )}
      </MuiPickersUtilsProvider>
    )
  );
}

export default DatetimeInput;
