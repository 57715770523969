import React, { useState } from "react";

// Material UI
import { Grid, IconButton } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Popover from "@material-ui/core/Popover";

// Util
import { DELETE_ANNOTATION } from "../Util/GraphQLQueries";
import { addPreviousText } from "../Util/AnnotationHelpers";
import { send_request_graphql } from "../Request";
import { CommentInput, Delete, DeleteGrid, DeleteText, PopoverDeleteText, ThreadText } from "../Util/AnnotationStyledComponents";

function ReplyBox({ disabled, handlePostReply, thread, threads, setThreads, handleOpenCloseThread, username, getLoader, updateState, annotationCount, formId }) {
    const [text, setText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleDeleteThread = () => {
        const ele = document.getElementById(thread.itemId);
        if (!ele) return;

        setLoading(true);

        send_request_graphql(formId, DELETE_ANNOTATION(thread.annotationId, username))
            .then((res) => {
                if (!res.data.deleteAnnotation) throw "Error deleting annotation";

                try {
                    for (let i = 0; i < ele.childNodes.length; i++) {
                        let curr = ele.childNodes[i];
                        if (curr.id !== thread.annotationId) continue;

                        const prevText = curr.firstChild.textContent;

                        if (!addPreviousText(ele, curr, prevText)) {
                            let textNode = document.createTextNode(prevText);
                            ele.insertBefore(textNode, curr);
                            ele.removeChild(curr);
                        }

                        break;
                    }
                } catch (e) {}

                // Come in here and update the threads
                setThreads(threads.filter((t) => t.annotationId !== thread.annotationId));
                updateState({ annotationCount: annotationCount - 1 });
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    const getOpenThread = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <CommentInput
                        multiline
                        fullWidth
                        id={`${thread.annotationId}-reply-input`}
                        style={{ width: "100%" }}
                        variant="outlined"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Type a reply"
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    disabled={disabled}
                                    id={`${thread.annotationId}-reply-button`}
                                    style={{ outline: "none", padding: "0px" }}
                                    onClick={() => {
                                        handlePostReply(text, thread.annotationId);
                                        setText("");
                                    }}
                                >
                                    <SendIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
                <Grid item>
                    {username === thread.annotationCreator && (
                        <ThreadText item xs onClick={() => handleOpenCloseThread(false, thread.annotationId)}>
                            Close thread
                        </ThreadText>
                    )}
                </Grid>
                {username === thread.annotationCreator && getDeleteThreadButton()}
            </Grid>
        );
    };

    const getCloseThread = () => {
        return (
            <Grid container>
                <Grid item>
                    {username === thread.annotationCreator && (
                        <ThreadText item xs onClick={() => handleOpenCloseThread(true, thread.annotationId)}>
                            Reopen thread
                        </ThreadText>
                    )}
                </Grid>
                {username === thread.annotationCreator && getDeleteThreadButton()}
            </Grid>
        );
    };

    const getDeleteThreadButton = () => {
        return (
            <DeleteGrid item>
                <DeleteText onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Delete />
                    Delete thread
                </DeleteText>
                <Popover
                    id={`delete-thread-popover-${thread.annotationId}`}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        style: {
                            color: "#868686",
                            backgroundColor: "#ffffff",
                            padding: "8px",
                            paddingLeft: "0px",
                            fontSize: "12px",
                            borderRadius: "4px",
                            boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)",
                        },
                    }}
                >
                    <PopoverDeleteText onClick={handleDeleteThread}>Permanently delete this thread</PopoverDeleteText>
                </Popover>
            </DeleteGrid>
        );
    };

    return loading ? getLoader("reply-box-loading-skeleton") : thread.isOpen ? getOpenThread() : getCloseThread();
}

export default ReplyBox;
