import React from "react";

// Material UI
import { AddCommentText, StyledPopover } from "./Util/AnnotationStyledComponents";

function Popover({ children, openSidebar, showPopover, isAnnotationModeOn, openWithoutReplyBox }) {
    return showPopover ? (
        <StyledPopover
            popoverItems={(itemClass) => (
                <>{isAnnotationModeOn ? <AddCommentText onClick={openSidebar}>Add comment</AddCommentText> : <AddCommentText onClick={openWithoutReplyBox}>Turn on annotation mode</AddCommentText>}</>
            )}
        >
            {children}
        </StyledPopover>
    ) : (
        children
    );
}

export default Popover;
