import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";
import "./Legend.css";
import {getLegendColor} from "./GetLegendColor"

class Legend extends MapControl {
  createLeafletElement(props) {}

  componentDidMount() {
    // get color depending on population density value

    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      let labels = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<i style="background:' +
          getLegendColor(from + 1) +
            '; width: 24px; height: 12px; border-radius: 8px"></i> ' +
            from +
            (to ? "&ndash;" + to : "+") +
            " km/h"
        );
      }

      div.innerHTML = labels.join("<br>");
      return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }
}

export default withLeaflet(Legend);
