import { createTableRow } from "./HandleInitialiseTable";
import { convertStringToKey, parseRowData } from "./Helpers";

export const addEmptySection = (data, existingAnswers) => {
    const colNum = data.data.length;
    const cols = data.data;
    const tableCells = data.tableCells;

    const newRowIdx = existingAnswers.length;
    let tableRow = createTableRow(cols, tableCells, colNum, newRowIdx);

    return [...existingAnswers, tableRow];
};

export const getRepeatableSectionsAnswerKeys = (data, shown) => {
    let rowLabels = data.rowLabels;

    let result = [];

    for (let i = 0; i < rowLabels.length; i++) {
        if (shown && i >= shown) break;
        const rowLabel = rowLabels[i];

        let key = "";
        if (rowLabel.rowLabel != "") key = convertStringToKey(rowLabel.rowLabel);
        else key = rowLabel.rowKey;

        result.push(key);
    }

    return result;
};

export const parseRepeatableSectionsAnswerData = (answerData, columns, extractFormat, rowLabels, shown) => {
    return parseRowData(answerData, rowLabels, shown);
};

export const calculateSectionsToShow = (answerData) => {
    try {
        return Object.keys(answerData.answer).length;
    } catch (e) {
        return 1; // Default to showing 1
    }
};

export const removeItemWithIndex = (answers, removeIdx) => {
    if (!answers) return [];
    let result = [];
    for (let i = 0; i < answers.length; i++) {
        if (i === removeIdx) continue;
        result.push(answers[i]);
    }
    return result;
}
