import React from "react";
import { Grid, List, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { styled } from "@material-ui/core/styles";

const StyledSkeleton = styled(Skeleton)({
    background: "#E8E8E8",
    borderRadius: "4px",
});

function FormSkeletonLoader({ classes }) {
    return (
        <Grid container spacing={1} className={classes.layout}>
            <StyledSkeleton variant={"rect"} width={"20%"} height={30} style={{ position: "absolute", top: "16px", left: "32px" }} />
            <StyledSkeleton variant={"rect"} width={"15%"} height={30} style={{ position: "absolute", top: "16px", right: "32px" }} />

            <Grid item xs={1}></Grid>

            <Grid item xs={12} md={8}>
                <Paper className={classes.paper} style={{ minHeight: "unset" }}>
                    <StyledSkeleton variant={"rect"} height={"5vh"} style={{ marginTop: "62px" }} />
                    <StyledSkeleton variant={"rect"} height={"15vh"} style={{ marginTop: "18px" }} />
                    <StyledSkeleton variant={"rect"} height={"10vh"} style={{ marginTop: "18px" }} />
                    <StyledSkeleton variant={"rect"} height={"30vh"} style={{ marginTop: "18px" }} />

                    <StyledSkeleton variant={"rect"} height={30} width={200} style={{ margin: "auto", marginTop: "32px" }} />
                    <StyledSkeleton variant={"rect"} height={33} width={80} style={{ margin: "auto", marginTop: "12px" }} />
                </Paper>
            </Grid>

            <Grid item xs={2}>
                <List style={{ position: "sticky", top: "90px", background: "white", borderRadius: "6px" }}>
                    <StyledSkeleton variant={"rect"} height={20} />
                </List>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    );
}

export default FormSkeletonLoader;
