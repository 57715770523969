import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import FilePreviewer from "./FilePreviewer";

const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  textroot: {
    width: "100%",
    margin: "auto"
  },
  filePreviewURL: {
    height: "100vh",
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    margin: "8px 0px",
    "& > div > div > .photo-viewer-container" : {
      margin: "auto",
    }
  },
  downloadFile: {
    textDecoration: "none",
    fontSize: "12px",
    color: "#757575",
    "&:hover":{
      textDecoration: "none",
      color: "#2196F3"
    }
  },
  filePreviewError: {
    height: 100,
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    margin: "8px 0px",
    color: "rgb(225, 0, 80)",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});


class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filePath : "",
      fileType : "",
      errorMessage: "",
      key:""
    }
  }


  componentDidMount = async () => {

    const {data} = this.props
    let fileType = ""
    await axios.get(
       data.data
    ).then(() => {
      if (data && data.data){

        fileType = this.getExtension(data.data)
        if (fileType){
          this.setState({
            fileType,
            filePath: data.data,
            key:data.key
          })
        }else {
          this.setState({
            fileType: data.file_type,
            filePath: data.data,
            key : data.key
          })
        }
      }
    })
    .catch((error)=>{
      console.log(error.message)
      this.setState({
        errorMessage: error.message
      })
    })

  }

    processUrl =(urlStr) =>{
    // Remove [ from start
    urlStr = urlStr.replace(/^\[/, '');
    // Remove everything after , or ]
    urlStr = urlStr.replace(/[,].*$/, '');
    urlStr = urlStr.replace(/\].*$/, '');
    console.log(urlStr);
    return urlStr;
  }

  getExtension = (path) => {
    var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
      // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
      return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
  }
  render() {
    const { classes } = this.props;
    let { filePath, fileType, errorMessage,key} = this.state;
    filePath=this.processUrl(filePath);
    if (errorMessage){
      return (
        <div className={classes.root}>
          <div className={classes.filePreviewError}>
            The file cannot be previewed ( {errorMessage} )
          </div>
        </div>
      )
    }else {
      try {
        return (
          <div className={classes.root}>
           { <div className={classes.filePreviewURL}>
             <FilePreviewer  className="filePreview"  fileType={fileType} filePath={filePath} fileName={key}
             />
            </div>}

            <a className={classes.downloadFile}  href={filePath} >
              <GetAppIcon />
              DOWNLOAD
            </a>
          </div>
        );
      } catch (e) {
        return <h5>Error encountered</h5>;
      }
    }
  }
}

export default withStyles(styles)(FilePreview);
