import HighlightPop from "react-highlight-pop";

import { styled } from "@material-ui/core/styles";
import { Drawer, Grid, List, TextField } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

// ** Popover ** //
export const AddCommentText = styled("span")({
    color: "#fff",
    fontSize: "12px",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(255, 255, 255, 0.95)",
    },
});

export const StyledPopover = styled(HighlightPop)({
    ".h-popover": {
        borderRadius: "8px",
    },
    background: "rgb(41, 41, 41) none repeat scroll 0% 0%;",
});

// ** Thread side bar ** //
export const StyledDrawer = styled(Drawer)({
    overflow: "hidden",
    "@media (max-width: 900px)": {
        width: "70% !important",
    },
});

export const InsideDiv = styled("div")({
    overflowY: "scroll",
    paddingRight: "16px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "*::-webkit-scrollbar": {
        display: "none !important",
    },
});

export const StyledSkeleton = styled(Skeleton)(({ minHeight, margin }) => ({
    borderRadius: "8px",
    padding: "24px",
    margin: margin ? margin : "0px 16px",
    minHeight: minHeight,
}));

export const CloseIconListItem = styled(List)({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "16px",
});

export const Close = styled(CloseIcon)({
    color: "rgb(41, 41, 41)",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(0, 0, 0, 1)",
    },
});

// ** Thread box content ** //
export const TextBox = styled("div")(({}) => ({
    color: "#000000",
    padding: "12px",
    marginBottom: "16px",
    textAlign: "left",
    wordWrap: "anywhere",
    border: "1px solid #CDCDCD",
    borderRadius: "2px",
    fontSize: "14px",
    width: "100%",
}));

export const Text = styled("span")(({ highlightColor }) => ({
    backgroundColor: highlightColor,
    fontSize: "20px",
    padding: "0px 3px",
    lineHeight: "initial",
    whiteSpace: "pre-wrap",
}));

export const ThreadBoxSkeleton = styled(Skeleton)({
    borderRadius: "8px",
    marginBottom: "12px",
    minHeight: "50px",
    width: "100%",
});

// ** Thread Box ** //
export const Box = styled("div")(({ isIndented, isMobile }) => ({
    border: "1px solid #CDCDCD",
    borderRadius: "8px",
    padding: "24px",
    margin: "0px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    ...(!isMobile && { ...(isIndented ? { position: "relative", marginRight: "16px", boxShadow: "8px 8px 16px 0px #00000040" } : { marginLeft: "16px" }) }),
    ...(isMobile && { ...(isIndented ? { position: "relative", boxShadow: "8px 8px 16px 0px #00000040", marginLeft: "16px" } : { marginLeft: "16px" }) }),
}));

// ** Reply Box ** //
export const CommentInput = styled(TextField)({
    color: "#fff",
    fontSize: "12px",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(255, 255, 255, 0.95)",
    },
});

export const DeleteText = styled("span")({
    marginLeft: "12px",
    marginTop: "14px",
});

export const PopoverDeleteText = styled("span")({
    marginLeft: "12px",
    marginTop: "14px",
    "&:hover": {
        cursor: "pointer",
        filter: "brightness(1.1)",
    },
});

export const Delete = styled(DeleteIcon)({
    height: "14px",
    width: "auto",
    marginRight: "2px",
});

export const DeleteGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "12px",
    color: "#C44058",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(196, 64, 88, 0.85)",
    },
});

export const ThreadText = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "12px",
    marginTop: "14px",
    color: "#2196F3",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 0.85)",
    },
});

// ** Edit Box ** //
export const EditGrid = styled(Grid)({
    marginBottom: "8px",
});

export const EditBoxInput = styled(TextField)({
    color: "#fff",
    fontSize: "12px",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(255, 255, 255, 0.95)",
    },
    marginBottom: "8px",
});

// ** Comment Bubble ** //
export const ReplyItem = styled(Grid)(({ calculatedcolour }) => ({
    color: "#000000",
    backgroundColor: calculatedcolour,
    borderRadius: "10px",
    padding: "8px",
    marginBottom: "12px",
    textAlign: "left",
    wordWrap: "anywhere",
}));

export const SystemItem = styled(Grid)(() => ({
    color: "#000000",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    padding: "8px",
    marginBottom: "12px",
    textAlign: "left",
    border: "1px solid #000",
    wordWrap: "anywhere",
}));

export const ReplyText = styled(Grid)({
    fontSize: "14px",
    lineHeight: "19px",
    whiteSpace: "pre-wrap",
});

export const ReplyTopText = styled(Grid)({
    fontSize: "12px",
    lineHeight: "16px",
    // display: "flex",
});

export const Username = styled("span")({
    color: "#666666",
    fontWeight: "bold",
});

export const Time = styled("span")({
    color: "#666666",
});

export const EditOrDelete = styled("span")({
    color: "rgba(33, 150, 243, 1)",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 0.9)",
    },
});

export const UsernameSkeleton = styled(Skeleton)({
    borderRadius: "4px",
    width: "150px",
});

export const CommentContainer = styled(Grid)({
    display: "flex",
    alignItems: "center",
    gap: "3px",
});
