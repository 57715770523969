import React from "react";

// mui
import { Dialog, DialogContent, Grid, styled, DialogActions } from "@material-ui/core";

// mui icons
import CloseIcon from "@material-ui/icons/Close";

// util
import { Button as VButton } from "vardogyir-ui";
import IconBanner from "./Views/component/Icon/IconBanner";
import { Transition } from "./Views/component/NavigationSidebar/CommonComponents";
import { getFailedUploadsBanner } from "./Views/component/util/SubmissionUtil";

const Text = styled("div")({
    fontSize: "14px",
    lineHeight: "19px",
    color: "rgba(53, 53, 53, 1)",
    width: "fit-content",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
});

const Cross = styled(CloseIcon)({
    height: "18px",
    width: "auto",
    color: "#ec0000",
    marginRight: "6px",
});

function FailedSubmissionDialog({ formFailures, close }) {
    const { failedUploads } = formFailures;

    return (
        <Dialog open maxWidth="sm" TransitionComponent={Transition}>
            <DialogContent style={{ textAlign: "left" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <IconBanner
                            bgColor="rgba(225, 0, 0, 0.1)"
                            borderColor="rgba(225, 0, 0, 1)"
                            content={
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Text>
                                            <Cross />
                                            <b>Form submission failed</b>
                                        </Text>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text>
                                            <span>
                                                An error occurred and your form could not be submitted. The form data has been saved but <b>uploaded files may need to be re-uploaded</b> before
                                                retrying the submission. Contact support@workflow86.com if this error reoccurs.
                                            </span>
                                        </Text>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {getFailedUploadsBanner(failedUploads)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} display="flex" justify="flex-end">
                    <Grid item>
                        <VButton variant="outlined" onClick={close} theme={"S_blue"}>
                            Close
                        </VButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default FailedSubmissionDialog;
