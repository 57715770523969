import React, { useEffect, useState } from "react";
import config from "../../../config";
import { Username, UsernameSkeleton } from "../Util/AnnotationStyledComponents";
import { Auth } from "aws-amplify";
import axios from "axios";

function CommentUsername({ username, isAuthed }) {
    const [displayName, setDisplayName] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        if (!isAuthed) {
            setDisplayName(username);
            return;
        }

        setLoading(true);
        try {
            let url = `${config.USER_ADDRESS}/display_name/${username}`;
            let header = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            header["Authorization"] = (await Auth.currentSession()).getIdToken().getJwtToken();
            const request = await axios(url, {
                headers: header,
                method: "GET",
                data: {},
            })
                .then(
                    (res) => {
                        return res;
                    },
                    (rej) => {
                        throw rej;
                    }
                )
                .catch(async (error) => {
                    setDisplayName(username);
                });
            if (request.data === "") setDisplayName(username);
            else setDisplayName(request.data);
            setLoading(false);
        } catch (e) {
            setDisplayName(username);
            setLoading(false);
        }
    }, []);

    return <Username>{!loading ? displayName : <UsernameSkeleton variant="rect" />}</Username>;
}

export default CommentUsername;
