import { Button, AlertMessage } from "vardogyir-ui"
import React, { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import config from "../../config";

const reportButtonTextMap = {
    "not_generated": {
        "alert_text": "",
        "button_text": "Generate PDF"
    },
    "generating": {
        "alert_text": "Generating PDF. This may take up to 15 minutes for larger files. Once completed, click download to access the report.",
        "button_text": "Generating PDF",
    },
    "generated": {
        "alert_text": "PDF is finished generating. Click the download button to download your report.",
        "button_text": "Download PDF"
    }
}

const ReportButton = (props) => {
    const [report_generation_state, set_report_generation_state] = useState("not_generated");
    const [is_alert_open, set_alert_open] = useState(false);
    let timer_id;

    useEffect(() => {
        if (timer_id === undefined) {
            timer_id = setInterval(pollAhvvaReportStatus, 120000);
        }
        pollAhvvaReportStatus();
        return () => clearInterval(timer_id);        
    }, [])

    const onAhvvaReportClick = () => {
        

        switch (report_generation_state) {
            case "not_generated":
                axios.post(`${config.API_ADDRESS}/ahvva_report/${props.req_id}`)
                    .then((res) => {
                        set_report_generation_state("generating")
                        set_alert_open(true)
                    })
                break;
            case "generated":
                axios.get(`${config.API_ADDRESS}/ahvva_report/${props.req_id}`)
                    .then((res) => {
                        const link = document.createElement('a');
                        link.href = res.data.location;
                        link.setAttribute('download', 'ahvva_report.pdf');
                        link.setAttribute('target', "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                break;
        }

    }

    const pollAhvvaReportStatus = () => {
        console.log(`polling ${timer_id}`)
        axios.get(`${config.API_ADDRESS}/ahvva_report/${props.req_id}`)
            .then((res) => {
                const { status } = res.data;
                switch (status) {
                    case "in-progress":
                        if (report_generation_state !== "generating") {
                            set_report_generation_state("generating");
                        }
                        break;
                    case "finished":
                        const prev_state = report_generation_state;
                        clearInterval(timer_id);
                        set_report_generation_state("generated"); // TODO: fix this part as prev_state is always not_generated!
                        console.log(prev_state)
                        if (prev_state === "generating") {
                            set_alert_open(true);
                        }
                        break;
                }
            })
    }

    return (
        <div>
            <AlertMessage
                open={is_alert_open}
                anchorOrigin={{ "vertical": "bottom", "horizontal": "center" }}
                msg={reportButtonTextMap[report_generation_state]["alert_text"]}
                handleClose={() => { set_alert_open(false) }} 
                status={200} />
            <Button disabled={report_generation_state === "generating" ? true : false}
                onClick={onAhvvaReportClick}
                theme={"P_blue"}
                classes={{
                    disabled: {
                        backgroundColor: 'grey'
                    }
                }}>
                {
                    reportButtonTextMap[report_generation_state]["button_text"]
                }
            </Button>
        </div >
    )
}

export default ReportButton; 