export const FormActionTypes = {
    GET_MAIN_PAGE: 'GET_MAIN_PAGE',
    GET_NEXT_PAGE: 'GET_NEXT_PAGE',
    GO_BACK_TO_PRE: 'GO_BACK_TO_PRE',
    GET_MAIN_PAGE_SUCCESS: 'GET_MAIN_PAGE_SUCCESS',
    GET_MAIN_PAGE_FAILED: 'GET_MAIN_PAGE_FAILED',
    GET_NEXT_PAGE_SUCCESS: 'GET_NEXT_PAGE_SUCCESS',
    GET_NEXT_PAGE_FAILED: 'GET_NEXT_PAGE_FAILED',
    ADD_PAGE_ANSWER: 'ADD_PAGE_ANSWER',
    ADD_PAGE_COMMENT: 'ADD_PAGE_COMMENT',
    DELETE_FILE_UPLOAD: 'DELETE_FILE_UPLOAD',
    ADD_PAGE_ANSWER_CALENDAR: 'ADD_PAGE_ANSWER_CALENDAR',
    ADD_PAGE_ANSWER_TABLE: 'ADD_PAGE_ANSWER_TABLE',
    GET_FORM: 'GET_FORM',
    UNMOUNT_FROM: 'UNMOUNT_FROM',
    GO_RELOAD: "GO_RELOAD",
    FETCH_RELOAD: "FETCH_RELOAD",
    FETCH_RELOAD_SUCCESS: "FETCH_RELOAD_SUCCESS",
    FETCH_RELOAD_FAILED: "FETCH_RELOAD_FAILED",
    PASSWORD_ALERT: "PASSWORD_ALERT",
    CLEAR_PASSWORD_ALERT: "CLEAR_PASSWORD_ALERT",
    GO_BACK_TO_ANY: "GO_BACK_TO_ANY",
    FORM_LOADING: "FORM_LOADING",
    GET_COMMENTS: "GET_COMMENTS",
    GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
    GET_COMMENTS_FAILED: "GET_COMMENTS_FAILED",
    UPDATE_FORM_STATE: "UPDATE_FORM_STATE",
};

export default FormActionTypes;
