import React from "react";

import { styled } from "@material-ui/core/styles";
import { Dialog, DialogContent } from "@material-ui/core";

const Loader = styled(DialogContent)({
    marginBottom: "4px",
    marginTop: "4px",
});

const LoaderText = styled("div")({
    marginTop: "8px",
    marginBottom: "4px",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "center",
});

function FormSubmitLoader({ open }) {
    return (
        <Dialog open={open} maxWidth={"xs"} fullWidth>
            <Loader>
                <img src={"/images/loading.gif"} />
                <LoaderText>Submitting...</LoaderText>
            </Loader>
        </Dialog>
    );
}

export default FormSubmitLoader;
