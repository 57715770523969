import React from "react";
import {FeatureGroup, Map, Marker, Polygon, ScaleControl, TileLayer} from "react-leaflet";
import L from "leaflet";
import PolylineDecorator from "./PolylineDecorator";
import {EditControl} from "react-leaflet-draw";
import GeoSearch from "./GeoSearch";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles/index";
import {addPageData} from "../../store/form/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "react-leaflet-fullscreen/dist/styles.css";
import FullscreenControl from "react-leaflet-fullscreen";
import Control from "react-leaflet-control";
import 'leaflet-linear-measurement/src/Leaflet.LinearMeasurement';

const styles = theme => ({
    Button: {
        color: "#2196F3",
        marginLeft: "10px",
        marginRight: "20px",
        border: "1px solid #2196F3",
        backgroundColor: "white",
        "&:hover": {
            color: "white",
            backgroundColor: "#2196F3"
        }
    }
});

const targetD = L.icon({
    iconUrl: "/images/poi_marker1.svg",
    iconSize: [80, 80], // size of the icon
    iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});

const arrow_blue = [
    {
        offset: 25,
        repeat: 50,
        symbol: L.Symbol.arrowHead({
            pixelSize: 8,
            polygon: false,
            pathOptions: {
                opacity: 0.25,
                color: "blue",
                stroke: true
            }
        })
    }
];
const arrow_green = [
    {
        offset: 25,
        repeat: 50,
        symbol: L.Symbol.arrowHead({
            pixelSize: 8,
            polygon: false,
            pathOptions: {
                opacity: 0.25,
                color: "#1af018",
                stroke: true
            }
        })
    }
];

(function () {
    var originalOnTouch = L.Draw.Polyline.prototype._onTouch;
    L.Draw.Polyline.prototype._onTouch = function (e) {
        if (e.originalEvent.pointerType !== "mouse") {
            return originalOnTouch.call(this, e);
        }
    };
})();

class DrawableMap extends React.Component {
    constructor() {
        super();
        this.mapRef = React.createRef();
        this.drawRef = React.createRef();
        this.featureGroupRef = React.createRef();

        this.state = {
            mapping: {},
            markers: [],
            zoom: 3,
            hasLocation: false,
            latlng: {
                lat: -33.89911682453446,
                lng: 151.2072569896768
            },
            polygonList: [], // array for draw a polygon
            perimeterPath: [],
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            polyPath: [], // draw a polyline || array with array
            extendPath: [], // an array with an array
            layerKey: 0,
            rulerTool: {}
        };
    }

    componentDidMount() {
        try {
            /*
            We Overwrite the initRuler and resetRuler in the LeafletMeasurement Package
            */
            const map = this.mapRef.current.leafletElement;
            var Ruler = L.Control.LinearMeasurement;
            // create the new Ruler tool
            var newRuler = new Ruler({
                unitSystem: 'metrics',
                color: '#FF0099',
                type: 'line',
                features: 'ruler'
            });
            this.setState({rulerTool: newRuler});
            // Add Ruler to Control Bar
            map.addControl(newRuler);

            newRuler.resetRulerOrg = newRuler.resetRuler;
            // Rewrite the InitRuler in Leaflet Measurement package
            newRuler.initRuler = function () {
                var me = this,
                    map = this._map;

                if (!this.mainLayer) {
                    this.mainLayer = L.featureGroup();
                    this.mainLayer.addTo(this._map);
                }

                map.touchZoom.disable();
                map.doubleClickZoom.disable();
                map.boxZoom.disable();
                map.keyboard.disable();

                if (map.tap) {
                    map.tap.disable();
                }

                this.dblClickEventFn = function (e) {
                    L.DomEvent.stop(e);
                };

                this.clickEventFn = function (e) {
                    if (me.clickHandle) {
                        clearTimeout(me.clickHandle);
                        me.clickHandle = 0;

                        if (me.options.show_last_node) {
                            me.preClick(e);
                            me.getMouseClickHandler(e);
                        }

                        me.getDblClickHandler(e);
                    } else {
                        me.preClick(e);
                        me.clickHandle = setTimeout(function () {
                            me.getMouseClickHandler(e);
                            me.clickHandle = 0;
                        }, me.clickSpeed);
                    }
                };

                this.moveEventFn = function (e) {
                    if (!me.clickHandle) {
                        me.getMouseMoveHandler(e);
                    }
                };

                map.on('click', this.clickEventFn, this);
                map.on('mousemove', this.moveEventFn, this);
                this.resetRuler();
            };
            // ReWrite the reset Ruler Function
            newRuler.resetRuler = function (resetLayer) {
                console.log("resetLayer: " + resetLayer);
                // var map = this._map;
                if (resetLayer) {
                    //do nothing

                    this._map.off('click', this.clickEventFn, this);
                    this._map.off('mousemove', this.moveEventFn, this);

                    this._map.touchZoom.enable();

                    this._map.boxZoom.enable();

                    this._map.keyboard.enable();

                    if (this._map.tap) {
                        this._map.tap.enable();
                    }

                    newRuler.resetRulerOrg(false);

                } else {
                    newRuler.resetRulerOrg(resetLayer);
                }
            };

            if (this.props.form.answer_data[this.props.data.key]) {
                let answer = this.props.form.answer_data[this.props.data.key].answer[0];
                if (answer) {
                    let polyPath = [];
                    let extendPath = [];
                    let markers = [];
                    answer.targets.forEach(target => {
                        const extendPaths = target.sequence.map(path => [
                            path.latitude,
                            path.longitude
                        ]);
                        if (extendPaths.length > 0) {
                            extendPath.push(extendPaths);
                        }
                        const userSpecifiedPaths = target.user_specified.map(path => [
                            path.latitude,
                            path.longitude
                        ]);
                        if (userSpecifiedPaths.length > 0) {
                            polyPath.push(userSpecifiedPaths);
                        }
                    });
                    answer.target.map(marker => {
                        markers.push({
                            lat: marker.latitude,
                            lng: marker.longitude
                        });
                    });
                    let polygonList = [];
                    answer.blocks.map(block => {
                        let coords = [];
                        block.obstacle.map(coordinate => {
                            coords.push([coordinate.latitude, coordinate.longitude]);
                        });
                        polygonList.push(coords);
                    });

                    //let perimeterList = answer.perimeter.slice();
                    let perimeterPaths = [];
                    answer.perimeter.map(perimeterPath => {
                        let p = [];
                        perimeterPath.map(coordinate => {
                            p.push([coordinate.latitude, coordinate.longitude]);
                        });
                        perimeterPaths.push(p);
                    });

                    this.setState({
                        polyPath: polyPath,
                        extendPath: extendPath,
                        markers: markers,
                        polygonList: polygonList,
                        perimeterPath: perimeterPaths
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleSatelite = () => {
        this.setState({
            url:
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            attribution:
                "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        });
    };
    handleMap = () => {
        this.setState({
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        });
    };
    handleDarkMap = () => {
        this.setState({
            url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        });
    };

    handleClick = e => {
        const map = this.mapRef.current;
        if (map != null) {
            map.leafletElement.locate();
            this.setState({latlng: map.leafletElement.locate().options.center});
        }
    };

    handleLocationFound = e => {
        this.setState({
            hasLocation: true,
            latlng: e.latlng
        });
    };

    _onChange = (markers, polygonList, polyPath, extendPath, perimeterPaths = []) => {
        let current = {
            target: [],
            blocks: [],
            targets: [],
            perimeter: []
        };
        markers.map(marker => {
            current.target.push({
                latitude: marker.lat,
                longitude: marker.lng
            });
            current.targets.push({
                single: {
                    latitude: marker.lat,
                    longitude: marker.lng
                },
                sequence: [],
                user_specified: [],
                radius: 250
            });
        });
        for (let i in polyPath) {
            let Path = [];
            for (let key in polyPath[i]) {
                Path.push({
                    latitude: polyPath[i][key][0],
                    longitude: polyPath[i][key][1]
                });
            }
            current.targets.push({
                single: {},
                sequence: [],
                user_specified: Path,
                radius: 250
            });
        }

        for (let a = 0; a < perimeterPaths.length; a++) {
            let perimeterC = perimeterPaths[a];
            let coordinates = [];
            for (let b = 0; b < perimeterC.length; b++) {
                coordinates.push({
                    latitude: perimeterC[b][0],
                    longitude: perimeterC[b][1]
                });
            }

            current.perimeter.push(coordinates);

            current.targets.push({
                single: {},
                sequence: [],
                user_specified: [],
                radius: 250,
                perimeter_interval: 25,
                perimeter: coordinates
            });

        }

        for (let i in extendPath) {
            let Path = [];
            for (let key in extendPath[i]) {
                Path.push({
                    latitude: extendPath[i][key][0],
                    longitude: extendPath[i][key][1]
                });
            }
            current.targets.push({
                single: {},
                sequence: Path,
                user_specified: [],
                radius: 250
            });
        }
        for (let i in polygonList) {
            let obstacle = [];
            for (let key in polygonList[i]) {
                obstacle.push({
                    latitude: polygonList[i][key][0],
                    longitude: polygonList[i][key][1]
                });
            }
            current.blocks.push({obstacle: obstacle});
        }
        console.log(current)
        if (current.targets.length > 0) {
            this.props.getDataFromComponent(
                {drawable_map_answer: current},
                this.props.data.key,
                this.props.data.question_text
            );
        }
    };

    _onEdited = e => {
        let curr = this.state.markers;
        e.layers.eachLayer(layer => {
            switch (this.state.mapping[layer._leaflet_id]) {
                case "marker":
                    const {markers} = this.state;
                    markers.map(marker => {
                        if (marker.id === e.layer._leaflet_id) {
                            this.getLatLng(layer.toGeoJSON().geometry.coordinates);
                        }
                    });
                    break;
                default:
                    break;
            }
        });

        this._onChange();
    };

    getLatLng(coordinates, type) {
        console.log(Array.isArray(coordinates[1]));
        console.log(coordinates);
        if (type === "polyline") {
            return coordinates.map(point => [point[1], point[0]]);
        } else if (type === "marker") {
            return {
                lat: coordinates[1],
                lng: coordinates[0]
            };
        } else if (type === "polygon") {
            return coordinates[0].map(point => [point[1], point[0]]);
        }
    }

    _onCreated = e => {
        let type = e.layerType;
        let layer = e.layer;

        const coordinates = this.getLatLng(
            layer.toGeoJSON().geometry.coordinates,
            type
        );

        let markers = this.state.markers;
        let polygonList = this.state.polygonList;
        let polyPath = this.state.polyPath;
        let extendPath = this.state.extendPath;
        let perimeterPath = this.state.perimeterPath;
        console.log(type);

        this.state.mapping[e.layer._leaflet_id] = type;
        switch (type) {
            case "rectangle":
                this.setState({
                    coordinates: layer.toGeoJSON().geometry.coordinates[0]
                });
                break;
            case "circle":
                this.setState({coordinates: layer.toGeoJSON().geometry.coordinates});
                break;
            case "marker":
                markers.push(coordinates);

                break;
            case "polygon":
                polygonList.push(coordinates);
                break;
            case "polyline":
                if (layer.options.color === "#1af018") {
                    extendPath.push(coordinates);
                } else if (layer.options.color === "red") {
                    perimeterPath.push(coordinates);
                } else {
                    polyPath.push(coordinates);
                }
                break;

            default:
                break;
        }

        // Do whatever else you need to. (save to db; etc)

        this._onChange(markers, polygonList, polyPath, extendPath, perimeterPath);

        this.setState({layerKey: this.state.layerKey + 1});
    };
    handleExtend = () => {
        // Call onRemove(map) to disable the Ruler Tool
        this.state.rulerTool.onRemove(this.mapRef.current.leafletElement);
        new L.Draw.Polyline(this.mapRef.current.leafletElement, {
            showArea: false,
            shapeOptions: {
                color: "#1af018",
                weight: 4,
                opacity: 0.5,
                clickable: true
            }
        }).enable();
    };
    handleManual = () => {
        // Call onRemove(map) to disable the Ruler Tool
        this.state.rulerTool.onRemove(this.mapRef.current.leafletElement);
        new L.Draw.Polyline(this.mapRef.current.leafletElement, {
            showArea: false,
            shapeOptions: {
                color: "blue",
                weight: 4,
                opacity: 0.5,
                clickable: true
            }
        }).enable();
    };

    handlePerimeter = () => {
        // Call onRemove(map) to disable the Ruler Tool
        this.state.rulerTool.onRemove(this.mapRef.current.leafletElement);
        new L.Draw.Polyline(this.mapRef.current.leafletElement, {
            showArea: false,
            shapeOptions: {
                color: "red",
                weight: 4,
                opacity: 0.5,
                clickable: true
            }
        }).enable();
    }


    handleBlocking = () => {
        // Call onRemove(map) to disable the Ruler Tool
        this.state.rulerTool.onRemove(this.mapRef.current.leafletElement);
        new L.Draw.Polygon(this.mapRef.current.leafletElement, {
            showArea: true,
            shapeOptions: {
                color: "red"
            }
        }).enable();
    };
    handleMarker = () => {
        // Call onRemove(map) to disable the Ruler Tool
        this.state.rulerTool.onRemove(this.mapRef.current.leafletElement);
        new L.Draw.Marker(this.mapRef.current.leafletElement, {
            icon: targetD
        }).enable();
    };

    getShapeType = layer => {
        if (layer instanceof L.Circle) {
            return "circle";
        }

        if (layer instanceof L.Marker) {
            return "marker";
        }

        if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
            return "polyline";
        }

        if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
            return "polygon";
        }

        if (layer instanceof L.Rectangle) {
            return "rectangle";
        }
    };
    _onDeleted = e => {
        this.state.rulerTool.resetRuler();
        let markers = [];
        let polygonList = [];
        let polyPath = [];
        let extendPath = [];
        let perimeterPaths = [];

        this.featureGroupRef.current.leafletElement.eachLayer(layer => {
            const type = this.getShapeType(layer);
            const coordinates = this.getLatLng(
                layer.toGeoJSON().geometry.coordinates,
                type
            );
            switch (type) {
                case "marker":
                    markers.push(coordinates);
                    break;
                case "polygon":
                    polygonList.push(coordinates);
                    break;
                case "polyline":
                    if (layer.options.color === "#1af018") {
                        extendPath.push(coordinates);
                    }
                    if (layer.options.color === "red") {
                        perimeterPaths.push(coordinates);
                    } else {
                        polyPath.push(coordinates);
                    }
                    break;
                default:
                    break;
            }
        });

        this.setState({
            polygonList: polygonList,
            markers: markers,
            polyPath: polyPath,
            extendPath: extendPath,
            perimeterPath: perimeterPaths
        });
        this.props.getDataFromComponent(
            {drawable_map_answer: ""},
            this.props.data.key,
            this.props.data.question_text
        );

        this._onChange(markers, polygonList, polyPath, extendPath, perimeterPaths);
    };

    _onMounted = drawControl => {
        console.log("_onMounted", drawControl);
    };

    _onDeleteStop = e => {
        this.setState({layerKey: this.state.layerKey + 1});
        console.log("deletestop", this.state.markers);

        console.log("_onDeleteStop", e);
    };

    render() {
        return (
            <div>
                <Map
                    maxNativeZoom={19}
                    minZoom={0}
                    maxZoom={22}
                    style={{width: "100%", height: "600px"}}
                    center={this.state.latlng}
                    length={4}
                    onLocationfound={this.handleLocationFound}
                    ref={this.mapRef}
                    zoom={this.state.zoom}
                >
                    <TileLayer
                        url={this.state.url}
                        attribution={this.state.attribution}
                        maxNativeZoom={19}
                        minZoom={0}
                        maxZoom={22}
                    />
                    <FullscreenControl position="topleft"/>

                    <FeatureGroup ref={this.featureGroupRef} key={this.state.layerKey}>
                        {this.state.polygonList.map((polygon, index) => {
                            return (
                                <Polygon
                                    color={"red"}
                                    opacity={0.5}
                                    positions={polygon}
                                    key={index}
                                />
                            );
                        })}
                        {this.state.polyPath.map((polyline, index) => {
                            return (
                                <PolylineDecorator
                                    patterns={arrow_blue}
                                    positions={polyline}
                                    color={"blue"}
                                    opacity={0.5}
                                    key={index}
                                />
                            );
                        })}
                        {this.state.extendPath.map((polyline, index) => {
                            return (
                                <PolylineDecorator
                                    patterns={arrow_green}
                                    positions={polyline}
                                    color={"#1af018"}
                                    opacity={0.5}
                                    key={index}/>
                            );
                        })}
                        {this.state.perimeterPath.map((polyline, index) => {
                            return (
                                <PolylineDecorator
                                    patterns={arrow_green}
                                    positions={polyline}
                                    color={"red"}
                                    opacity={0.5}
                                    key={index}/>
                            );
                        })}
                        {this.state.markers.map((marker, index) => {
                            return <Marker icon={targetD} position={marker} key={index}/>;
                        })}
                        <GeoSearch/>

                        <Control position="topright">
                            <Tooltip placement="left" title="Draw a hybrid path">
                                <div onClick={this.handleExtend} className={"customize-button"}>
                                    <img src={"/images/extendPath.png"}/>
                                </div>
                            </Tooltip>
                            <Tooltip placement="left" title="Draw a manual path">
                                <div
                                    onClick={this.handleManual}
                                    className={"customize-button dark-button"}
                                >
                                    <img src={"/images/diagonal-line.svg"}/>
                                </div>
                            </Tooltip>
                            <Tooltip placement="left" title="Draw a blocking element">
                                <div
                                    onClick={this.handleBlocking}
                                    className={"customize-button dark-button"}
                                >
                                    <img src={"/images/polygon.svg"}/>
                                </div>
                            </Tooltip>
                            <Tooltip placement="left" title="Draw a perimeter">
                                <div
                                    onClick={this.handlePerimeter}
                                    className={"customize-button dark-button"}
                                >
                                    <img alt={"perimeter"} src={"/images/perimeter.svg"}/>
                                </div>
                            </Tooltip>
                            <Tooltip placement="left" title="Set an auto-path marker">
                                <div onClick={this.handleMarker} className={"customize-button"}>
                                    <img src={"/images/crosshair1.svg"}/>
                                </div>
                            </Tooltip>
                        </Control>
                        <EditControl
                            ref={this.drawRef}
                            edit={{edit: false}}
                            position="topright"
                            onEdited={this._onEdited}
                            onCreated={this._onCreated}
                            onDeleted={this._onDeleted}
                            onMounted={this._onMounted}
                            onDeleteStop={this._onDeleteStop}
                            draw={{
                                circle: false,
                                rectangle: false,
                                polygon: false,
                                marker: false,
                                polyline: false,
                                circlemarker: false
                            }}
                        />
                    </FeatureGroup>

                    <Control position="topleft">
                        <Tooltip title="Locate Me">
                            <div className={"customize-button"} onClick={this.handleClick}>
                                <img src={"/images/location.svg"}/>
                            </div>
                        </Tooltip>

                        <Tooltip title="Satellite View">
                            <div className={"customize-button"} onClick={this.handleSatelite}>
                                <img src={"/images/satellite.svg"}/>
                            </div>
                        </Tooltip>
                        <Tooltip title="Map View">
                            <div className={"customize-button"} onClick={this.handleMap}>
                                <img src={"/images/map.svg"}/>
                            </div>
                        </Tooltip>
                        <Tooltip title="Dark View">
                            <div
                                className={"customize-button dark-button"}
                                onClick={this.handleDarkMap}
                            >
                                <img src={"/images/black-map.svg"}/>
                            </div>
                        </Tooltip>

                    </Control>
                    <Control position="bottomleft">
                        <ScaleControl></ScaleControl>
                    </Control>
                </Map>

                <div style={{marginTop: "10px"}}></div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {
    dispatchaddPageData: (key, answer, question_text) =>
        addPageData({key, answer, question_text})
};

DrawableMap.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DrawableMap));
