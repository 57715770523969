import dayjs from "dayjs";

const re = /\!\{\S+\}/g; //searching for !{no.space}
const DEFAULT_DATE_FORMAT = "DD MMMM YYYY HH:mm";

//we don't retrieve answers from these types of questions
const UNSUPPORTED_FORMAT = [
  "e_signature",
  "custom_map",
  "drawable_map",
  "upload_media",
  "table",
  "repeatable_section",
  "file_preview",
  "video_embed",
];

//***********************Main functions that find and replace placeholders******************************
export function parsePlaceholder(data, form) {
  //parse placeholders inside answer options and questions, descriptions, strings
  //keeps a match for the placeholder
  let match;
  //list of pages containing current form answers
  let answerData = form.answer_data;
  //forces an object into a map, because javascript
  let pages = Object.values(answerData);
  //loop over input value and search for placeholders
  //this is a description or some other string
  if (typeof data === "string") {
    while ((match = re.exec(data))) {
      data = replace(data, match, pages);
    }
  } else if (typeof data.data == "string") {
    while ((match = re.exec(data.data))) {
      data.data = replace(data.data, match, pages);
    }
  } else if (Array.isArray(data.data)) {
    //we are inside an answer option
    data.data = replaceMultipleChoiceOptions(data.data, pages);
  }
  return data;
}

export function parsePlaceholdersInLogicBlocks(statement, form) {
  //parse placeholders inside logic blocks
  //keeps a match for the placeholder
  let match;
  //list of pages containing current form answers
  let answerData = form.answer_data;
  //forces an object into a map, because javascript
  let pages = Object.values(answerData);

  if (statement) {
    while ((match = re.exec(statement.value))) {
      if (match)
        statement.value = replaceSkipBlanks(statement.value, match, pages);
    }
  }

  return statement;
}

//***********************Util functions******************************

function retrieveAnswer(answer) {
  //handle calendar question
  if (answer.format == "calendar_answer") {
    if (answer.answer && answer.answer[0])
      if (answer.outputFormat)
        return dayjs(answer.answer[0]).format(
          replaceForFrontend(answer.outputFormat)
        );
      else return dayjs(answer.answer[0]).format(DEFAULT_DATE_FORMAT);
  }

  //based on data type
  if (Array.isArray(answer.answer)) {
    //this could be a list, multiple or single choice question

    if (answer.answer.length == 1 && Array.isArray(answer.answer[0])) {
      //this is a single choice
      return answer.answer[0][0];
    } else if (
      answer.answer.length > 0 &&
      Array.isArray(answer.answer[0]) &&
      answer.answer[0] !== "list"
    ) {
      //this is a multiple choice

      let multiple = "";
      for (const [index, arrayElement] of answer.answer.entries()) {
        //retrieve values and not label
        if (index === answer.answer.length - 1) {
          multiple += arrayElement[0];
        } else {
          multiple += arrayElement[0] + ", ";
        }
      }
      return multiple;
    } else if (answer.answer[0] === "list") {
      //this is a list

      let multiple = "";

      for (const [index, arrayElement] of answer.answer.entries()) {
        //retrieve values and not label
        if (index === 0) {
          //skip adding value here as it is == to 'list'
        } else if (index === answer.answer.length - 1) {
          multiple += arrayElement;
        } else {
          multiple += arrayElement + ", ";
        }
      }
      return multiple;
    }
  }
  return answer.answer;
}

function replace(data, match, pages) {
  //replace placeholders in the data
  for (let matchItem of match) {
    let replacedAtLeastOnce = false;
    // retrieve question key from the placeholder

    //clean placeholder from html if this comes from jodit
    let placeholders = cleanPlaceholder(matchItem);
    for (let placeholder of placeholders) {
      //this wipes .answer from a string when changing to .comment will have to add here
      let key = placeholder.substring(2, placeholder.length - 8);
      // loop over every page
      for (let pageItem of pages) {
        //find correct question to alter
        if (key === pageItem.key) {
          //filter for type
          if (UNSUPPORTED_FORMAT.indexOf(pageItem.format) === -1) {
            //this is the answer for this placeholder
            let answer = retrieveAnswer(pageItem) + "";
            //until there are no more placeholders inside the input
            while (data.indexOf(placeholder) !== -1) {
              //replace placeholder with the answer found
              if (answer) {
                data = data.replace(placeholder, answer);
                replacedAtLeastOnce = true;
              } else {
                data = data.replace(placeholder, "...");
              }
            }
          }
        }
      }

      if (!replacedAtLeastOnce) {
        data = data.replace(placeholder, "...");
      }
    }
  }
  return data;
}

function replaceSkipBlanks(data, match, pages) {
  //replace placeholders in the data
  for (let placeholder of match) {
    // retrieve question key from the placeholder
    let key = placeholder.substring(2, placeholder.length - 8); //this wipes .answer from a string when changing to .comment will have to add here
    // loop over every page
    for (let pageItem of pages) {
      //find correct question to alter
      if (key === pageItem.key) {
        //filter for type
        if (UNSUPPORTED_FORMAT.indexOf(pageItem.format) === -1) {
          //this is the answer for this placeholder
          let answer = retrieveAnswer(pageItem) + "";
          //until there are no more placeholders inside the input
          while (data.indexOf(placeholder) !== -1) {
            //replace placeholder with the answer found
            if (answer) {
              data = data.replace(placeholder, answer);
            } else {
              data = data.replace(placeholder, "...");
            }
          }
        }
      }
    }
  }
  return data;
}

//***********************Replace question options with blanks on initial load******************************
function replaceWithBlanks(data, match) {
  for (let placeholder of match) {
    // retrieve question key from the placeholder
    while (data.indexOf(placeholder) !== -1) {
      //replace placeholder with the answer found
      data = data.replace(placeholder, "...");
    }
  }

  return data;
}

//***********************Functions target multiple choice question options******************************
function replaceWithBlanksMultipleChoiceOptions(data) {
  let match;
  for (let answerOption of data) {
    //just double check this is an array of arrays and not some other array
    if (Array.isArray(answerOption) && answerOption.length == 2) {
      for (let index = 0; index < answerOption.length; index++) {
        //this is either label or value
        let labelOrValue = answerOption[index];
        //search for a placeholder match inside
        while ((match = re.exec(labelOrValue))) {
          for (let placeholder of match) {
            // retrieve question key from the placeholder
            while (labelOrValue.indexOf(placeholder) !== -1) {
              //replace placeholder with the answer found
              labelOrValue = labelOrValue.replace(placeholder, "...");
              answerOption[index] = labelOrValue;
            }
          }
        }
      }
    }
  }

  return data;
}
function replaceMultipleChoiceOptions(data, pages) {
  let match;
  for (let answerOption of data) {
    let replacedAtLeastOnce = false;
    //just double check this is an array of arrays and not some other array
    if (Array.isArray(answerOption) && answerOption.length == 2) {
      for (let index = 0; index < answerOption.length; index++) {
        //this is either label or value
        let labelOrValue = answerOption[index];
        //search for a placeholder match inside
        while ((match = re.exec(labelOrValue))) {
          for (let placeholder of match) {
            // retrieve question key from the placeholder
            let key = placeholder.substring(2, placeholder.length - 8); //this wipes .answer from a string when changing to .comment will have to add here
            // loop over every page
            for (let pageItem of pages) {
              //find correct question to alter
              if (key === pageItem.key) {
                //filter for type
                if (UNSUPPORTED_FORMAT.indexOf(pageItem.format) === -1) {
                  //this is the answer for this placeholder
                  let answer = retrieveAnswer(pageItem) + "";
                  //until there are no more placeholders inside the input
                  while (labelOrValue.indexOf(placeholder) !== -1) {
                    if (answer) {
                      //replace placeholder with the answer found
                      labelOrValue = labelOrValue.replace(placeholder, answer);
                      answerOption[index] = labelOrValue;
                    } else {
                      //replace placeholder with the answer found
                      labelOrValue = labelOrValue.replace(placeholder, "...");
                      answerOption[index] = labelOrValue;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (!replacedAtLeastOnce) {
      data = replaceWithBlanksMultipleChoiceOptions(data);
    }
  }
  return data;
}

function cleanPlaceholder(placeholder) {
  let result = [];
  let match;
  let leftoverString = [];

  //to wipe the rest of jodit html
  if (placeholder.indexOf("<") !== -1) {
    //save a leftorver string that still needs to be parsed for placeholders
    leftoverString = placeholder.substring(
      placeholder.indexOf("<"),
      placeholder.length
    );
    //get the placeholder out
    placeholder = placeholder.substring(0, placeholder.indexOf("<"));
    //add placeholder to the result
    result.push(placeholder);
    //for the rest of the match
    while ((match = re.exec(leftoverString))) {
      //if there is a placeholder left in the leftover string
      for (let matchItem of match) {
        if (matchItem.indexOf("<") !== -1) {
          //remove it from the string
          leftoverString = matchItem.substring(
            matchItem.indexOf("<"),
            matchItem.length
          );
          //get it out
          matchItem = matchItem.substring(0, matchItem.indexOf("<"));
          //save it in the result
          result.push(matchItem);
        }
      }
    }
  } else {
    result.push(placeholder);
  }
  return result;
}
function replaceForFrontend(format) {
  format = format.replaceAll("L", "M");
  format = format.replaceAll("d", "D");
  format = format.replaceAll("k", "H");
  if (format == "Unix") format = DEFAULT_DATE_FORMAT;
  return format;
}
