import React, { Component } from "react";
import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

class GeoSearch extends MapControl {
  constructor(props, context) {
    super(props);
  }

  createLeafletElement(opts) {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: "button",
      showMarker: true,
      showPopup: true,
      autoClose: true,
      retainZoomLevel: false,
      animateZoom: true,
      keepResult: false,
      searchLabel: "Search for a location"
    });
    return searchControl;
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    map.addControl(this.leafletElement);
    const func = e => {
      //console.log("search");
      
      var evt = e ? e : window.event;
      if (evt.stopPropagation) {
        //W3C
        evt.stopPropagation();
      } else {
        //IE
        evt.cancelBubble = true;
      }
    };
    var a = document.getElementsByClassName("leaflet-control-geosearch");
    a[0].onclick = func;
    a[0].ondblclick = func;
  }
}

export default withLeaflet(GeoSearch);
