import React from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar"; // Import Avatar component
import {loadAuth2, loadGapiInsideDOM} from 'gapi-script';
import aws_config from "../../auth/awsConfig";
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; // Import the icon

// Function to generate a unique color based on the username
const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

class UserAccount extends React.Component {
  state = {
    showLogout: false,
    username: "",
    openMenu: false,
    anchorEl: null,
    accountStatus: ""
  };

  constructor(props) {
    super(props);
  }

  onSignOut = () => {
    Auth.signOut().then(async () => {
      const gapi = await loadGapiInsideDOM();
      await loadAuth2(gapi, aws_config.gg_client_id, '');
      const auth = gapi.auth2

      auth.getAuthInstance().signOut().then(() => {
        console.log('User signed out.');

        window.history.pushState({}, document.title, "/form");
        window.location.pathname = "/form";
      });
    });
  };

  componentDidMount() {
    Auth.currentUserInfo().then((user) => {
      if (user) {
        if (user.username.startsWith("Google_")) {
          this.setState({
            accountStatus: "GOOGLE",
            showLogout: true,
            username: user.attributes.email, // Set username as email if it starts with "Google_"
          });
        } else {
          this.setState({
            accountStatus: "USERNAME",
            showLogout: true,
            username: user.username,
          });
        }
      }
    }).catch(() => console.log("Not signed in"));
  }

  handleClick = (event) => {
    this.setState({ openMenu: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ openMenu: false, anchorEl: null });
  };

  render() {
    if (this.state.showLogout) {
      const avatarColor = stringToColor(this.state.username);
      return (
        <div className="account-container" style={{marginLeft:8, display: 'flex', alignItems: 'center'}}>
          <Button
            style={{ textTransform: "none", display: 'flex', alignItems: 'center'}}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Avatar 
              style={{ 
                marginRight: 8, 
                width: 24, 
                height: 24, 
                backgroundColor: avatarColor,
                fontSize: "0.75em",
                border: "2px solid #fff",
                fontWeight: 600 
              }}
            >
              {this.state.username.charAt(0).toUpperCase()}
            </Avatar> {/* Display Avatar */}
            {"  "+this.state.username}
          </Button>
          <Menu
            open={this.state.openMenu}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={this.onSignOut}>
              <ExitToAppIcon style={{ marginRight: 8 }} /> {/* Add the icon */}
              Log out
            </MenuItem>
          </Menu>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default UserAccount;