import React, { createRef, Component } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import L from 'leaflet'
import {withStyles} from "@material-ui/core/styles/index";
import {antPath} from 'leaflet-ant-path';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Scatter} from 'react-chartjs-2';
const styles = theme => ({
    Button: {
        color: '#2196F3',
        backgroundColor: '#444444',
        '&:hover': {
            backgroundColor: '#2f2f2f',
        }
    },
    details: {
    alignItems: 'center',
        width:'100%'
  },
    table:{
        width:'1000'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
})




class DisplayGraph extends React.Component {
  constructor () {
    super()

    this.state = {



    }
  }




generateScatter=(xList,yList)=>{
      let result = []
      for(let i = 0 ; i<xList.length;i++){
result.push({
                x: xList[i],
                y: yList[i]
            })
      }
      return result
}
 toRadians=(degrees)=>{
      var pi = Math.PI;
  return degrees * (pi/180);
 }
    Calculate_Distance =(a,b)=>{
        console.log(a[0],b)
        var R = 6371e3; // metres
        var φ1 = this.toRadians(a[0]);
        var φ2 = this.toRadians(b[0]);
        var Δφ = this.toRadians(b[0]-a[0]);
        var Δλ = this.toRadians(b[1]-a[1]);

        var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        var d = R * c;
        return d.toFixed(2)
    }

    render () {
        let dataLine1= {

            labels:'Scatter Dataset',
                datasets: [
                    {
                        label: 'Distance / Speed',
                         yAxisID: 'V',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: '#199ec2',
                        borderColor: '#199ec2',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#199ec2',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        borderWidth: 3,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#199ec2',
                        pointHoverBorderColor: '#199ec2',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        pointStyle: 'circle',
                        data: this.generateScatter(this.props.data.distlist,this.props.data.speedlist)
                    },


                ]
            }
             let dataLine2= {

            labels:'Scatter Dataset',
                datasets: [

                    {
                        label: 'Kinetic Energy',
                         yAxisID: 'E',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: '#ec4100',
                        borderColor: '#ec4100',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#ec4100',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        borderWidth: 3,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#ec4100',
                        pointHoverBorderColor: '#ec4100',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        pointStyle: 'circle',
                        data: this.generateScatter(this.props.data.distlist,this.props.data.kinetic_energylist)
                    },

                ]
            }

       const { classes } = this.props;
       try{return (
           <div>
               <div className={'subpage'}>
                      <div  style={{width:'100%'}}>


            <h1 style={{whiteSpace:'pre-line'}}>
                {`${this.props.data.title}   Page:${this.props.page_index}`}
            </h1>



            <div style={{boxSizing:'unset',width:'100%'}}>




                    <Scatter  data={dataLine1} options={{responsive: true,
                    showLines: true,
                    scales: {
                    xAxes: [{
                         scaleLabel: {
        display: true,
        labelString: 'Distance (M)'
      },
                min: 0,
               type: 'linear',
                position: 'bottom'
            }],

        yAxes: [{
                        scaleLabel: {
        display: true,
        labelString: 'Velocity (km/h)'
      },
                        position: 'left',
                         id: 'V',
            ticks: {

                min: 0,
                stepSize: this.props.data.speed_step *2
            }
        }
        ]
    }}}/>
    <Scatter  data={dataLine2} options={{responsive: true,
                    showLines: true,
                    scales: {
                    xAxes: [{
                         scaleLabel: {
        display: true,
        labelString: 'Distance (M)'
      },
                min: 0,
               type: 'linear',
                position: 'bottom'
            }],

        yAxes: [
            {
                 scaleLabel: {
        display: true,
        labelString: 'Kinetic Energy (Joules)'
      },
                position: 'left',
                         id: 'E',
            ticks: {

                min: 0,
                stepSize: this.props.data.kinetic_energy_step*2
            }
        }
        ]
    }}}/>


<br/><br/>
                <div style={{width:'100%'}}>
                     <Table size={'small'} padding={'none'}  className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Class</TableCell>
            <TableCell align="right">Weight(kg)</TableCell>
            <TableCell align="right">Maximum speed(km/h)</TableCell>
            <TableCell align="right">Acceleration(m/s2)</TableCell>

              <TableCell align="right">Friction coefficient</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

            <TableRow key={this.props.data.title}>
              <TableCell component="th" scope="row">
                {this.props.data.carType}
              </TableCell>
              <TableCell align="right">{this.props.data.weight}</TableCell>
              <TableCell align="right">{this.props.data.top_speed}</TableCell>
              <TableCell align="right">{this.props.data.acceleration}</TableCell>

                 <TableCell align="right">{this.props.data.coeff_fric}</TableCell>
            </TableRow>

        </TableBody>
      </Table>
                </div>

            </div>



        </div>
               </div>

               <div style={{pageBreakAfter: 'always'}}></div>
               </div>
    )}
       catch (e) {
           return<p>Loading...</p>
       }


  }
}

export default withStyles(styles)(DisplayGraph)