import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";

const styles = (theme) => ({
  root: {},
  shaded: {
    backgroundColor: "#E7E7E7",
  },
  responsiveDiv: {
    position: "relative",
    paddingBottom: "42.1875%",
    height: 0,
  },
  responsiveIFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

const VideoEmbed = (props) => {
  const { classes } = props;

  try {
    return (
      <div className={classes.root}>
        {props.data.embedType === "URL" ? (
          props.data.size === "RESPONSIVE" ? (
            <div className={classes.responsiveDiv}>
              <iframe
                src={props.data.url}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                className={classes.responsiveIFrame}
              />
            </div>
          ) : (
            <iframe
              style={{ width: props.data.width, height: props.data.height }}
              src={props.data.url}
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            />
          )
        ) : (
          ReactHtmlParser(
            DOMPurify.sanitize(props.data.snippet, {
              ADD_TAGS: ["iframe"],
              ADD_ATTR: [
                "allow",
                "allowfullscreen",
                "frameborder",
                "scrolling",
              ],
            })
          )
        )}
      </div>
    );
  } catch (e) {
    return <h5>Error encountered</h5>;
  }
};

VideoEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoEmbed);
