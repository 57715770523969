import React, { createRef, Component } from "react";
import uuid from "uuid";
import L from "leaflet";
import Tooltip from "@material-ui/core/Tooltip";
import AntPath from "react-leaflet-ant-path";
import "react-leaflet-fullscreen/dist/styles.css";
import { FeatureGroup, Map, Marker, TileLayer, Popup, ScaleControl } from "react-leaflet";
import { LayersControl, Polygon } from "react-leaflet"; ////////
import Control from "react-leaflet-control";
import Legend from "./Legend";
import AhvvaContent from './AhvvaContent';

const { BaseLayer, Overlay } = LayersControl; ////////

const targetIcon = L.icon({
  iconUrl: "/images/impact_zone_radius.svg",
  iconSize: [80, 80], // size of the icon
  iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});
const targetD = L.icon({
  iconUrl: "/images/poi_marker1.svg",
  iconSize: [80, 80], // size of the icon
  iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});
const icon_start = L.icon({
  iconUrl: "/images/start_marker.svg",
  iconSize: [15, 15], // size of the icon
  iconAnchor: [7.5, 7.5], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -7.5] // point from which the popup should open relative to the iconAnchor
});
const icon_speed = L.icon({
  iconUrl: "/images/speed_marker.png",
  iconSize: [7.5, 7.5], // size of the icon
  iconAnchor: [3.75, 3.75], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -3.75] // point from which the popup should open relative to the iconAnchor
});

export default class AhvvaMap extends Component {
  constructor(props) {
    super(props);

    this.mapRef = createRef();
    this.state = {};
  }
  
  render() {  
    // const AhvvaDataArray = Object.keys(this.props.state.AhvvaData).map(function(k) {
    //   return this.props.state.AhvvaData[k];
    // });
    const arrayAhvvaData = Object.keys(this.props.state.AhvvaData).map(key => ({[key]: this.props.state.AhvvaData[key]}));
    let allAhhvaPaths = [];
    arrayAhvvaData.map((n,index) => {
      const pathDataArray = n[index].data;
      pathDataArray.map((p) => {
        const paths_list = p.paths_list
        paths_list.map((pathData,i) => {
          allAhhvaPaths.push(pathData.coordlist)  
        })
      })
    })
    
    return (
      <Map
        maxZoom={19}
        style={{
          height: this.props.state.fullScreen
            ? "100vh"
            : window.innerHeight - 110
        }}
        center={this.props.state.latlng}
        ref={this.mapRef}
        zoom={this.props.state.zoom}
      >
        <Control position="bottomleft">
          <ScaleControl></ScaleControl>
        </Control>
        <Legend/>
        <TileLayer
          url={this.props.state.url}
          attribution={this.props.state.attribution}
        />

        {this.props.state.blockList[0]
          ? this.props.state.blockList[0].map(targetBlocks => {
              return <Polygon color="red" positions={targetBlocks} />;
            })
          : null}

        <Control position="topleft">
          <Tooltip title="Satellite View">
            <div
              className={"customize-button"}
              onClick={this.props.handleSatelite}
            >
              <img src={"/images/satellite.svg"} />
            </div>
          </Tooltip>
          <Tooltip title="Map View">
            <div className={"customize-button"} onClick={this.props.handleMap}>
              <img src={"/images/map.svg"} />
            </div>
          </Tooltip>
          <Tooltip title="Dark View">
            <div
              className={"customize-button dark-button"}
              onClick={this.props.handleDarkMap}
            >
              <img src={"/images/black-map.svg"} />
            </div>
          </Tooltip>
          <Tooltip title="Analysis View">
            <div
              className={"customize-button"}
              onClick={this.props.openSidePage}
            >
              <img style={{ width: "100%" }} src={"/images/data.png"} />
            </div>
          </Tooltip>
          <Tooltip title="Full Screen">
            <div
              className={"customize-button"}
              onClick={this.props.handleFullScreen}
            >
              <img style={{ width: "100%" }} src={"/images/fullscreen.svg"} />
            </div>
          </Tooltip>
          <Tooltip title="Summary View">
            <div className={"customize-button"} onClick={this.props.summaryD}>
              <img style={{ width: "100%" }} src={"/images/short_text.svg"} />
            </div>
          </Tooltip>
        </Control>
        <Control position="topright"></Control>
        <FeatureGroup>
          <AntPath
            positions={allAhhvaPaths}
            options={{
              fillOpacity: 0.5,
              dashArray: [10, 1],
              pulseColor: "rgba(0,0,255,0.3)",
              opacity: 0.5,
              weight: 7,
              color: "rgba(0,0,255,0.3)",
              paused: true,
              hardwareAccelerated: true
            }}
          />
          {this.props.state.AhvvaData[this.props.state.targetFocus].data[
            this.props.state.carFocus
          ].paths_list.map((n, i) => {
            // console.log(n)
            if (i != this.props.state.pathFocus) {
              return (
                <AntPath
                  key={i}
                  positions={n.coordlist}
                  options={{
                    fillOpacity: 0.5,
                    dashArray: [10, 1],
                    pulseColor: "rgba(25,158,198, 0.5)",
                    opacity: 0.5,
                    weight: 7,
                    color: "rgba(25,158,198, 0.5)",
                    paused: true,
                    hardwareAccelerated: true
                  }}
                />
              );
            }
          })}
          {
            // Skip final node
            this.props.state.AhvvaData[this.props.state.targetFocus].data[
            this.props.state.carFocus
          ].paths_list[this.props.state.pathFocus].coordlist.slice(0, -1).map(
            (n, i) => {
              let speedlist = this.props.state.AhvvaData[this.props.state.targetFocus].data[this.props.state.carFocus].paths_list[this.props.state.pathFocus].speedlist;
              let coordlist = this.props.state.AhvvaData[this.props.state.targetFocus].data[this.props.state.carFocus].paths_list[this.props.state.pathFocus].coordlist;
              {
                let coordinatepair = [coordlist[i], coordlist[i + 1]];
                return (
                    <AntPath
                    key={uuid()}
                    positions={coordinatepair}
                    options={{
                        fillOpacity: 1,
                        dashArray: [10, 1],
                        pulseColor: AhvvaContent.get_segment_colour(speedlist[i + 1]) || "red",
                        opacity: 1,
                        weight: 7,
                        color: AhvvaContent.get_segment_colour(speedlist[i + 1]) || "red",
                        paused: true,
                        hardwareAccelerated: true
                    }}
                    />
                    
                );
              }
            }
          )
        }
          <Marker
            key={[
              this.props.state.targetFocus,
              this.props.state.targetFocus,
              this.props.state.pathFocus
            ]}
            icon={icon_start}
            ref={this.openPopup}
            position={
              this.props.state.AhvvaData[this.props.state.targetFocus].data[
                this.props.state.carFocus
              ].paths_list[this.props.state.pathFocus].coordlist[0]
            }
            onMouseOver={(e) => {
              e.target.openPopup();
            }}
          >
            <Popup>
              <span>
                {`Start`}
                <br />
                {`${
                  this.props.state.AhvvaData[this.props.state.targetFocus]
                    .carList[this.props.state.carFocus]
                } Path ${this.props.state.pathFocus}`}
                <br />
                {this.props.state.AhvvaData[this.props.state.targetFocus].data[
                  this.props.state.carFocus
                ].paths_list[this.props.state.pathFocus].time_impact
                  ? `${
                      this.props.state.AhvvaData[this.props.state.targetFocus]
                        .data[this.props.state.carFocus].paths_list[
                        this.props.state.pathFocus
                      ].time_impact[0]
                    } sec to impact`
                  : null}
              </span>
            </Popup>
          </Marker>
          {this.props.state.targetList.map((n, i) => {
            if (i == this.props.state.targetFocus) {
              return (
                <Marker
                  icon={targetIcon}
                  onclick={this.props.changeTarget.bind(this, i)}
                  position={n}
                  onMouseOver={(e) => {
                    e.target.openPopup();
                  }}
                >
                  <Popup>
                <span>Target {i+1}</span>
                  </Popup>
                </Marker>
              );
            } else {
              return (
                <Marker
                  key={n}
                  icon={targetD}
                  onclick={this.props.changeTarget.bind(this, i)}
                  position={n}
                  onMouseOver={(e) => {
                    e.target.openPopup();
                  }}
                >
                  <Popup>
                    <span>Target {i+1}</span>
                  </Popup>
                </Marker>
              );
            }
          })}
          {this.props.state.AhvvaData[this.props.state.targetFocus].data[
            this.props.state.carFocus
          ].paths_list[this.props.state.pathFocus].coordlist
            .slice(1)
            .map((n, i) => {
              const path = this.props.state.AhvvaData[
                this.props.state.targetFocus
              ].data[this.props.state.carFocus].paths_list[
                this.props.state.pathFocus
              ];
              const speed = path.speedlist[i + 1];
              const momentum = path.momentumlist[i + 1];

              let displayTimeToImpact;
              let displayDistanceFromStart;

              if (path.time_impact) {
                const timeToImpact = path.time_impact[i + 1];
                displayTimeToImpact = (
                  <li>{`${timeToImpact} sec to impact`}</li>
                );
              }
              if (path.distlist){
                const distanceFromStart = path.distlist[i + 1];
                displayDistanceFromStart = (
                  <li>{`${distanceFromStart} m from start`}</li>
                );
              }

              return (
                <Marker zIndexOffset={999} icon={icon_speed} position={n} 
                  onMouseOver={(e) => {
                  e.target.openPopup();
                }}>
                  <Popup>
                    <ul
                      style={{
                        listStyle: "none",
                        paddingLeft: "0"
                      }}
                    >
                      <li>{`${speed} km/h`}</li>
                      <li>{`${momentum} N`}</li>
                      {displayTimeToImpact}
                      {displayDistanceFromStart}
                    </ul>
                  </Popup>
                </Marker>
              );
            })}
        </FeatureGroup>
      </Map>
    );
  }
}
