import { AppException, UnknownException, JSONException } from './exceptions';

import axios from 'axios'

const REQUEST_EXCEPTION = 'REQUEST_EXCEPTION';
export async function httpFetch(params) {
  const { method, uri, formData, queryParams,bodyData, accessToken, mobile, isFileUpload } = params;
  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': isFileUpload ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
    },
    data: bodyData
  };

  if (accessToken && mobile) {
    options.headers = {
      ...options.headers,
      'X-Auth-Token': accessToken,
      'X-Auth-Mobile': mobile
    };
  }

  let formDataBody = {};

  if (formData && !isFileUpload) {
    formDataBody = serializeData(formData);
  } else if (formData && isFileUpload) {
    formDataBody = formData;
  }

  options.data = formDataBody;
  let endResolvedUri = uri;
  if (queryParams) {
    endResolvedUri = uri + '?' + serializeData(queryParams);
  }
  const axiosPromise = axios(endResolvedUri,options).then((res)=>{

    return res.data.data;
  }).catch((error)=>{

    const errorExceptionInfo = {
        uri: endResolvedUri,
        method,
        headers: options.headers,
        body: options.body,
        queryParams,

      };

        let parsedResut = error.response.data;

        throw AppException(
          REQUEST_EXCEPTION,
          parsedResut.status,
          parsedResut.msg,
          errorExceptionInfo
        );


  })

  return axiosPromise;
}

function serializeData(formBodyObj) {
  const requestBody = Object.keys(formBodyObj)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(formBodyObj[key]);
    })
    .join('&');

  return requestBody;
}
