const config = {
  VERSION: "0.0.1",
  API_ADDRESS: process.env.REACT_APP_STAGE === 'local' 
    ? "http://localhost:3001/forms"
    : "https://formservice.w86dev.click:5000/forms",
  USER_ADDRESS: process.env.REACT_APP_STAGE === 'local'
    ? "http://localhost:9100"
    : "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/authz-service",
  // USER_ADDRESS: "http://localhost:8080",
  // USER_ADDRESS:process.env.REACT_APP_STAGE == 'dev' ? "https://43sfx7klc7.execute-api.eu-west-1.amazonaws.com/dev/authz-service" : "https://srkd6ccl2g.execute-api.ap-southeast-2.amazonaws.com/prod/authz-service",
  REGION: "ap-southeast-2",
  //USER_POOL_ID: process.env.USER_POOL_ID || "ap-southeast-2_mQ7Hz6dUm",
  //USER_POOL_ID: "ap-southeast-2_qWbjdSOKA",
  USER_POOL_ID:process.env.REACT_APP_STAGE == 'dev' ? "ap-southeast-2_Kp9DpRrdK" : "ap-southeast-2_2f9Awkzdb",
  //APP_CLIENT_ID: process.env.APP_CLIENT_ID || "546ivvtvkrg9pfo18u08g1j3o2",
  //APP_CLIENT_ID: "3gq5l30vet2qou0rap6pik98vo",
  APP_CLIENT_ID:process.env.REACT_APP_STAGE == 'dev' ? "5f93lfrqqh7nruhjiiin8qbeem" : "3smqebtdtk2k225ttme2k5g375",
  DOMAIN: ".w86dev.click",
  //ISS: "https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_mQ7Hz6dUm",
  ISS: "https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_2f9Awkzdb",
  TERA_SUBMISSION_URL: "https://tsjgdv2vb9.execute-api.ap-southeast-2.amazonaws.com/development/tera",
  TEAN_SUBMISSION_URL: "https://tsjgdv2vb9.execute-api.ap-southeast-2.amazonaws.com/development/tean",
  AHVVA_SUBMISSION_URL: [
    "https://awe2dvmr2b.execute-api.ap-southeast-2.amazonaws.com/dev/ahvva/invocations",
    "https://tsjgdv2vb9.execute-api.ap-southeast-2.amazonaws.com/production/ahvva/private/invocations",
    "https://tsjgdv2vb9.execute-api.ap-southeast-2.amazonaws.com/development/ahvva/private/invocations"],

  //USAGE_ADDRESS: process.env.REACT_APP_STAGE == 'dev' ? 'https://tsjgdv2vb9.execute-api.ap-southeast-2.amazonaws.com/production/usage' : '',
  USAGE_ADDRESS: process.env.REACT_APP_STAGE == '',
  FILE_ADDRESS: process.env.REACT_APP_STAGE == 'dev' ? "https://dashboard.w86dev.click:9300/file/get" : "https://file.workflow86.com/file/get",
  PROJECT_SERVICE_URL: process.env.REACT_APP_STAGE == 'dev' ? "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/project-service/project" : "https://srkd6ccl2g.execute-api.ap-southeast-2.amazonaws.com/prod/project-service/project",

  ADMIN_DASHBOARD_URL: "http://localhost:3000",
  // ADMIN_DASHBOARD_URL:process.env.REACT_APP_STAGE == 'dev' ? "https://app.w86dev.click" : "https://app.workflow86.com",
  AUTOSAVE_INTERVAL: 30 ,// Seconds
  ADOBE_CONFIG_API_KEY: "88d8993b747244238c1b0f87c182882c",
  // ANNOTATION_SERVICE_PUBLIC_URL: "http://localhost:12000/public/graphql/annotation",
  // ANNOTATION_SERVICE_AUTH_URL: "http://localhost:12000/graphql/annotation",
  ANNOTATION_SERVICE_PUBLIC_URL: "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/form-annotation/public",
  ANNOTATION_SERVICE_AUTH_URL: "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/form-annotation/graphql/annotation",
  UPLOAD_MEDIA_SIZE:30000000,
};

export default config;
