import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import UserAccount from "./component/UserAccount";

import { TextField, Button } from "vardogyir-ui";
import { Grid, Container } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { styled } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const ErrorGrid = styled(Grid)({
    border: "1px solid rgba(176, 0, 32, 1)",
    backgroundColor: "rgba(176, 0, 32, 0.15)",
    padding: "8px",
    borderRadius: "4px",
    textAlign: "left",
    marginBottom: "4px",
    boxShadow: "4px 4px 0px 0px rgba(0, 0, 0, 0.25)"
});

const BoldGrid = styled(Grid)({
    fontWeight: "bold",
    marginBottom: "8px",
});

const LoggedInGrid = styled(Grid)({
    textAlign: "left",
    paddingLeft: "0px !important",
});

const Error = styled(ErrorIcon)({
    color: "rgba(176, 0, 32, 1)",
    height: "40px",
    width: "auto",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const ACCESS_ERROR_MSG = "You have not been granted access to this form or task. Contact the administrator or owner of this workflow.";
const COMPONENT_ERROR_MSG =
    "The component used to generate this page is no longer available. The component has been deleted or the workflow has been unpublished or deleted. Contact the administrator or owner of this workflow.";

function FormError(props) {
    const [errorType, setErrorType] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(async () => {

        Auth.currentSession()
        .then((userSession) => {
            if (userSession) setIsLoggedIn(true);
        })
        .catch((e) => {
            console.log(e);
        })

        let errorType = props.match.params.error_type;
        setErrorType(errorType ? errorType : "generic_error");
    }, []);

    const getErrorBasedOnType = (type) => {
        switch (type) {
            case "access":
                return formAccessError();
            case "already_submitted":
                return sessionAlreadySubmittedError();
            case "terminated":
                return sessionTerminatedError();
            case "unavailable":
                return formUnavailableError();
            default:
                return genericFormError();
        }
    };

    const formAccessError = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            Access error
                        </BoldGrid>
                        <Grid item xs={12}>
                            {ACCESS_ERROR_MSG}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const sessionAlreadySubmittedError = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            Session has already been submitted
                        </BoldGrid>
                        <Grid item xs={12}>
                            This session is no longer available because the session has been already been submitted. Contact the administrator or owner of this workflow.
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const sessionTerminatedError = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            ❌ Session has been terminated
                        </BoldGrid>
                        <Grid item xs={12}>
                            This session is no longer available because it has been terminated. Contact the administrator or owner of this workflow for additional information or assistance. 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const formUnavailableError = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            🚩 Component is unavailable
                        </BoldGrid>
                        <Grid item xs={12}>
                            {COMPONENT_ERROR_MSG}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const genericFormError = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            🔒 Access error
                        </BoldGrid>
                        <Grid item xs={12}>
                            {ACCESS_ERROR_MSG}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            📩 Session not available
                        </BoldGrid>
                        <Grid item xs={12}>
                            This session may have been submitted or terminated, and cannot be accessed. Contact the administrator or owner of this workflow.
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <BoldGrid item xs={12}>
                            ❌ Component error
                        </BoldGrid>
                        <Grid item xs={12}>
                            {COMPONENT_ERROR_MSG}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const formErrorTemplate = () => {
        return (
            <Grid container item xs={12} alignItems={"center"} direction={"row"} spacing={3} style={{ marginTop: "100px", maxWidth: "640px", display: "inline-flex", padding: "0px 32px" }}>                
                <ErrorGrid item xs={12}>
                    {errorType !== null ? getErrorBasedOnType(errorType) : getSkeletonLoaders()}
                </ErrorGrid>
                {isLoggedIn && (errorType === "access" || errorType === "generic_error") && (
                    <LoggedInGrid item xs={12}>
                        <Grid container spacing={1}>
                            <BoldGrid item xs={12}>
                                Are you logged into the correct Workflow86 account?
                            </BoldGrid>
                            <Grid item xs={12}>
                                You are currently logged in to the Workflow86 account below. If this is not the correct account for this workflow, log out and sign in again using the correct account.
                            </Grid>
                            <Grid item xs={12}>
                                <UserAccount />
                            </Grid>
                        </Grid>
                    </LoggedInGrid>
                )}
            </Grid>
        );
    };

    const getSkeletonLoaders = () => {
        return (
            <Grid container spacing={2}>
                {[1, 2, 3].map(() => {
                    return (
                        <Grid item xs={12}>
                            <StyledSkeleton variant="rect" height="75px" />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    return formErrorTemplate();
}

export default FormError;
