import React from "react";
import {
  Grid,
  Slide,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@material-ui/core";
import { Button } from "vardogyir-ui";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopy from "@material-ui/icons/FileCopy";
import { withStyles } from '@material-ui/core/styles';
import IconBanner from "./Icon/IconBanner";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import { FormFailuresText, getFailedUploadsBanner } from "./util/SubmissionUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  copyBtn: {
    cursor: 'pointer',
    '&:hover': {
      color: '#2196f3'
    }
  }
}

class SaveDialog extends React.Component {

  getFileUploadsBanner = (failedSave, failedUploads) => {
    const hasFailedUploads = failedUploads && Object.keys(failedUploads).length > 0;

    if (hasFailedUploads) return getFailedUploadsBanner(failedUploads);
    else if (!failedSave) {
      return (
        <IconBanner 
          bgColor="rgba(67, 160, 71, 0.2)"
          borderColor="rgba(67, 160, 71, 1)"
          icon={<CheckIcon style={{ fontSize: '16px', color: "rgba(67, 160, 71, 1)" }} />}
          content={<FormFailuresText>Uploaded files saved</FormFailuresText>}
        />
      );
    }

    return <></>;
  }

  render() {

    const { classes, formFailures } = this.props; 

    // Extract failedUploads and failedSave
    const { failedUploads, failedSave } = formFailures;

    return (
      <Grid container item xs={12} direction={"column"}>
        <Dialog
          open={this.props.open}
          TransitionComponent={Transition}
          onClose={() => this.props.close()}
          maxWidth={"sm"}
        >
          <DialogTitle>
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <h5>Save form</h5>
            </Grid>
          </DialogTitle>
          <DialogContent className={"start-alignment"}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!failedSave ? (
                  <IconBanner 
                    bgColor="rgba(67, 160, 71, 0.2)"
                    borderColor="rgba(67, 160, 71, 1)"
                    icon={<CheckIcon style={{ fontSize: '16px', color: "rgba(67, 160, 71, 1)" }} />}
                    content={<FormFailuresText>Form data saved</FormFailuresText>}
                  />
                ) : (
                  <IconBanner 
                  bgColor="rgba(225, 0, 0, 0.1)"
                  borderColor="rgba(225, 0, 0, 1)"
                  icon={<ErrorOutlineIcon style={{ fontSize: '16px', color: "rgba(225, 0, 0, 1)" }} />}
                    content={<FormFailuresText>Form data could not be saved. Please try again.</FormFailuresText>}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {this.getFileUploadsBanner(failedSave, failedUploads)}
              </Grid>
              {!failedSave && <>
                <Grid item xs={12}>
                  <p>
                    Use the link below to resume your session at any time.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="copyAddress"
                    className={"copy-text"}
                    fullWidth={true}
                    value={window.location.href}
                    variant="outlined"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard text={window.location.href}>
                            <FileCopy className={classes.copyBtn} />
                          </CopyToClipboard>
                        </InputAdornment>
                      )
                    }}
                  >
                    {window.location.href}
                  </TextField>
                </Grid>
              </>}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              theme={"S_blue"}
              onClick={() => {
                this.props.close();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(SaveDialog)