import React, { createRef, Component } from 'react'
import DisplayPath from './component/DisplayPath'
import DisplayText from './component/DisplayText'
import DisplayGraph from './component/DisplayGraph'
import {withStyles} from "@material-ui/core/styles/index";
import axios from "axios";
import config from "../config";

const styles = theme => ({
    Button: {
        color: '#2196F3',
        backgroundColor: '#444444',
        '&:hover': {
            backgroundColor: '#2f2f2f',
        }
    },
})




class AhvvaResult extends React.Component {
  constructor () {
    super()

    this.state = {

        Ahvva:[]
    }
  }

// componentWillUnmount() {
//       localStorage.setItem('AHVVA','')
//      if(document.getElementById('main-header')){document.getElementById('main-header').hidden = false;}
// }

async componentDidMount() {

      if(document.getElementById('main-header')){document.getElementById('main-header').hidden = true;}
      try{
          const res = await  axios(`${config.API_ADDRESS}/ahvva_result?req_id=${this.props.match.params.req_id}`, {
                                  method: 'get',

                                  headers: {
                                      'Accept': 'application/json',
                                      'Content-Type': 'application/json',

                                  },

                              })
          //  let data = JSON.parse( localStorage.getItem('AHVVA'))
          console.log(res.data.data.data)
            this.setState({Ahvva:res.data.data.data})
      }
      catch (e) {
            console.log(e)
      }

}
_detectType=(weight,speed)=>{
      switch (weight) {
          case 1500:
              return 'M1'
          case 2500:
              return 'N1G'
          case 3500:
              return 'N1'
          case 7500:
              return speed ==  64? 'N2': 'N3_7500'
          case 30000:
              return 'N3_30000'
          default:
              return ''
      }
}
_detectName = (type)=>{
       switch (type) {
          case "M1":
              return 'Passenger car'
          case "N1G":
              return '4x4 pick up'
          case "N1":
              return '3.5 tonne truck'
           case "N2":
               return '3.5 tonne truck'
          case "N3_7500":
              return  '7.5 tonne truck'
          case 'N3_30000':
              return '7.5 tonne truck type 2'
          default:
              return ''
      }
}
    render () {
      const data = this.state.Ahvva
      const result = []
        for(let i in data){
            for(let j in data[i].vehicle_list){
                let carType = this._detectType(data[i].vehicle_list[j].weight,data[i].vehicle_list[j].top_speed)
                let name = this._detectName(carType)
                let acceleration = data[i].vehicle_list[j].acceleration
                let coeff_fric = data[i].vehicle_list[j].coeff_fric
                let weight = data[i].vehicle_list[j].weight
                let top_speed = data[i].vehicle_list[j].top_speed
                for(let k in data[i].vehicle_list[j].paths_list){
                    let n = data[i].vehicle_list[j].paths_list[k]
                    n.title = `${ carType} ${n.pair_id}  Path ${i%5}`
                    n.carType = carType
                    n.name = name
                    n.acceleration = acceleration
                    n.coeff_fric = coeff_fric
                    n.weight = weight
                    n.top_speed = top_speed

                    result.push(<div>
                            <div className={'page'}>
                           <DisplayPath data={n} page_index={1}/>
                        </div>
                           <div className={'page'}>
                           <DisplayText data={n} page_index={2}/>
                        </div>
                            <div className={'page'}>
                           <DisplayGraph data={n} page_index={3}/>
                        </div>
                       </div>)
                }

            }

                        }

       const { classes } = this.props;
       try{

           return (
               <div>
                   {result}

               {/*{this.state.Ahvva.map((n,i)=>{*/}
                   {/*n.title = `${ this._detectType(n.weight,n.top_speed)} ${n.pair_id}  Path ${i%5}`*/}
                    {/*n.carType = this._detectType(n.weight,n.top_speed)*/}
                   {/*n.name = this._detectName(n.carType)*/}
                   {/*return(<div>*/}
                        {/*<div className={'page'}>*/}
                       {/*<DisplayPath data={n} page_index={1}/>*/}
                    {/*</div>*/}
                       {/*<div className={'page'}>*/}
                       {/*<DisplayText data={n} page_index={2}/>*/}
                    {/*</div>*/}
                        {/*<div className={'page'}>*/}
                       {/*<DisplayGraph data={n} page_index={3}/>*/}
                    {/*</div>*/}
                   {/*</div>)*/}
               {/*})}*/}



               </div>
    )}
       catch (e) {
           return<p>Loading...</p>
       }


  }
}

export default withStyles(styles)(AhvvaResult)