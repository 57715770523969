import React, { Component } from 'react';
import { connect } from "react-redux";
import { goBackToANY } from "../../store/form/actions";

function RenderBreadCrum(props) {
  const { page, index } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {index > 0 && <span>/</span>}
      &nbsp;
      <p
        onClick={() => props.goBackToANY(index)}
        style={{
          cursor: "pointer",
          maxWidth: "20vw",
          margin: 0,
          overflowX: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        {page.title.view.data.default}
      </p>
      &nbsp;
    </div>
  )
}

class PreviewBreadCrums extends Component {

  render() {
    const { request_data, current_index } = this.props.form;
    return (
      <div
        style={{
          display: "flex",
          color: "#868686",
          margin: "16px 0",
        }}
      >
        {request_data.length > 1 && current_index > 0 &&
          request_data.map((page, index) => {
            if (current_index >= index) {
              return (
                <RenderBreadCrum
                  key={index}
                  page={page}
                  index={index}
                  goBackToANY={this.props.goBackToANY}
                />
              )
            }
          })}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    form: state.form
  };
};

const mapDispatchToProps = {
  goBackToANY
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewBreadCrums);
