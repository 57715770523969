import { CircularProgress, styled, Typography, Button as MUIButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React from "react";

const ResubmitButton = styled(MUIButton)({
    background: "#000",
    color: "#FFF",
    fontSize: "13px",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.85)",
    },
});

const SubmitButton = styled(MUIButton)({
    background: "#000",
    color: "#FFF",
    fontSize: "13px",
    "&:hover": {
        background: "#2196F3",
    },
    "&:disabled": {
        background: "#ccc",
        color: "#666",
    },
});

function SubmitButtons(props) {
    const getSubmitButtons = () => {
        if (props.isTerminated || !props.canSubmitSubmission) return <></>;
        return (
            <>
                {props.hasBeenSubmitted ? (
                    <>
                        <Typography className={props.classes.instructions} id={`submit-text-${props.form.current_index}`}>
                            Click to re-submit this form
                        </Typography>
                        <ResubmitButton
                            disabled={(props.sended || props.pageProcessing || !props.canSubmitSubmission || props.readOnly || props.previewMode) && !props.isTest}
                            variant="contained"
                            onClick={props.openResubmitDialog}
                            startIcon={<Refresh />}
                        >
                            Resubmit
                            {props.pageProcessing && <CircularProgress className="buttonProgress" size={20} />}
                        </ResubmitButton>
                    </>
                ) : (
                    <>
                        <Typography className={props.classes.instructions} id={`submit-text-${props.form.current_index}`}>
                            Click to submit this form
                        </Typography>
                        <SubmitButton
                            disabled={(props.sended || props.pageProcessing || !props.canSubmitSubmission || props.readOnly || props.previewMode) && !props.isTest}
                            variant="contained"
                            onClick={props.handleSubmit}
                        >
                            Submit {props.pageProcessing && <CircularProgress className="buttonProgress" size={20} />}
                        </SubmitButton>
                    </>
                )}
            </>
        );
    };

    return getSubmitButtons();
}

export default SubmitButtons;
