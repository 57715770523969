
import React from "react";
import ViewSDKClient from "../utils/ViewSDKClient";
import FileViewer from "react-file-viewer";
const renderFile = ({fileType, filePath, fileName}) => {
    const loadPDF = () => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
                "pdf-div",
                {
                    defaultViewMode: "FIT_WIDTH",
                    showAnnotationTools: true,
                    showLeftHandPanel: false,
                    showPageControls: true,
                    showDownloadPDF: false,
                    showPrintPDF: false,
                    showCommentsPanel:false
                },
                filePath,fileName
            );
        });
    };


    switch (fileType) {
        case 'csv':
        case 'xlsx':
        case 'docx':
        case 'pptx':
            return <div style={{borderRadius:10, height: "100vh", overflow:"hidden"}}>
            <iframe className={fileType} width="100%" height="100%"  frameBorder="0"  id="doc-div"
                           src={`https://docs.google.com/gview?url=${filePath}&embedded=true`}  >
            </iframe></div>



        case 'pdf':
            return   <div className={fileType}>
                <div
                    style = {{height:"100vh"}}
                    id="pdf-div"
                    className="full-window-div border border-gray-100 h-screen" onChange=
                        {loadPDF()}
                >
                </div>
            </div>
        case '':
            return ' '
        default:
            return <FileViewer
                fileType={fileType}
                filePath={filePath}
                unsupportedComponent={() => {
                    return (
                        <div>
                            The file cannot be previewed. Only .{fileType} is supported
                        </div>
                    )
                }}

            />

    }

};
export default renderFile;