import React, { useState } from "react";

// Material UI
import { Grid, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { EditBoxInput, EditGrid } from "../Util/AnnotationStyledComponents";
import { breakToNewLine } from "../Util/AnnotationHelpers";

function EditBox({ comment, handleEditComment, thread }) {
    const [text, setText] = useState(breakToNewLine(comment.value));

    return (
        <EditGrid container>
            <Grid item xs={12}>
                <EditBoxInput
                    fullWidth
                    multiline
                    id={`${thread.annotationId}-edit-input`}
                    style={{ width: "100%" }}
                    variant="outlined"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Edit your comment"
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                id={`${thread.annotationId}-edit-button`}
                                style={{ outline: "none", padding: "0px" }}
                                onClick={() => {
                                    handleEditComment(text, comment);
                                    setText(text);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
        </EditGrid>
    );
}

export default EditBox;
