export function getLegendColor (d) {
  return d > 100
    ? "#8E0000"
    : d > 90
    ? "#8E0000"
    : d > 80
    ? "#CC0000"
    : d > 70
    ? "#FE4300"
    : d > 60
    ? "#FF8105"
    : d > 50
    ? "#FECA02"
    : d > 40
    ? "#FFFF00"
    : d > 30
    ? "#C1FF00"
    : d > 20
    ? "#93D150"
    : d > 10
    ? "#00B050"
    : "#007434";
};
