import dayjs from "dayjs";
import { performCalcOnInitialTable } from "./HandleCalc";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

// Used to create an empty table with relevant datetime objects
// And empty strings if default  value is not given
export const emptyTableWithDatetime = (data, shown) => {
  const rowNum = parseInt(data.numRows);
  const colNum = data.data.length;
  const cols = data.data;

  const tableCells = data.tableCells;

  let table = [];

  // Loop over rows and columns
  for (let rowIdx = 0; rowIdx < rowNum; rowIdx++) {
    if (shown && rowIdx >= shown) break;
    let tableRow = createTableRow(cols, tableCells, colNum, rowIdx);
    table.push(tableRow);
  }

  // Perform initial calculation on table and return
  return performCalcOnInitialTable(table, tableCells, cols);
};

export const createTableRow = (cols, tableCells, colNum, rowIdx) => {
  let tableRow = [];
    
  for (let colIdx = 0; colIdx < colNum; colIdx++) {
    let currCol = cols[colIdx];

    // Init final value structure
    let parsedFinalValue = {
      defaultValue: "",
      calculatedValue: "",
    };

    // Call the relevant function based on the type
    switch (currCol.type) {
      case "text":
        parsedFinalValue = handleOtherTypes(tableCells, currCol, rowIdx);
        break;
      case "datetime":
        parsedFinalValue = handleDatetimeType(tableCells, currCol, rowIdx);
        break;
      case "number":
        if (tableCells != null) {
          let defaultVal = tableCells[currCol.id][rowIdx].defaultValue;
          const checkCalc = defaultVal.match(/(\={(?:\[??[^\[]*?\}))|"([0-9+ ]+)"/g);
          if (checkCalc != null) {
            tableCells[currCol.id][rowIdx].defaultValue = checkCalc.join("")
          }
        }
        parsedFinalValue = handleOtherTypes(tableCells, currCol, rowIdx);
        break;
      case "dropdown":
        parsedFinalValue = handleOtherTypes(tableCells, currCol, rowIdx);
        break;
      default:
        break;
    }

    tableRow.push(parsedFinalValue); // Add to row
  }

  return tableRow;
}

// Handles: Text, Dropdown & Number
// Returns an empty string of table cells doesn't exist
// Else returns the parsed default value
const handleOtherTypes = (tableCells, currCol, rowIdx) => {
  let ans = {
    defaultValue: "",
    calculatedValue: "",
  };

  if (tableCells == null || tableCells[currCol.id] == null) return ans;
  if (tableCells[currCol.id][rowIdx] == null) return ans;

  ans.defaultValue = tableCells[currCol.id][rowIdx].defaultValue;
  return parseDefaultValFromType(ans, currCol);
};

// Handles: Datetime
// Returns an empty string of table cells doesn't exist
// Else returns the parsed default value
const handleDatetimeType = (tableCells, currCol, rowIdx) => {
  let defaultDtCell = {
    value: {
      defaultValue: "",
      calculatedValue: "",
    },
    outputFormat: currCol.dtFormat ? currCol.dtFormat.outputFormat : "",
    timezone: currCol.dtFormat ? currCol.dtFormat.timezone : "",
    type: currCol.type,
  };

  if (tableCells == null || tableCells[currCol.id] == null)
    return defaultDtCell;
  if (tableCells[currCol.id][rowIdx] == null) return defaultDtCell;

  defaultDtCell.value.defaultValue =
    tableCells[currCol.id][rowIdx].defaultValue;

  defaultDtCell.value = parseDefaultValFromType(defaultDtCell.value, currCol);

  return defaultDtCell;
};

// Checks that the type is correct to preset the column
// If not, returns an empty string
const parseDefaultValFromType = (value, column) => {
  if (value.defaultValue === "") return value;

  const parsedValue = { ...value };

  switch (column.type) {
    case "text":
      return parsedValue;
    case "number":
      if (!isNaN(parsedValue.defaultValue)) {
        parsedValue.calculatedValue = value.defaultValue;
        return parsedValue;
      }
      break;
    case "dropdown":
      if (
        column.options.some((opt) => opt.value === parsedValue.defaultvalue)
      ) {
        parsedValue.calculatedValue = value.defaultValue;
        return parsedValue;
      }
      break;
    case "datetime":
      parsedValue.calculatedValue = getEpochVal(
        parsedValue.defaultValue,
        column
      );
      return parsedValue;
  }

  return parsedValue;
};

// Get the epoch value based on the format of the datetime string
const getEpochVal = (val, currCol) => {
  if (!currCol.dtFormat) return "";
  let currOutputFormat = currCol.dtFormat.outputFormat;
  let epochVal = "";

  // Get epoch value dependant on the format
  switch (currOutputFormat) {
    case "dd LLLL YYYY":
      let format1 = getCorrectFormat(val.toString());
      epochVal = dayjs(format1, "DD/MM/YYYY").valueOf();
      break;
    case "dd LLLL YYYY kk:mm":
      let format2 = getCorrectFormat(val.toString());
      epochVal = dayjs(format2, "DD/MM/YYYY HH:mm").valueOf();
      break;
    case "dd/MM/YY":
      epochVal = dayjs(val.toString(), "DD/MM/YY").valueOf();
      break;
    case "dd/MM/YY kk:mm":
      epochVal = dayjs(val.toString(), "DD/MM/YY HH:mm").valueOf();
      break;
    case "dd/MM/YYYY":
      epochVal = dayjs(val.toString(), "DD/MM/YYYY").valueOf();
      break;
    case "dd/MM/YYYY kk:mm":
      epochVal = dayjs(val.toString(), "DD/MM/YYYY HH:mm").valueOf();
      break;
    case "dd-MM-YYYY":
      epochVal = dayjs(val.toString(), "DD-MM-YYYY").valueOf();
      break;
    case "dd-MM-YYYY kk:mm":
      epochVal = dayjs(val.toString(), "DD-MM-YYYY HH:mm").valueOf();
      break;
    case "MM/dd/YYYY":
      epochVal = dayjs(val.toString(), "MM/DD/YYYY").valueOf();
      break;
    case "MM/dd/YYYY kk:mm":
      epochVal = dayjs(val.toString(), "MM/DD/YYYY HH:mm").valueOf();
      break;
    case "YYYY-MM-dd":
      epochVal = dayjs(val.toString(), "YYYY-MM-DD").valueOf();
      break;
    case "YYYY-MM-dd kk:mm":
      epochVal = dayjs(val.toString(), "YYYY-MM-DD HH:mm").valueOf();
      break;
    case "Unix":
      epochVal = dayjs(parseInt(val.toString())).valueOf();
      break;
    default:
      epochVal = "";
      break;
  }

  // If NAN or Invalid, return ""
  if (isNaN(epochVal) || epochVal === "Invalid Date") return "";
  else return epochVal;
};

// Get correct format for dates with a month literal
// i.e: September
const getCorrectFormat = (s) => {
  let splitStr = s.split(" ");
  splitStr[1] =
    months.indexOf(splitStr[1]) + 1 < 10
      ? "0" + (months.indexOf(splitStr[1]) + 1)
      : months.indexOf(splitStr[1]) + 1;

  return splitStr.join(" ");
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
