import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from "vardogyir-ui";
import Comment from "./Comment";
import SignatureCanvas from 'react-signature-canvas';
import {Hidden} from "@material-ui/core";

const styles = theme => ({

    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    textroot: {
        width: '100%',
        margin: 'auto'
    }

});


class ESignature extends React.Component {

    
    constructor(props) {
        
        super(props);
        this.state = {
            answer: ''
            
        };
        this.signtaurePad = React.createRef();

    }

    componentDidMount() {
        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            this.setState({ answer: this.props.form.answer_data[this.props.data.key].answer[0] })
        } else {
            if (this.props.data.data.toString() && this.state.answer == '') {
                this.setState({ answer: this.props.data.data })
                this.props.getDataFromComponent({ 'esignature_answer': this.props.data.data }, this.props.data.key, this.props.data.question_text, this.props.data.data)
            }

            if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {
                this.props.getDataFromComponent({ 'esignature_answer': this.props.data.auto }, this.props.data.key, this.props.data.question_text, this.props.data.data)
                this.setState({ answer: this.props.data.auto })
            }
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //nothing to do
    }

    handleOnChange = (event) => {

      this.setState({ answer: event.target.value })
    }

    handleOnBlur = (event) => {

        let text = { 'esignature_answer': this.signtaurePad.getCanvas().toDataURL('image/jpeg') }
        this.props.getDataFromComponent(text, this.props.data.key, this.props.data.question_text, this.props.data.data)

    };

    doNothing = () => {}

    initRef = (ref) =>{

        this.signtaurePad = ref;

        if(this.signtaurePad && this.state.answer)
            this.signtaurePad.fromDataURL(this.state.answer);

        if(this.signtaurePad && this.props.data.read_only)
            this.signtaurePad.off();
            
    }
    render() {
        const { classes, data } = this.props;

        try {
            return (
                <div className={classes.root}>
                  
                  <div style={{width:"fit-content"}}>
                    <Hidden smDown>
                        <SignatureCanvas penColor='black'
                            canvasProps={{width: 500, height: 120, style: {borderColor:"#868686",borderStyle:"solid",borderWidth:"thin",width:"fit-content",borderRadius:"8px"}}} 
                            ref = {this.initRef}      
                            onEnd={this.props.data.read_only  ? this.doNothing : this.handleOnBlur}
                            backgroundColor="white"
                            />         
                    </Hidden>       

                    <Hidden mdUp>
                        <SignatureCanvas penColor='black'
                            canvasProps={{style: {maxWidth:"500px", minHeight:"200px", maxHeight:"200px",borderColor:"#868686",borderStyle:"solid",borderWidth:"thin",width:"100%",borderRadius:"8px"}}} 
                            ref = {this.initRef}      
                            onEnd={this.props.data.read_only  ? this.doNothing : this.handleOnBlur}
                            backgroundColor="white"
                            />         
                    </Hidden>    

                    <div style={{display:"flex",justifyContent:"start"}}>
                        <a 
                            style={{cursor:"pointer", fontSize:"12px", color:"#868686"}}
                            onClick={()=>{
                                this.signtaurePad.clear();
                            }}
                            id={`${data.key}-clear-signature-text`}
                        >
                            Clear signature
                        </a>
                    </div>
                  </div>
                </div>
            );
        } catch (e) {
            return (<h5>Error encountered</h5>)
        }

    }
}


const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {};


ESignature.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ESignature));
