/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/
import config from "../../config"
class ViewSDKClient {
    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Document Services PDF Embed API to be ready */
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }

    ready() {
        return this.readyPromise;
    }
    previewFile(divId, viewerConfig, url,fileName) {
        const adobeConfig = {
            clientId: config.ADOBE_CONFIG_API_KEY, ///enter client id here
        };
        if (divId) {
            adobeConfig.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(adobeConfig);
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                content: {
                    location: {
                        url: url,
                    },
                },
                metaData: {
                    fileName: fileName,
                    id: " ",
                },
            },
            viewerConfig
        );
        return previewFilePromise;
    }


}

export default ViewSDKClient;