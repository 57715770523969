import React, { useEffect } from "react";
import JoditEditor from "jodit-pro-react";
import ReactHtmlParser from "react-html-parser";

function DisplayDatabaseContent({ data, isAnnotationModeOn, getDataFromComponent }) {
    useEffect(() => {
        // Set the pulled value into the state so we catch it on submission
        getDataFromComponent(data.data, data.key, data.question_text, data.data);
    }, []);

    const getStringValue = () => {
        try {
            return String(data.data);
        } catch (e) {
            return "";
        }
    };

    return !isAnnotationModeOn ? (
        <div
            style={{
                "& .jodit-container": {
                    border: "none !important",
                },
                "& .jodit-wysiwyg": {
                    padding: "0px !important",
                },
                marginTop: "1rem",
            }}
        >
            <JoditEditor
                value={getStringValue()}
                config={{
                    readonly: true,
                    disablePlugins: "resizer",
                    useIframeResizer: false,
                    toolbar: false,
                    showCharsCounter: false,
                    showWordsCounter: false,
                    showXPathInStatusbar: false,
                    minHeight: "unset",
                }}
                tabIndex={1} // tabIndex of textarea
            />
        </div>
    ) : (
        ReactHtmlParser(getStringValue())
    );
}

export default DisplayDatabaseContent;
