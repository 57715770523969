import React, { createRef, Component } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import L from 'leaflet'
import {withStyles} from "@material-ui/core/styles/index";
import {antPath} from 'leaflet-ant-path';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Scatter} from 'react-chartjs-2';
import AhvvaContent from "./AhvvaContent";

const styles = theme => ({
    Button: {
        color: '#2196F3',
        backgroundColor: '#444444',
        '&:hover': {
            backgroundColor: '#2f2f2f',
        }
    },
    details: {
    alignItems: 'center',
        width:'100%'
  },
    table:{
        width:'1000'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
})




class DisplayText extends React.Component {
  constructor () {
    super()

  }



    componentDidMount() {





}


    render () {

       try{
           
            let consequence_rating = AhvvaContent.get_consequence_rating(this.props.data.momentum);
            let consequence_text = AhvvaContent.get_consequence_text(consequence_rating);
            let likelihood_rating = AhvvaContent.get_likelihood_rating(this.props.data.sum_angle);
            let likelihood_text = AhvvaContent.get_likelihood_text(likelihood_rating);
            let overall_rating = AhvvaContent.get_overall_rating(consequence_rating, likelihood_rating)
            let overall_text = AhvvaContent.get_overall_text(overall_rating);
            let barrier_text = AhvvaContent.get_barrier_text(this.props.data.sum_angle, this.props.data.velocity)
            let recommendation_text = AhvvaContent.get_recommendation_text(this.props.data.pair_id, this.props.data.name, this.props.data.velocity, this.props.data.momentum, this.props.data.kinetic_energy, barrier_text)
           
           return (
           <div>
               <div className={'subpage'}>
                      <div  style={{width:'100%'}}>


            <h1 style={{whiteSpace:'pre-line'}}>
                {`${this.props.data.title}   Page:${this.props.page_index}`}
            </h1>



<br/>


            <div style={{boxSizing:'unset',width:'100%'}}>





                <div style={{height:'230px'}}>
                    <h2 style={{textAlign:'left'}}><strong>Consequence</strong></h2>
                    <h4 style={{textAlign:'left'}}>{consequence_text}</h4>

                </div>
                <div style={{height:'300px'}}>
                    <h2 style={{textAlign:'left'}}><strong>Likelihood</strong></h2>
                    <h4 style={{textAlign:'left'}}>{likelihood_text}</h4>

                </div>
                 <div style={{height:'270px'}}>
                    <h2 style={{textAlign:'left'}}><strong>Overall risk</strong></h2>
                    <h4 style={{textAlign:'left'}}>{overall_text}</h4>

                </div>

                <div style={{height:'300px'}}>
                    <h1 style={{textAlign:'left'}}><strong>Recommendation</strong></h1>
                    <h4 style={{textAlign:'left'}}>{recommendation_text}</h4>

                </div>


            </div>



        </div>
               </div>

               <div style={{pageBreakAfter: 'always'}}></div>
               </div>
    )}
       catch (e) {
           return<p>Loading...</p>
       }


  }
}

export default withStyles(styles)(DisplayText)
