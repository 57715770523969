import config from "../../config";
import Amplify, { Auth } from "aws-amplify";
import axios from "axios";

export async function send_request_graphql(path, query) {
    let url = `${config.ANNOTATION_SERVICE_PUBLIC_URL}/${path}`;
    let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    // If it's a logged in user, set the auth header and change the url
    try {
        let auth = (await Auth.currentSession()).getIdToken().getJwtToken();
        header["Authorization"] = auth;
        url = `${config.ANNOTATION_SERVICE_AUTH_URL}/${path}`;
    } catch (e) {}

    const request = await axios(url, {
        headers: header,
        method: "POST",
        data: { query: query },
    })
        .then(
            (res) => {
                return res;
            },
            (rej) => {
                throw rej;
            }
        )
        .catch(async (error) => {
            throw error;
        });
    return request;
}
