import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { addPageData } from "../../store/form/actions";
import Comment from "./Comment";

const styles = (theme) => ({
  checkbox: {
    marginRight: 40,
    color: "#afafaf",
  },
  checked: {},
  listtext: {
    fontSize: "13px",
  },
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
});

class Multiplechoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = { multiple_answer: {} };
  }

  findIndex = (data, container) => {
    function hasObject(element, target) {
      return element.toString() == target.toString();
    }

    let i = container.findIndex((n) => {
      let a = hasObject(n, data);
      if (a == true) {
        return true;
      }
    });
    return i;
  };

  componentDidMount() {
    // console.log(this.props.data)
    let temp = this.state.multiple_answer;
    this.props.data.data.map((n) => {
      temp[n[0]] = false;
    });
    let answer_data = this.props.form.answer_data[this.props.data.key];
    if (answer_data && answer_data.answer) {
      for (let key in this.props.form.answer_data[this.props.data.key].answer) {
        let index = this.findIndex(
          this.props.form.answer_data[this.props.data.key].answer[key],
          this.props.data.data
        );
        temp[this.props.data.data[index][0]] = true;
      }

      this.setState({ multiple_answer: temp });
    } else {
      if (this.props.data.auto.toString()) {
        let temp = this.state.multiple_answer;
        this.props.data.data.map((n) => {
          temp[n[0]] = false;
        });
        let index = this.findIndex(this.props.data.auto, this.props.data.data);

        if (this.props.data.data[index]) {
          temp[this.props.data.data[index][0]] = true;
          this.props.dispatchaddPageData(
            this.props.data.key,
            typeof this.props.data.auto[0] == "object"
              ? this.props.data.auto
              : [this.props.data.auto],
            this.props.data.question_text
          );
          // this.props.getDataFromComponent({multiple_answer: temp},this.props.data.key,this.props.data.question_text,this.props.data.data)
          this.setState({ multiple_answer: temp });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {
    if (this.props.data.auto != prevProps.data.auto) {
      let temp = this.state.multiple_answer;
      this.props.data.data.map((n) => {
        temp[n[0]] = false;
      });
      let index = this.findIndex(this.props.data.auto, this.props.data.data);

      if (this.props.data.data[index]) {
        temp[this.props.data.data[index][0]] = true;
        this.props.dispatchaddPageData(
          this.props.data.key,
          typeof this.props.data.auto[0] == "object"
            ? this.props.data.auto
            : [this.props.data.auto],
          this.props.data.question_text
        );
        // this.props.getDataFromComponent({multiple_answer: temp},this.props.data.key,this.props.data.question_text,this.props.data.data)
        this.setState({ multiple_answer: temp });
      }
    }
  }

  handleChange = (name) => (event) => {
    let temp = this.state.multiple_answer;
    temp[name] = !temp[name];
    this.setState({ multiple_answer: temp });
    this.props.getDataFromComponent(
      this.state,
      this.props.data.key,
      this.props.data.question_text,
      this.props.data.data
    );
  };

  doNothing = () => {};
  render() {
    const { classes, form, data, isAnnotationModeOn } = this.props;
    try {
      return (
        <div className={classes.root}>
          <List component="nav">
            {this.props.data.data.map((n, i) => {
              return (
                <ListItem
                  dense
                  button={!isAnnotationModeOn ? true : false}
                  onClick={!isAnnotationModeOn ?
                    (this.props.data.read_only == true
                      ? this.doNothing()
                      : this.handleChange(n[0]))
                   : undefined}
                >
                  <Checkbox
                    classes={{
                      root: this.props.data.style || classes.checkbox,
                      checked: classes.checked,
                    }}
                    disabled={this.props.data.read_only}
                    checked={this.state.multiple_answer[n[0]] || false}
                    onCheck={!isAnnotationModeOn ?
                      (this.props.data.read_only
                        ? this.doNothing()
                        : this.handleChange(n[0]))
                     : undefined}
                    value={n[0]}
                  />

                  <div>
                    <div style={{ textAlign: "left", display: "inline" }}>
                      <ListItemText
                        className={classes.listtext}
                        disableTypography={true}
                        primary={
                          <p
                            style={{
                              marginBottom: 0,
                              fontWeight: "normal",
                            }}
                            id={`${data.key}-${i}-answer-text`}
                          >
                            {n[1]}
                          </p>
                        }
                      />
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {
  dispatchaddPageData: (key, answer, question_text) =>
    addPageData({ key, answer, question_text }),
};

Multiplechoice.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Multiplechoice));
