import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "vardogyir-ui";
import config from "../../config";
import Amplify, { Auth } from "aws-amplify";
import { styles } from './Style';
import { fetchInfoPreview, handleSubmitStart } from './ServicePreview'
// const uuid = require("uuid");
import UserAccount from "../component/UserAccount";


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)(change to 'localhost' to work on local)
      domain: window.location.host.includes("localhost")
        ? "localhost"
        : config.DOMAIN,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).      
      secure: true,
      sameSite: "none"
    },
  }
});
class FormLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_logo: "",
      form_id: "",
      form_title: "",
    };
  }
  async componentDidMount() {
    //Auth.signOut();
    const { form_id,projectVersion } = this.props.match.params;
    const json = await fetchInfoPreview(form_id,projectVersion);
    if (json) {
      let data = json.data;
      this.setState({
        form_logo: data.form_logo,
        form_title: data.form_title
      });
    }
  }


  handleSubmit = async () => {
    const { form_id, projectVersion } = this.props.match.params;
    const session_id = `preview_${form_id}`;
    const data = {
      data: {},
      session_id,
      form_id,
      projectVersion
    }
    const json = await handleSubmitStart(data);
    if (json.status == 201 || json.status == 200) {
      const url = `/form/form_preview/${form_id}/${projectVersion}/${session_id}`;
      this.props.history.push(url);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layoutPreview} style={{minHeight:"unset"}}>
        <nav
          id="main-header"
          className="navbar navbar-expand-lg navbar-light text-right "
        >
          <div
            className="container"
            style={{ maxWidth: 1500, textAlign: "right", flexWrap: "unset" }}
          >
            <a className="navbar-brand" href="https://www.vardogyir.com/">
              <img
                src={"/images/w86_logo.png"}
                height="40"
                alt="logo"
              />
            </a>
          </div>
        </nav>
        <div className={classes.paperPreview}>
          <div className="divForm">
            <div style={{display:"flex",justifyContent: "flex-end"}}>
              <UserAccount/>
            </div>
            <div>
              {
                this.state.form_logo == "DELETE" || this.state.form_logo == ""
                  ?
                  (
                    <img
                      style={{maxHeight:"200px"}}
                      className="imageForm"
                      src={"/images/default-image-form.png"}
                    />
                  )
                  :
                  (
                    <img
                      style={{maxHeight:"200px",marginBottom:24}}
                      className="imageForm"
                      src={this.state.form_logo}
                    />
                  )
              }
            </div>
            {this.state.form_title ? (
                <div>
                  <h2
                    style={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginBottom: 32
                    }}
                  >
                    {this.state.form_title} (Preview)
                  </h2>
                </div>
              ) :
              (
                <div>
                  <h2
                    style={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginBottom: 32
                    }}
                  >
                    Untitled (Preview)
                  </h2>
                </div>
              )}
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={this.handleSubmit}
                theme={"P_blue"}
                style={{margin: "16px 0px"}}
              >
                Start
              </Button><br/>
              <div className={classes.textArea} style={{width:"100%",display:"unset"}}>
                  <span>By proceeding you agree to the </span>
                  <span>
                    <a
                      target="_blank"
                      className="conditionText"
                      href={"https://www.workflow86.com/terms-and-conditions-of-use/"}
                    >
                      {" "}
                      Conditions of Use
                    </a>
                  </span>
                </div>
            </div>
          </div>
        </div>
        <div className={classes.footerPreview}>
          <p style={{ margin: 0, fontSize: "18px" }}>FORM PREVIEW AND TESTING</p>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(FormLanding);
