import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { addPageData } from "../../store/form/actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldMUI from "@material-ui/core/TextField";

const styles = (theme) => ({
  checkbox: {
    color: "#afafaf",
    "&$checked": {
      color: "#2196F3",
    },
  },
  listtext: {},
  checked: {},
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  annotationSingleSelect: {
    border: "1px solid #d5d5d5",
    padding: "6px 10px 10px 10px",
    borderRadius: "4px",
    fontSize: "16px",
    marginTop: "5px",
    overflow: "hidden",
    lineHeight: "1.1876em",
    letterSpacing: "inherit",
    fontWeight: 400
  }
});

class SingleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { single_select: "" };
  }

  componentDidMount() {
    let answer_data = this.props.form.answer_data[this.props.data.key];
    if (answer_data && answer_data.answer && answer_data.answer[0]) {
      // this.setState({single_select: this.props.form.answer_data[this.props.data.key].answer[0]})
      this.setState({
        single_select: this.props.form.answer_data[this.props.data.key]
          .answer[0][1],
      });
    }
    else  {

      if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key] && this.props.data.auto[1]) {
        this.props.getDataFromComponent({ 'single_select': this.props.data.auto[1] }, this.props.data.key, this.props.data.question_text, this.props.data.data)
        this.setState({ single_select: this.props.data.auto[1] });
      }
    }

    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data.auto+"" != prevProps.data.auto+"") {
      //auto has changed
      //we need to update with auto
      this.props.getDataFromComponent({ 'single_select': this.props.data.auto[1] }, this.props.data.key, this.props.data.question_text, this.props.data.data)

      this.setState({ single_select: this.props.data.auto[1] });
    }
    
    else {
      if (this.state.single_select+"" != prevState.single_select+"") {
        //state changes
        this.props.getDataFromComponent({ 'single_select': this.state.single_select }, this.props.data.key, this.props.data.question_text, this.props.data.data)

      }
    }
  }

  handleChange = (event, inputValue) => {
    this.setState({ single_select: inputValue });
  };

  doNothing = ()=>{}

  render() {
    const { classes, form, data, isAnnotationModeOn } = this.props;
    const theme = createMuiTheme({
      palette: {
        primary: { 500: "#2196F3" },
      },
      typography: { useNextVariants: true },
    });
    try {
      return (
        <div className={classes.root}>
          <MuiThemeProvider theme={theme}>
            {!isAnnotationModeOn ? <Autocomplete
              className={"autocomplete"}
              disabled={this.props.data.read_only }
              id={`single-select-${this.props.data.key}`}
              inputProps={this.props.data.style}
              menuprops={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              style={{width:"min-content !important"}}
              options={this.props.data.data}
              autoComplete={true}
              value={this.state.single_select}
              size={"small"}
              onChange={
                this.props.data.read_only
                  ? this.doNothing()
                  : (event, inputValue) => {
                      if (inputValue != null && Array.isArray(inputValue))
                        this.handleChange(event, inputValue[1]);
                    }
              }
              renderInput={(params) => {
                return (
                  <TextFieldMUI
                    {...params}
                    variant="outlined"
                    value={this.state.single_select}
                  />
                );
              }}
              getOptionLabel={(option) => {
                if (Array.isArray(option)) {
                  if (option[1] == null) return "";
                  else return option[1];
                } else return option;
              }}
              getOptionSelected={(option, value) => {
                if (option != null) {
                  if (option[1] == value) return true;
                  else return false;
                }
              }}
              variant={"outlined"}
            /> : <div id={`${data.key}-answer-text`} className={classes.annotationSingleSelect}>
              {this.state.single_select}
              </div>}
          </MuiThemeProvider>
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {
  dispatchaddPageData: (key, answer, question_text) =>
    addPageData({ key, answer, question_text }),
};

SingleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SingleSelect));
