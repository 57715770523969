import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import RequestActionTypes from './constants';
import config from '../../config';
import { httpFetch } from './utils/axiosRequest';


const { API_ADDRESS } = config;

export const pathJoin = (...args) => {
  return args
  .map((part, i) => {
    if (i === 0) {
      console.log(part);

      console.log(part.trim().replace(/[/]*$/g, ""));
      return part.trim().replace(/[/]*$/g, "");
    } else {
      console.log(part);
      console.log(part.trim().replace(/(^[/]*|[\\/]*$)/g, ""));
      return part.trim().replace(/(^[/]*|[\\/]*$)/g, "");
    }
  })
    .filter(x => x.length)
    .join('/');
};


const REQUEST_EXCEPTION = 'REQUEST_EXCEPTION';


function* handleRequests(action) {
  const { type, payload } = action;
  const { uri, successAction, failureAction, data } = payload;
  // console.log(data)
  const { isFileUpload } = data;
  // Important request should always floow AUTH_METHOD_REQUEST format
  const requestParams = type.split('_');
  const requestMethod = requestParams[1];
  let accessToken = null;
  let mobile = null;
  if (requestParams[0] === 'AUTHED') {
    accessToken = yield select(state => state.user.auth.access_token);
    mobile = yield select(state => state.user.auth.mobile);
    // todo:
    if (!(accessToken && mobile)) {
      // notify user to login
    }
  }

  yield put({ type: RequestActionTypes.REQUEST_RESTARTED });
  let formData = data.formData || {};
  let bodyData = data.bodyData || {};
  let queryParams = data.queryParams || {};
  const apiUri = pathJoin(API_ADDRESS, '', uri);

  try {
    const response = yield call(httpFetch, {
      method: requestMethod,
      uri: apiUri,
      formData,
      bodyData,
      queryParams,
      accessToken,
      mobile,
      isFileUpload
    });
    yield put({ type: successAction, payload: response, queryParams: queryParams });
  } catch (exception) {
    if (exception && exception.type === REQUEST_EXCEPTION) {
      yield put({ type: failureAction, payload: { ...exception.payload } });

    } else {
      yield put({ type: failureAction, payload: exception });
    }
  } finally {
    yield put({ type: RequestActionTypes.REQUEST_COMPLETED });
  }
}

function* watchRequests() {
  yield takeEvery(
    [
      RequestActionTypes.VERSION_GET_REQUEST,
      RequestActionTypes.AUTHED_DELETE_REQUEST,
      RequestActionTypes.UNAUTHED_GET_REQUEST,
      RequestActionTypes.UNAUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_GET_REQUEST,
      RequestActionTypes.AUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_PUT_REQUEST
    ],
    handleRequests
  );
}

const sagas = [fork(watchRequests)];

export default sagas;
