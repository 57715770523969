import requestSaga from './request/sagas';
import FormSaga from './form/sagas'


import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  try {
    yield all([ ...requestSaga,...FormSaga,]);
  } catch (err) {
    // console.log('error caught in rootsaga');
  }
}
