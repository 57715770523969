import { FormActionTypes } from './constants';

export const fetchMainPageData = ({ password, form_id, session_id, projectVersion, projectSessionId, threadId, draft }) => ({
  type: FormActionTypes.GET_MAIN_PAGE,
  payload: {
    password, form_id, session_id, projectVersion, projectSessionId, threadId, draft
  }
});

export const fetchFormComments = ({ session_id, isTest }) => ({
  type: FormActionTypes.GET_COMMENTS,
  payload: {
    session_id,
    isTest
  }
});

export const searchForm = ({ password, companyName }) => ({
  type: FormActionTypes.GET_FORM,
  payload: {
    password, companyName
  }
});

export const fetchNextPageData = ({ password, form_id, index, projectVersion, projectSessionId, threadId }) => ({
  type: FormActionTypes.GET_NEXT_PAGE,
  payload: {
    password, form_id, index, projectVersion, projectSessionId, threadId
  },
  targetIndex: index
});
export const addPageData = ({ key, answer, question_text, format }) => ({
  type: FormActionTypes.ADD_PAGE_ANSWER,
  payload: {
    key, answer, question_text, format
  }
});

export const deleteFileUpload = ({ key }) => ({
  type: FormActionTypes.DELETE_FILE_UPLOAD,
  payload: { key }
});

export const addPageComment = ({ key, format, question_text, comment, user, date }) => ({
  type: FormActionTypes.ADD_PAGE_COMMENT,
  payload: {
    key, format, question_text, comment, user, date
  }
});

export const addPageDataCalendar = ({ key, answer, question_text, format,rawValue, outputFormat, timezone }) => ({
  type: FormActionTypes.ADD_PAGE_ANSWER_CALENDAR,
  payload: {
    key, answer, question_text, format,rawValue, outputFormat, timezone,
    
  }
});

export const addPageDataTable = ({ key, answer, question_text, format, exportFormat, tableAnswerKeys, colNames }) => ({
  type: FormActionTypes.ADD_PAGE_ANSWER_TABLE,
  payload: {
    key, answer, question_text, format, exportFormat, tableAnswerKeys, colNames
  }
});

export const goBackToPrevious = () => ({
  type: FormActionTypes.GO_BACK_TO_PRE,
  payload: {

  }
});
export const goBackToANY = (index) => ({
  type: FormActionTypes.GO_BACK_TO_ANY,
  payload: {
    index
  }
});
export const unmountForm = () => ({
  type: FormActionTypes.UNMOUNT_FROM,
  payload: {

  }
});
export const passwordAlert = () => ({
  type: FormActionTypes.PASSWORD_ALERT,
  payload: {

  }
});
export const clearpasswordAlert = () => ({
  type: FormActionTypes.CLEAR_PASSWORD_ALERT,
  payload: {

  }
});
export const formLoading = () => ({
  type: FormActionTypes.FORM_LOADING,
  payload: {}
});

export const updateFormState = ({ formSession, formMeta }) => ({
  type: FormActionTypes.UPDATE_FORM_STATE,
  payload: {
    formSession, formMeta 
  }
});
