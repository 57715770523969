import { getRandomColor } from "../../ColourTextHelper";

/**
 *
 * This re calculates the start and end index based on the element
 * We do this because if we have:
 * <span>One two three four <highlight>five</highlight> six seven</span>
 * The indices for five will be 0 and 4
 * We need to calculate the index from the start of the entire span
 * So we go over each child until we hit the current div and add the previous lengths
 *
 */
const recalculateOffsetAndEle = (ele, startIdx, endIdx) => {
    let finalObj = {};
    let offset = 0;

    for (let i = 0; i < ele.childNodes.length; i++) {
        let curr = ele.childNodes[i];
        if (curr.textContent.length + offset > startIdx) {
            finalObj["currentElement"] = curr;
            break;
        }

        // Add the offset
        offset += curr.textContent.length;
    }

    finalObj["startIdx"] = startIdx - offset;
    finalObj["endIdx"] = endIdx - offset;
    return finalObj;
};
/**
 *
 * This is used for re-creating the previous text string when removing a highlight from an element
 * We do this by looking at the previous and next siblings of the highlight that we would like to delet
 *
 */
export const addPreviousText = (ele, curr, text) => {
    if (curr.previousSibling && curr.previousSibling.nodeName === "#text" && curr.nextSibling && curr.nextSibling.nodeName === "#text") {
        const nextSiblingText = curr.nextSibling.textContent;
        ele.removeChild(curr.nextSibling);
        curr.previousSibling.textContent += text + nextSiblingText;
        ele.removeChild(curr);
        return true;
    } else if (curr.previousSibling && curr.previousSibling.nodeName === "#text") {
        curr.previousSibling.textContent += text;
        ele.removeChild(curr);
        return true;
    } else if (curr.nextSibling && curr.nextSibling.nodeName === "#text") {
        curr.nextSibling.textContent = text + curr.nextSibling.textContent;
        ele.removeChild(curr);
        return true;
    }

    return false;
};

/**
 *
 * Adds a highlight to a piece of text based on the id, start index and end index
 *
 */
export const addHighlight = (a, showSidebarAndHighlight, annotationId) => {
    const { itemId, startIndex, endIndex, annotationCreator } = a;

    const highlightColour = getRandomColor(annotationCreator);

    try {
        let range = document.createRange();
        let ele = document.getElementById(itemId);

        const offset = recalculateOffsetAndEle(ele, startIndex, endIndex);

        range.setStart(offset.currentElement, offset.startIdx);
        range.setEnd(offset.currentElement, offset.endIdx);

        let span = document.createElement("span");
        span.id = annotationId;
        span.className = "highlightme-annotation";
        span.style.backgroundColor = highlightColour;
        span.style.borderRadius = "4px";

        span.onclick = () => {
            showSidebarAndHighlight(true, annotationId);
        };

        range.surroundContents(span);
        range.insertNode(span);
    } catch (e) {
        // console.log(e);
        return;
    }
};

export const removeAnnotationHighlights = async () => {
    const eles = [...document.getElementsByClassName("highlightme-annotation")];
    for (let ele of eles) {
        let parent = ele.parentElement;
        let annotationId = ele.id;
        deleteHighlight(parent, annotationId);
    }
};

// Deletes a highlight given the element and the annotation id
export const deleteHighlight = (ele, annotationId) => {
    if (!ele) return;
    for (let i = 0; i < ele.childNodes.length; i++) {
        try {
            let curr = ele.childNodes[i];

            if (curr.id !== annotationId) continue;
            const prevText = curr.firstChild.textContent;

            if (!addPreviousText(ele, curr, prevText)) {
                let textNode = document.createTextNode(prevText);
                ele.insertBefore(textNode, curr);
                ele.removeChild(curr);
            }
        } catch (e) {}

        break;
    }
};

export const getSelection = () => {
    if (window.getSelection) {
        return window.getSelection();
    }
    if (document.selection) {
        return document.selection;
    }
    return null;
};

export const deleteTempAnnotation = () => {
    try {
        let ele = document.getElementById("temp-annotation-highlight").parentElement;
        deleteHighlight(ele, "temp-annotation-highlight");
    } catch (e) {}
};

export const getOffsetCalc = (givenRange) => {
    let offset = 0;

    let currNode = givenRange.startContainer.previousSibling;

    while (currNode) {
        offset += currNode.textContent.length;
        currNode = currNode.previousSibling;
    }

    return offset;
};

function deleteChildren(e) {
    var child = e.lastElementChild;
    while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
    }
}

export const newLineToBreak = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const breakToNewLine = (str) => {
    return str.replace(/<br\s*\/?>/gm, "\n");
};
