import {
  call,
  fork,
  put,
  takeLatest,
  race,
  take,
  cancel,
  takeEvery,
} from "redux-saga/effects";
import { FormActionTypes } from "./constants";
import { getAuthed, getUnAuthed } from "../request/actions";

function* fetchNextPage(action) {
  const successAction = FormActionTypes.GET_NEXT_PAGE_SUCCESS;
  yield put({type: successAction, 
    data: {
        queryParams: {
            password: action.payload.password || "",
            form_id: action.payload.form_id,
            current_status: "published",
            index: action.payload.index,
            projectVersion: action.payload.projectVersion,
            projectSessionId: action.payload.projectSessionId,
            threadId: action.payload.threadId
            },
        },
    });
}

function* fetchForm(action) {
  const successAction = FormActionTypes.GET_NEXT_PAGE_SUCCESS;
  const failureAction = FormActionTypes.GET_NEXT_PAGE_FAILED;
  yield put(
    getUnAuthed({
      uri: "/search_form",
      successAction,
      failureAction,
      data: {
        queryParams: {
          password: action.payload.password || "",
          form_id: action.payload.form_id,
          company_name: action.payload.companyName,
        },
      },
    })
  );
  const { success, fail } = yield race({
    success: take(successAction),
    fail: take(failureAction),
  });

  if (fail) {
    alert(fail.payload.message);
    window.document.location.pathname = "/";
  }
}

function* getComments(action) {
  const successAction = FormActionTypes.GET_COMMENTS_SUCCESS;
  const failureAction = FormActionTypes.GET_COMMENTS_FAILED;
  const { session_id, isTest } = action.payload;
  yield put(
    getUnAuthed({
      uri: "/get_comments",
      successAction,
      failureAction,
      data: {
        queryParams: {
          session_id,
          isTest
        }
      }
    })
  )
  const { success, fail } = yield race({
    success: take(successAction),
    fail: take(failureAction),
  });


  // if (fail) {
  //   alert(fail.payload.message);
  // }
}

function* watchForm() {
  yield takeLatest(FormActionTypes.GET_NEXT_PAGE, fetchNextPage);
  yield takeLatest(FormActionTypes.GET_FORM, fetchForm);
  yield takeLatest(FormActionTypes.GET_COMMENTS, getComments);
}


const Formsaga = [fork(watchForm)];
export default Formsaga;
