import React from "react";
import SendIcon from "@material-ui/icons/Send";
import { TextField, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { addPageComment } from "../../store/form/actions";
import { withStyles } from "@material-ui/core";
import { Auth } from "aws-amplify";
import axios from "axios";
import config from "../../config";

const styles = (theme) => ({
  comment: {
    "&:hover": {
      color: "#2196F3 !important",
    },
  },
});

class Comment extends React.Component {
  state = {
    displayComment: false,
    username: null,
    text: ""
  };

  componentDidMount() {

    Auth.currentUserInfo().then((user) => {
      if (user) {
        if (user.attributes.identities){
          this.setState({
            username: user.attributes.email,
          })
        }else {
          this.setState({
            username: user.username,
          })
        }
      }
    })
    .catch(() => console.log("Not signed in"));
  }

  handleChangeComment = (e) => {
    this.setState({
      text: e.target.value,
    });
  };


  handlePostComment = async () => {
    const { keyComponent, username, notificationEmails } = this.props;
    let user = username

    if (this.state.text.length === 0) {
      return;
    }

    const newComment = {
      comment: this.state.text,
      user,
      date: new Date(),
    }

    const addCommentPromise = new Promise((resolve, reject) => {
      this.props.dispatchaddPageComponent(keyComponent, newComment);
      resolve();
    })
      
    addCommentPromise
      .then(async () => {
        await this.props.postComment(keyComponent, newComment, notificationEmails);
      })
      .then(() => {
        this.setState({ text: ""})
      })
    


  }

  handleDisplayComment = () => {
    const displayComment = this.state.displayComment;
    this.setState({
      displayComment: !displayComment,
    });
  };

  render() {
    let { text } = this.state;
    let { disabled, readOnly } = this.props;

    return (
      <div style={{ marginTop: 10 }}>
        <TextField
          multiline
          // rows="3"
          variant="outlined"
          style={{  width: "100%" }}
          placeholder="Type a comment"
          value={text}
          InputProps={{
            endAdornment: (
              <IconButton disabled={disabled} style={{ outline: "none", padding: "0px" }} onClick={this.handlePostComment}>
                <SendIcon />
              </IconButton>
            ),
          }}
          disabled={readOnly}
          onChange={this.handleChangeComment}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    answer_data: state.form.answer_data,
  };
};

const mapDispatchToProps = {
  dispatchaddPageComponent: (key, comment) =>
    addPageComment({ key, comment }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Comment));
