export function getRandomColor(name) {
    /**
     * This determines the background colour of the comment bubble.
     * We grab the first and last characters of the author's name.
     * We then add the first, last and first characters in ascii and use
     * them to generate the colour.
     *  */

    if (name === null || name === undefined) {
        return "rgb(20, 20, 20, 0.3)";
    }

    const asciiCode = name.charCodeAt(0);
    const secondAsciiCode = name.charCodeAt(name.length - 1);
    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + secondAsciiCode.toString() + asciiCode.toString();
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = (num >> 16) & 255;
    var g = (num >> 8) & 255;
    var b = num & 255;

    return "rgb(" + r + ", " + g + ", " + b + ", 0.15)";
}
