import { RequestActionTypes } from './constants';


export const getVersion = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.VERSION_GET_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const getAuthed = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.AUTHED_GET_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const getUnAuthed = ({ uri, successAction, failureAction, data = {} }) => {

  return createRequestAction({
    type: RequestActionTypes.UNAUTHED_GET_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const postAuthed = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.AUTHED_POST_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const putAuthed = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.AUTHED_PUT_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const postUnauthed = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.UNAUTHED_POST_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

export const deleteAuthed = ({ uri, successAction, failureAction, data = {} }) => {
  return createRequestAction({
    type: RequestActionTypes.AUTHED_DELETE_REQUEST,
    uri,
    successAction,
    failureAction,
    data
  });
};

const createRequestAction = ({ type, uri, successAction, failureAction, data = {} }) => ({
  type,
  payload: {
    uri,
    successAction,
    failureAction,
    data
  }
});
