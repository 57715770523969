import React, { Component } from "react";
import { connect } from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RawText from "../component/RawText";
import "../Form.css";
//components
import Multiplechoice from "../component/Multiplechoice";
import Freetext from "../component/Freetext";
import ListInput from "../component/ListInput";
import SingleLinetext from "../component/SingleLinetext";
import Singlechoice from "../component/Singlechoice";
import SingleSelect from "../component/SingleSelect";
import NumberInput from "../component/NumberInput";
import CustomMap from "../component/Map";
import Calendar from "../component/Calendar";
import UploadMedia from "../component/UploadMedia";
import DrawableMap from "../component/DrawableMap";
import { answerDecoder } from "../form_tools/answerEncoderDecoder";
import FadeIn from "react-fade-in";

import {
  dynamicRender,
  createDefaultCondition,
} from "../form_tools/dynamicRender";
import { parseJson } from "../form_tools/extraFunction";
import Comment from "../component/Comment";

import { addPageData } from "../../store/form/actions";

// styles
import { styles } from "../form_tools/formStyle";
var _ = require("lodash");

class FormPreviewPage extends Component {
  componentSelector = (format, data, section) => {
    switch (format) {
      case "multiple_choice":
        return data.drop_down == "true" ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              key={data.key}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{data.key}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Multiplechoice
                key={data.key}
                getDataFromComponent={this.getDataFromComponent}
                data={data}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <Multiplechoice
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "free_text":
        return (
          <Freetext
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "single_line_text":
        return (
          <SingleLinetext
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "single_choice":
        return data.drop_down == "true" ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              key={data.key}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{data.key}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Singlechoice
                key={data.key}
                getDataFromComponent={this.getDataFromComponent}
                data={data}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <Singlechoice
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "number_input":
        return (
          <NumberInput
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "custom_map":
        return (
          <CustomMap
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "calendar":
        return (
          <Calendar
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "drawable_map":
        return (
          <DrawableMap
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "row_text":
        if (section == "title") {
          return (
            <div>
              <h2>{data.data}</h2>
            </div>
          );
        } else if (section == "answer") {
          return <RawText data={data} />;
        } else if (section == "subtitle")
          return (
            <div className={"renderBreadCrumsDiv"}>
              <p className={"renderBreadCrumsPtag"}>data.data</p>
            </div>
          );
        else return <div>{data.data}</div>;
      case "single_select":
        return (
          <SingleSelect
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "upload_media":
        return (
          <UploadMedia
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      case "list_input":
        return (
          <ListInput
            key={data.key}
            getDataFromComponent={this.getDataFromComponent}
            data={data}
          />
        );
      default:
        return section == "title" ? (
          <div style={{ textAlign: "left", borderBottom: "1px solid black" }}>
            <h4>{data.data}</h4>
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <p>{data.data}</p>
          </div>
        );
    }
  };
  isContain = (data, container) => {
    function hasObject(element, target) {
      return _.isEqual(element, target);
    }
    let i = undefined;

    container.forEach((n) => {
      let a = hasObject(n, data);
      if (a == true) {
        i = n;
      }
      if (Array.isArray(n)) {
        if (data == n[0]) {
          i = n;
        }
      }
    });
    return i;
  };
  getDataFromComponent = (data, key, question_text, answer_list) => {
    data = answerDecoder(data, answer_list);
    this.props.dispatchaddPageData(key, data, question_text);
  };
  render() {
    const { form, classes, required_list } = this.props;
    const { current_index, form_length, request_data, answer_data } = form;
    console.log(answer_data);

    const page_data = request_data[current_index];
    let titleComponent = null;
    let subtitleComponent = null;
    if (page_data) {
      titleComponent = this.componentSelector(
        page_data.title.view.format,
        {
          data: dynamicRender(
            this,
            page_data.title.view.data.default,
            page_data.title.view.data.dynamic
          ),
          style: page_data.title.view.style,
        },
        "title"
      );
      subtitleComponent = this.componentSelector(
        page_data.title.view.format,
        {
          data: dynamicRender(
            this,
            page_data.title.view.data.default,
            page_data.title.view.data.dynamic
          ),
          style: page_data.title.view.style,
        },
        "subtitle"
      );
    }
    return (
      <>
        <React.Fragment>
          <div className={"divRender"}>{subtitleComponent}</div>
          <div style={{ textAlign: "left" }} id="title-area">
            <FadeIn>
              {titleComponent}
              {page_data &&
                page_data.components.map((n) => {
                  let auto;
                  let answer_list = parseJson(
                    dynamicRender(
                      this,
                      n.views.answer.view.data.default,
                      n.views.answer.view.data.dynamic
                    )
                  );
                  let question_list = parseJson(
                    dynamicRender(
                      this,
                      n.views.question.view.data.default,
                      n.views.question.view.data.dynamic
                    )
                  );

                  //this is a work around to create its default value
                  createDefaultCondition(n.visibility);
                  let visible = dynamicRender(this, "", n.visibility) + "";
                  auto = parseJson(dynamicRender(this, "", n.auto));
                  if (auto != "") {
                    if (
                      n.views.answer.view.format == "multiple_choice" ||
                      n.views.answer.view.format == "single_choice"
                    ) {
                      let answer = this.isContain(auto, answer_list);
                      if (!answer) {
                        visible = "false";
                      } else {
                        auto = answer;
                      }
                    } else if (typeof answer_list != "object") {
                      answer_list = auto;
                    }
                  }
                  let readonly = dynamicRender(this, "false", n.read_only) + "";
                  console.log({
                    n,
                    auto,
                    visible,
                    answer_list,
                    question_list,
                  });
                  return (
                    <div
                      style={{
                        display:
                          visible == "false"
                            ? "none"
                            : visible == ""
                            ? "none"
                            : "",
                      }}
                    >
                      {required_list &&
                        required_list.length > 0 &&
                        required_list.lastIndexOf(n.key) != -1 && (
                          <div
                            style={{
                              marginTop: 12,
                              height: 30,
                              backgroundColor: "rgba(176, 0, 32, 0.1)",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i
                              style={{ marginRight: 24 }}
                              className="material-icons error"
                            >
                              error
                            </i>
                            <p className={"error"}>
                              This question is required
                            </p>
                          </div>
                        )}
                      {n.views.answer.view.format != "row_text" && (
                        <div style={{ marginTop: "32px" }} id="question-area">
                          {this.componentSelector(
                            n.views.question.view.format,
                            {
                              data: question_list,
                              style: parseJson(n.views.question.style),
                            },
                            "question"
                          )}
                          <p
                            style={{
                              textAlign: "left",
                              margin: "auto",
                              color: "#868686",
                            }}
                          >
                            {dynamicRender(
                              this,
                              n.views.question.description.default,
                              n.views.question.description.dynamic
                            )}
                          </p>
                        </div>
                      )}

                      <div
                        style={{ marginTop: "20px", textAlign: "left" }}
                        id="answer-area"
                      >
                        {this.componentSelector(
                          n.views.answer.view.format,
                          {
                            data: answer_list,
                            style: parseJson(n.views.answer.style),
                            key: n.key,
                            read_only: JSON.parse(readonly),
                            list_min: n.list_min,
                            list_max: n.list_max,
                            file_type: n.file_type,
                            auto: auto,
                            question_text: question_list,
                            drop_down: n.drop_down,
                          },
                          "answer"
                        )}
                        <div>
                          {n.comment && (
                            <Comment
                              key={n.key}
                              format={n.views.answer.view.format}
                              question_text={question_list}
                              keyComponent={n.key}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            textAlign: "left",
                            margin: "auto",
                            color: "#868686",
                          }}
                        >
                          {dynamicRender(
                            this,
                            n.views.answer.description.default,
                            n.views.answer.description.dynamic
                          )}
                        </p>
                      </div>
                      <div style={{ height: "2vh" }}></div>
                      <Divider />
                    </div>
                  );
                })}
            </FadeIn>
          </div>
        </React.Fragment>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {
  dispatchaddPageData: (key, answer, question_text) =>
    addPageData({ key, answer, question_text }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormPreviewPage));
