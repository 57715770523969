import React, { createRef, Component } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Scatter, Doughnut } from "react-chartjs-2";
import AhvvaMap from "./component/AhvvaMap";
import NavigationBar from "./component/NavigationBar";
import { Button, Theme, AlertMessage } from "vardogyir-ui";
import axios from "axios";
import config from "../config";
import ReportButton from "./component/ReportButton"
import AhvvaContent from './component/AhvvaContent';

const drawerWidth = "30%";
const styles = theme => ({
  rightD: {
    position: "absolute",
    right: 0
  },
  leftD: {
    position: "absolute",
    left: 0
  },

  Button: {
    marginLeft: "10px",
    marginRight: "10px",
    color: "#2196F3",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3"
    }
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  Menu: {
    marginLeft: "10px",
    marginRight: "10px",
    color: "#2196F3",
    minWidth: "50px",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3"
    }
  },
  appBar: {
    backgroundColor: "#434343",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawer: {
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    minWidth: 400,
    position: "fixed",
    top: 120,
    bottom: 60,
    left: 60,
    margin: 33,
    maxHeight: "80vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0
  },
  toolbar: {
    minWidth: 300,
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px"
  },
  toolbarOpen: {
    // left:93,
    // width:drawerWidth,
    backgroundColor: "white",
    // position:'fixed',
    // display:'flex',
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px"
  },
  main: {
    alignItems: "center",
    padding: "0 8px"
  },
  content: {
    flexGrow: 1,

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaper: {
    minWidth: 300,
    position: "fixed",
    top: 150,
    bottom: 60,
    right: 60,
    width: drawerWidth,
    maxHeight: 600
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  table: {
    width: "100%"
  }
});

class AhvvaPage extends React.Component {
  constructor() {
    super();

    this.mapRef = createRef();

    this.state = {
      ready: false,
      appBarOpen: false,
      anchorEl: [null],
      latlng: [],
      mapWidth: "100%",
      targetFocus: 0,
      pathFocus: 0,
      carFocus: 0,
      AhvvaData: {},
      targetList: [],
      blockList: [],
      doughnut: "",
      summaryD: false,
      fullScreen: false,
      reportGenerationState: "not_generated",
      zoom: 15,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(this.mapRef.current);
  }

  async componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener(
        "fullscreenchange",
        exitHandler.bind(this),
        false
      );
      document.addEventListener(
        "mozfullscreenchange",
        exitHandler.bind(this),
        false
      );
      document.addEventListener(
        "MSFullscreenChange",
        exitHandler.bind(this),
        false
      );
      document.addEventListener(
        "webkitfullscreenchange",
        exitHandler.bind(this),
        false
      );
    }

    function exitHandler() {
      if (document.fullscreen) {
        this.setState({ fullScreen: true });
      } else {
        this.setState({ fullScreen: false });
      }
    }

    try {
      if (document.getElementById("main-header")) {
        document.getElementById("main-header").hidden = true;
      }
      const res = await axios(
        `${config.API_ADDRESS}/ahvva_result?req_id=${this.props.match.params.req_id}`,
        {
          method: "get",

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );

      let data = res.data.data.data;
      let targetList = [];
      let blockList = [];
      let AhvvaData = {};
      let anchorEl = [];
      let low = 0;
      let medium = 0;
      let high = 0;

      for (let i in data) {
        targetList.push(data[i].target);
        blockList.push(data[i].blocks);

        let AhvvaV = [];

        for (let j in data[i].vehicle_list) {
          // let carType = this._detectType(data[i].vehicle_list[j].weight,data[i].vehicle_list[j].top_speed)
          let carType = data[i].vehicle_list[j].name;
          AhvvaV.push(carType);
          anchorEl.push(null);
          for (let k in data[i].vehicle_list[j].paths_list) {
            let currentPath = data[i].vehicle_list[j].paths_list[k]
            let consequence_rating = AhvvaContent.get_consequence_rating(currentPath.momentum);
            let likelihood_rating = AhvvaContent.get_likelihood_rating(currentPath.sum_angle);
            let overall_rating = AhvvaContent.get_overall_rating(consequence_rating, likelihood_rating)
            switch (overall_rating) {
              case "Medium Risk":
                medium = medium + 1;
                break;
              case "High Risk":
                high = high + 1;
                break;
              case "Low Risk":
                low = low + 1;
                break;
            }
          }
        }
        AhvvaData[i] = { data: data[i].vehicle_list, carList: AhvvaV };
      }
      console.log({ low, medium, high, AhvvaData }, targetList[0]);

      this.setState({
        AhvvaData: AhvvaData,
        targetList: targetList,
        blockList: blockList,
        latlng: targetList[0],
        ready: true,
        doughnut: {
          labels: ["Low Risk", "Medium Risk", "High Risk"],
          datasets: [
            {
              label: "Summary",
              data: [low, medium, high],
              backgroundColor: ["#c1f8d8", "#fff6ce", "#e24100"],
              hoverBackgroundColor: ["#109748", "#fed009", "#ff0000"]
            }
          ]
        }
      });

      // console.log({AhvvaData,targetList})
    } catch (e) {
      console.log(e);
    }
  }

  changeTarget = i => {
    // alert(i)
    console.log(i, "change target");
    this.setState({ targetFocus: parseInt(i), pathFocus: 0, carFocus: 0 });
  };
  menuOpen = (i, event) => {
    let current = this.state.anchorEl;
    current[i] = event.currentTarget;
    this.setState({ anchorEl: current });
  };
  changePath = (targetIndex, carIndex, pathIndex, event) => {
    this.setState({
      targetFocus: targetIndex,
      pathFocus: pathIndex,
      carFocus: carIndex
    });
  };
  menuClose = (i, event) => {
    let current = this.state.anchorEl;
    current[i] = null;
    this.setState({ anchorEl: current });
  };
  handleFullScreen = () => {
    var elem = document.getElementById("AhvvaPage-map");

    /* When the openFullscreen() function is executed, open the video in fullscreen.
        Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet */

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  handleSatelite = () => {
    this.setState({
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    });
  };
  handleMap = () => {
    this.setState({
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    });
  };
  handleDarkMap = () => {
    this.setState({
      url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    });
  };

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }


  openSidePage = async () => {
    await this.setStateAsync({
      appBarOpen: true,
      latlng: this.state.targetList[this.state.targetFocus]
    });

  };
  closeSidePage = async () => {
    await this.setStateAsync({ appBarOpen: false });
  };

  openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup();
      });
    }
  }

  handleNext = () => {
    const limit =
      this.state.AhvvaData[this.state.targetFocus].data[this.state.carFocus]
        .paths_list.length - 1;
    if (this.state.pathFocus < limit) {
      this.setState({ pathFocus: this.state.pathFocus + 1 });
    }
  };
  generateScatter = (xList, yList) => {
    let result = [];
    for (let i = 0; i < xList.length; i++) {
      result.push({
        x: xList[i],
        y: yList[i]
      });
    }
    return result;
  };
  handlePrint = () => {
    window.open(`/AhvvaResult/${this.props.match.params.req_id}`);
  };
  renderText = () => {
    try {
      if (this.state.ready) {
        const { classes } = this.props;

        const data = this.state.AhvvaData[this.state.targetFocus].data[
          this.state.carFocus
        ].paths_list[this.state.pathFocus];
        let dataLine1 = {
          labels: "Scatter Dataset",
          datasets: [
            {
              label: "Distance / Speed",
              yAxisID: "V",
              fill: false,
              lineTension: 0.1,
              backgroundColor: "#199ec2",
              borderColor: "#199ec2",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#199ec2",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              borderWidth: 3,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#199ec2",
              pointHoverBorderColor: "#199ec2",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              pointStyle: "circle",
              data: this.generateScatter(data.distlist, data.speedlist)
            }
          ]
        };
        let dataLine2 = {
          labels: "Scatter Dataset",
          datasets: [
            {
              label: "Kinetic Energy",
              yAxisID: "E",
              fill: false,
              lineTension: 0.1,
              backgroundColor: "#ec4100",
              borderColor: "#ec4100",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#ec4100",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              borderWidth: 3,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#ec4100",
              pointHoverBorderColor: "#ec4100",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              pointStyle: "circle",
              data: this.generateScatter(data.distlist, data.kinetic_energylist)
            },
            
          ]
        };
        let dataLine3 = {};
        if (data.elevationlist != null || data.elevationlist != undefined){
          dataLine3 = {
            labels: "Scatter Dataset",
            datasets: [
              {
                label: "Elevation (m)",
                yAxisID: "E",
                fill: false,
                lineTension: 0.1,
                backgroundColor: "#32cd32",
                borderColor: "#32cd32",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#32cd32",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                borderWidth: 3,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#32cd32",
                pointHoverBorderColor: "#32cd32",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                pointStyle: "circle",
                data: this.generateScatter(data.distlist, data.elevationlist)
              },
              
            ]
          };
        }
        console.log(data)
        
        let consequence_rating = AhvvaContent.get_consequence_rating(data.momentum);
        let consequence_text = AhvvaContent.get_consequence_text(consequence_rating);
        let likelihood_rating = AhvvaContent.get_likelihood_rating(data.sum_angle);
        let likelihood_text = AhvvaContent.get_likelihood_text(likelihood_rating);
        let overall_rating = AhvvaContent.get_overall_rating(consequence_rating, likelihood_rating)
        let overall_text = AhvvaContent.get_overall_text(overall_rating);
        let barrier_text = AhvvaContent.get_barrier_text(data.sum_angle, data.velocity)
        let recommendation_text = AhvvaContent.get_recommendation_text(data.pair_id, data.name, data.velocity, data.momentum, data.kinetic_energy, barrier_text)
        
        
        return (

          <div style={{ margin: "10px", padding: "10px", overflowX: "auto" }}>

            <div style={{ width: "100%" }}>
              <div>
                <h4
                  style={{ textAlign: "left" }}
                >{`Path ${data.pair_id} Analysis (${data.path_num})`}</h4>
                <p
                  style={{ textAlign: "left" }}
                >{`Velocity at impact: ${data.velocity} Kilometers per Hour`}</p>
                <p
                  style={{ textAlign: "left" }}
                >{`Momentum at impact: ${data.momentum} Newton seconds`}</p>
                <p style={{ textAlign: "left" }}>{`Time from start to impact: ${
                  data.time_total
                    ? data.time_total + " seconds"
                    : "No data available"
                }`}</p>
              </div>
              <br />
              <div>
                <h4 style={{ textAlign: "left" }}>Consequence</h4>
                <p style={{ textAlign: "left" }}>{consequence_text}</p>
              </div>
              <br />

              <div>
                <h4 style={{ textAlign: "left" }}>Likelihood</h4>
                <p style={{ textAlign: "left" }}>{likelihood_text}</p>
              </div>
              <br />

              <div>
                <h4 style={{ textAlign: "left" }}>Overall risk</h4>
                <p style={{ textAlign: "left" }}>{overall_text}</p>
              </div>
              <br />

              <div>
                <h4 style={{ textAlign: "left" }}>Recommendation</h4>
                <p style={{ textAlign: "left" }}>{recommendation_text}</p>
              </div>
            </div>

            <div>
              <Scatter // Chart/Graph 1
                data={dataLine1}
                options={{
                  responsive: true,
                  showLines: true,
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Distance (M)"
                        },
                        min: 0,
                        type: "linear",
                        position: "bottom"
                      }
                    ],

                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Velocity (km/h)"
                        },
                        position: "left",
                        id: "V",
                        ticks: {
                          min: 0,
                          stepSize: data.speed_step * 2
                        }
                      }
                    ]
                  }
                }}
              />
              <Scatter // Chart/Graph 2
                data={dataLine2}
                options={{
                  responsive: true,
                  showLines: true,
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Distance (M)"
                        },
                        min: 0,
                        type: "linear",
                        position: "bottom"
                      }
                    ],

                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Kinetic Energy (Joules)"
                        },
                        position: "left",
                        id: "E",
                        ticks: {
                          min: 0,
                          stepSize: data.kinetic_energy_step * 2
                        }
                      }
                    ]
                  }
                }}
              />
              {data.elevationlist != null || data.elevationlist != undefined ? 
              <Scatter // Chart/Graph 3
                data={dataLine3}
                options={{
                  responsive: true,
                  showLines: true,
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Distance (m)"
                        },
                        min: 0,
                        type: "linear",
                        position: "bottom"
                      }
                    ],

                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "Elevation (m)"
                        },
                        position: "left",
                        id: "E",
                        ticks: {
                          min: 0,
                          // stepSize: data.kinetic_energy_step * 2
                        }
                      }
                    ]
                  }
                }}
      /> : <div></div> }
              <br />
              <br />
              <div style={{ width: "100%" }}>
                {" "}
                {/* Table for analysis view */}
                <Table
                  size={"small"}
                  padding={"none"}
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Class</TableCell>
                      <TableCell align="right">Weight(kg)</TableCell>
                      <TableCell align="right">Maximum speed(km/h)</TableCell>
                      <TableCell align="right">Acceleration(m/s2)</TableCell>

                      <TableCell align="right">Friction coefficient</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={
                        this.state.AhvvaData[this.state.targetFocus].carList[
                          this.state.carFocus
                        ]
                      }
                    >
                      <TableCell component="th" scope="row">
                        {
                          this.state.AhvvaData[this.state.targetFocus].carList[
                            this.state.carFocus
                          ]
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          this.state.AhvvaData[this.state.targetFocus].data[
                            this.state.carFocus
                          ].weight
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          this.state.AhvvaData[this.state.targetFocus].data[
                            this.state.carFocus
                          ].top_speed
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          this.state.AhvvaData[this.state.targetFocus].data[
                            this.state.carFocus
                          ].acceleration
                        }
                      </TableCell>

                      <TableCell align="right">
                        {
                          this.state.AhvvaData[this.state.targetFocus].data[
                            this.state.carFocus
                          ].coeff_fric
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        );
      } else {
        return <h3>Loading</h3>;
      }
    } catch (e) {
      console.log(e);
      return <p>Loading</p>;
    }
  };

  render() {
    const { classes } = this.props;
    try {
      return (
        <div style={{ display: "flex" }}>
          <CssBaseline />
          <AppBar className={classes.appBar}>
            <Toolbar>
              {" "}
              {/* Header - Ahvva Titlte */}
              <h3 style={{ marginLeft: 60 }}>Ahvva</h3>
              <div>{/*{this.renderHeader()}*/}</div>
              <div style={{ flexGrow: 1 }} />
            <ReportButton req_id={this.props.match.params.req_id}/>
            </Toolbar>

            <NavigationBar
              AhvvaData={this.state.AhvvaData}
              handlePrint={this.handlePrint}
              changePath={(target, vehicle, path) => {
                this.changePath(target, vehicle, path);
              }}
              summaryD={() => this.setState({ summaryD: true })}
              handleSatelite={this.handleSatelite}
              handleMap={this.handleMap}
              handleDarkMap={this.handleDarkMap}
            />
          </AppBar>

          <main className={classes.content}>
            <div className={classes.drawerHeader} />
            <div style={{ margin: "0px" }}>
              <div>
                <div id={"AhvvaPage-map"} style={{ width: "100%" }}>
                  <Drawer // Analysis Slide in drawer
                    variant="permanent"
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: this.state.appBarOpen,
                        [classes.drawerClose]: !this.state.appBarOpen
                      })
                    }}
                    open={this.state.appBarOpen}
                  >
                    <Toolbar
                      className={clsx(classes.toolbar, {
                        [classes.toolbarOpen]: this.state.appBarOpen
                      })}
                    >
                      <div style={{ margin: "0px 12px" }}>
                        <MuiThemeProvider theme={Theme}>
                          <Button onClick={this.closeSidePage} theme={"T_blue"}>
                            Close
                          </Button>
                        </MuiThemeProvider>
                      </div>
                      <div style={{ margin: "0px 12px" }}>
                        <MuiThemeProvider theme={Theme}>
                          <Button
                            disabled={this.state.pathFocus == 0}
                            onClick={() => {
                              this.state.pathFocus > 0 &&
                                this.setState({
                                  pathFocus: this.state.pathFocus - 1
                                });
                            }}
                            theme={"S_blue"}
                          >
                            Previous
                          </Button>
                        </MuiThemeProvider>
                      </div>
                      <div style={{ margin: "0px 12px" }}>
                        <MuiThemeProvider theme={Theme}>
                          <Button
                            disabled={
                              this.state.pathFocus >=
                              this.state.AhvvaData[this.state.targetFocus].data[
                                this.state.carFocus
                              ].paths_list.length -
                                1
                            }
                            onClick={this.handleNext}
                            theme={"P_blue"}
                          >
                            Next
                          </Button>
                        </MuiThemeProvider>
                      </div>
                    </Toolbar>

                    {this.renderText()}
                  </Drawer>

                  <Drawer // Summary Slide in drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={this.state.summaryD}
                    classes={{
                      paper: classes.drawerPaper
                    }}
                  >
                    <div style={{ height: 100 }} />
                    <h3>Summary</h3>
                    <br />
                    <Doughnut
                      data={this.state.doughnut}
                      options={{ responsive: true }}
                    />
                    <Toolbar
                      style={{ width: "100%" }}
                      className={classes.toolbarOpen}
                    >
                      <div style={{ flexGrow: 1 }} />
                      <div>
                        <MuiThemeProvider theme={Theme}>
                          <Button
                            theme={"P_blue"}
                            onClick={() => {
                              this.setState({ summaryD: false });
                            }}
                          >
                            OK
                          </Button>
                        </MuiThemeProvider>
                      </div>
                    </Toolbar>
                  </Drawer>
                  <br />
                  <br />
                  <AhvvaMap
                    state={this.state}
                    handleSatelite={this.handleSatelite}
                    handleMap={this.handleMap}
                    handleDarkMap={this.handleDarkMap}
                    openSidePage={this.openSidePage}
                    summaryD={() => {
                      this.setState({ summaryD: true });
                    }}
                    changeTarget={i => {
                      console.log(i, "clicked on target");
                      this.setState({
                        targetFocus: parseInt(i),
                        pathFocus: 0,
                        carFocus: 0
                      });
                    }}
                    handleFullScreen={this.handleFullScreen}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      );
    } catch (e) {
      return <p>Loading...</p>;
    }
  }
}

export default withStyles(styles)(AhvvaPage);
