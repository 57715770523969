import React from "react";

// mui
import { styled } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const Banner = styled(Grid)(({ borderColor, bgColor }) => ({
    border: `1px solid ${borderColor}`,
    backgroundColor: bgColor,
    padding: "4px 8px",
    borderRadius: "4px",
    columnGap: "12px",
    width: "fit-content",
}));

const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    alignItems: vertical,
    justifyContent: horizontal,
}));

function IconBanner({ icon, content, borderColor, bgColor }) {
    return (
        <Banner container borderColor={borderColor} bgColor={bgColor}>
            {icon && (
                <CustomGrid item vertical="center" horizontal="center">
                    {icon}
                </CustomGrid>
            )}
            <CustomGrid item xs vertical="center" horizontal="flex-start">
                {content}
            </CustomGrid>
        </Banner>
    );
}

export default IconBanner;
