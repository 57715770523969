// Entry point for parsing the table data into the
// correct answer format
export const convertTableDataToCorrectFormat = (
  exportType,
  tableAnswers,
  columns,
  rowLabels
) => {
  switch (exportType) {
    case "columnsAsLists":
      return getColumnAsListFormat(tableAnswers, columns);
    case "rowsAsLists":
      return getRowAsListFormat(tableAnswers, rowLabels);
    default:
      break;
  }
};

// For saving the data as "columnsAsLists"
const getColumnAsListFormat = (tableAnswers, columns) => {
  let formattedAnswer = {};

  let transposeTable = transpose(tableAnswers);

  // Loop over the column Names
  for (let i = 0; i < columns.length; i++) {
    let currCol = columns[i];

    const keyName = convertStringToKey(currCol.columnName);
    formattedAnswer[keyName] = ["list", ...transposeTable[i]];
  }

  return formattedAnswer;
};

// For saving the data as "rowsAsLists"
const getRowAsListFormat = (tableAnswers, rowLabels) => {
  let formattedAnswer = {};

  for (let i = 0; i < tableAnswers.length; i++) {
    const keyName = getRowKeyNameFromLabels(i, rowLabels);
    formattedAnswer[keyName] = ["list", ...tableAnswers[i]];
  }

  return formattedAnswer;
};

// Get the row key name from row labels
// If row label is not empty, return that
// Else return the rowKey
const getRowKeyNameFromLabels = (idx, rowLabels) => {
  try {
    let currRowLabel = rowLabels[idx];
    if (currRowLabel.rowLabel != "") {
      // We have set a row label
      return convertStringToKey(currRowLabel.rowLabel);
    } else {
      // Use default row_key
      return currRowLabel.rowKey;
    }
  } catch (e) {
    return "row" + idx.toString(); // Default to here
  }
};

export const transpose = (matrix) => {
  return matrix[0].map((col, i) => matrix.map((row) => row[i]));
};

// Converts a given string to a key
// i.e: "Column Name" ==> "column_name"
export const convertStringToKey = (name) => {
  return name
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
};

// For parsing through fetch table answers from DB
export const parseAnswerData = (
  answerData,
  columns,
  extractFormat,
  rowLabels,
  shown,
) => {
  switch (extractFormat) {
    case "columnsAsLists":
      return parseColumnData(answerData, columns);
    case "rowsAsLists":
      return parseRowData(answerData, rowLabels, shown);
  }
};

// Used for converting the legacy data that was storing the
// Values as strings
//  To storing it with the defaultVal & calculatedVal Object
const handleLegacyAndNewAnswers = (data) => {
  return data.filter((ans) => ans != "list").map((ans) => {
    // Either a date time object
    // Or a new answer type object
    if (ans instanceof Object) {
        // If value is null, it is the new answer
        if (ans.value == null) return ans;
        
        // If the value is an object
        // It is the new datetime object
        if (ans.value instanceof Object) return ans;
        else {
          // Else it is the old datetime objcet
          ans.value = {
              defaultValue: ans.value,
              calculatedValue: ans.value
          }
          return ans;            
        }
    } else return {
            defaultValue: ans,
            calculatedValue: ans,
        }
})
}

// Used for converting data saved as "columnsAsLists" from DB
const parseColumnData = (answerData, columns) => {
  let table = [];

  for (let col of columns) {
    let key = convertStringToKey(col.columnName);

    // Handle legacy and new answer format
    let colData = handleLegacyAndNewAnswers(answerData.answer[key]);
    table.push(colData);
  }

  return transpose(table);
};

// Used for converting data saved as "rowsAsLists" from DB
export const parseRowData = (answerData, rowLabels, shown) => {
  let table = [];

  for (let i = 0; i < rowLabels.length; i++) {
    if (shown && i >= shown) break;

    let currRowLabel = rowLabels[i];

    let key = currRowLabel.rowKey;

    if (currRowLabel.rowLabel != "") key = convertStringToKey(currRowLabel.rowLabel);
  
    let rowData = handleLegacyAndNewAnswers(answerData.answer[key]);
    table.push(rowData);
  }

  return table;
};

// Get the table answer keys
export const getTableAnswerKeys = (data) => {
  let cols = data.data;
  let rowLabels = data.rowLabels;
  let exportType = data.exportType;

  if (exportType === "columnsAsLists") {
    return cols.map((col) => {
      return convertStringToKey(col.columnName);
    });
  } else if (exportType === "rowsAsLists") {
    return rowLabels.map((rowLabel) => {
      if (rowLabel.rowLabel != "") {
        return convertStringToKey(rowLabel.rowLabel);
      } else return rowLabel.rowKey;
    });
  }

  return null;
};

// Check to see if a cell is read only
// From the tableCells
// Returns FALSE if table cells do not exist
export const isCellReadOnly = (tableCells, colId, rowIndex) => {
  // Check if exists
  if (tableCells == null) return false;
  if (tableCells[colId] == null) return false;
  if (tableCells[colId][rowIndex] == null) return false;
  if (tableCells[colId][rowIndex].isReadOnly == null) return false;
  return tableCells[colId][rowIndex].isReadOnly;
};

export const isCellDisabled = (tableCells, colId, rowIndex) => {
  // Check if exists
  if (tableCells == null) return false;
  if (tableCells[colId] == null) return false;
  if (tableCells[colId][rowIndex] == null) return false;
  if (tableCells[colId][rowIndex].isDisabled == null) return false;
  return tableCells[colId][rowIndex].isDisabled; // Else return field
};
