import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button } from "vardogyir-ui";
import Typography from "@material-ui/core/Typography";
import config from "../../config";
//components
import FormPreviewPage from "./FormPreviewPage";
import {
  fetchMainPageData,
  fetchNextPageData,
  goBackToPrevious,
} from "../../store/form/actions";
import { fetchDataPreview } from "./ServicePreview";
import PreviewBreadCrums from "./PreviewBreadCrums";
import { dynamicRender } from "../form_tools/dynamicRender";
import { styles } from "../form_tools/formStyle";
import { generateSummary } from "../form_tools/generateSummary";
import FadeIn from "react-fade-in";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserAccount from "../component/UserAccount";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import axios from "axios";
class FormPreview extends Component {
  state = {
    req_id: "",
    summary: "",
    answer: {},
    form_id: "",
    password: "",
    form_title: "",
    form_logo: "",
    form_oauth2: "",
    required_list: [],
    isSingleUse: false,
    branding:true
  };

  async componentDidMount() {
    
    const { form_id, session_id, projectVersion } = this.props.match.params;
    this.setState({ form_id });
    const json = await fetchDataPreview(form_id, projectVersion);
    if (json.status === 200) {
      let { data } = json;
      this.setState({
        form_logo: data.form_logo,
        form_title: data.form_title,
        form_oauth2: data.form_oauth2,
        isSingleUse: data.isSingleUse,
      });
      this.props.dispatchfetchMainPage("", form_id, session_id, projectVersion);
    }
  }

  checkAllAnswered = () => {
    const { form } = this.props;
    const { current_index, answer_data, request_data } = form;
    const answer = answer_data;
    let page_data = request_data[current_index].components;
    let required_list = [];
    for (let i in page_data) {
      let dynamicRequired = dynamicRender(this, false, page_data[i].required);
      if (
        dynamicRequired &&
        page_data[i].required != "false" &&
        page_data[i].key != "main_page"
      ) {
        if (
          !answer[page_data[i].key] ||
          answer[page_data[i].key].answer.length <= 0 ||
          (answer[page_data[i].key].answer.length > 0 &&
            !(
              answer[page_data[i].key].answer[0] != null &&
              answer[page_data[i].key].answer[0].toString()
            ))
        ) {
          required_list.push(page_data[i].key);
        }
      }
    }
    this.setState({ required_list: required_list });
    if (required_list.length > 0) {
      return false;
    }
    return true;
  };

  handleBack = () => {
    this.props.dispatchgoBackToPrevious();
    document.documentElement.scrollTop = 0;
  };

  handleNext = () => {
    let { form } = this.props;
    let { current_index } = form;
    let { form_id, password } = this.state;
    let { projectVersion } = this.props.match.params;
    if (this.checkAllAnswered()) {
      this.props.dispatchfetchNextPage(
        password,
        form_id,
        (current_index + 1).toString(),
        projectVersion
      );
      document.documentElement.scrollTop = 0;
    }
  };

  render() {
    const { classes, form } = this.props;
    const { form_logo, form_title, required_list } = this.state;
    const { current_index, form_length, request_data } = form;
    const progress = (((current_index + 1) / form_length) * 100).toFixed(0);
    return (
      <div className="form-preview">
        <main
          className={classes.layoutPreview}
          style={{ background: "#FAFAFA" }}
        >
          <div className={classes.paperPreview} style={{ marginTop: "8px" }}>
            <div className={classes.contentPreview}>
              <Paper
                className={classes.paper}
                style={{
                  minHeight: "unset",
                  padding: 32,
                }}
              >
                <nav
                  id="main-header"
                  style={{ backgroundColor: form.backgroundColor || "white" }}
                  className="navbar navbar-expand-lg navbar-light text-right p-0"
                >
                  <div
                    className="container m-0 p-0"
                    style={{ maxWidth: 1500, textAlign: "right" }}
                  >
                    <div
                      className="col-14 col-sm-12 col-md-12 col-lg-10 col-xl-10" style={{padding:0}}
                    >
                      <ProgressBar className={classes.progressbar} striped>
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{
                            width: `${(
                              ((parseInt(form.current_index) + 1) /
                                form.form_length) *
                              100
                            ).toFixed(0)}%`,
                            backgroundColor: "#484848",
                          }}
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >{`${(
                          ((parseInt(form.current_index) + 1) /
                            form.form_length) *
                          100
                        ).toFixed(0)}%`}</div>
                      </ProgressBar>
                    </div>
                    <div className="col-2 col-sm-1 col-md-1 col-lg-2 col-xl-2 menu-btn">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div
                        className="collapse navbar-collapse save-btn"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav ml-auto">
                          <li>
                            {!this.state.sended && (
                              <Button theme={"S_blue"} disabled={true}>
                                Save
                                {this.state.pageProcessing && (
                                  <CircularProgress
                                    className="buttonProgress"
                                    size={20}
                                  />
                                )}
                              </Button>
                            )}
                          </li>
                        </ul>
                        <UserAccount/>
                      </div>
                    </div>
                  </div>
                </nav>
                {this.state.form_title && (
                  <div>
                    <h3 className="page-title">
                      {this.state.form_title + " (Preview)"}{" "}
                    </h3>
                  </div>
                )}

                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <FadeIn>
                    <FormPreviewPage required_list={required_list} />
                  </FadeIn>
                  
                  <div>
                    {current_index >= form_length - 1 && (
                      <div style={{ marginTop: 24 }}>
                        <Typography className={classes.instructions}>
                          Click submit to complete
                        </Typography>
                        <Button disabled theme={"P_blue"} variant="contained">
                          {" "}
                          Submit{" "}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    <div style={{ height: "50px", marginTop: 24 }}>
                      <div style={{ float: "left" }}>
                        <Button
                          theme={"S_blue"}
                          disabled={current_index === 0}
                          onClick={this.handleBack}
                          color="secondary"
                          variant="contained"
                        >
                          {" "}
                          Back{" "}
                        </Button>
                      </div>
                      <div style={{ float: "right" }}>
                        {current_index < form_length - 1 && (
                          <Button
                            theme={"P_blue"}
                            disabled={
                              form.current_index >= form.form_length - 1
                            }
                            onClick={this.handleNext}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <p>Form Version {this.state.projectVersion}</p>

            {this.state.branding && <p>
              Powered{" "}
              <FlashOnIcon style={{ fontSize: "15px", color: "#ffee00" }} /> by
              <a
                target="_blank"
                style={{ marginLeft: "4px" }}
                href={"https://www.workflow86.com"}
              >
                Workflow86
              </a>
            </p>}
          </div>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              zIndex: 1000000,
            }}
            className={classes.footerPreview}
          >
            <p style={{ margin: 0, fontSize: "18px" }}>
              This is a form preview and cannot be submitted
            </p>
          </div>
        </main>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {
  dispatchfetchMainPage: (password, form_id, session_id, projectVersion) =>
    fetchMainPageData({ password, form_id, session_id, projectVersion }),
  dispatchfetchNextPage: (password, form_id, index, projectVersion) =>
    fetchNextPageData({ password, form_id, index, projectVersion }),
  dispatchgoBackToPrevious: () => goBackToPrevious(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormPreview));
