import React, { useState, useEffect } from "react";

// MUI Components
import { Grid } from "@material-ui/core";

// Utils
import ReplyBox from "./Boxes/ReplyBox";
import { send_request_graphql } from "./Request";
import CommentBubble from "./Boxes/CommentBubble";
import { getRandomColor } from "../ColourTextHelper";
import { CREATE_COMMENT, GET_ANNOTATION_COMMENTS } from "./Util/GraphQLQueries";
import { Text, TextBox, ThreadBoxSkeleton } from "./Util/AnnotationStyledComponents";
import { breakToNewLine, newLineToBreak } from "./Util/AnnotationHelpers";

function ThreadBoxContent({ thread, timezone, username, threads, setThreads, updateState, annotationCount, formId }) {
    const [comments, setComments] = useState(null);

    const [loading, setLoading] = useState(false);
    const [replyLoading, setReplyLoading] = useState(false);
    const [isEditingComment, setIsEditingComment] = useState(false);

    useEffect(() => {
        setLoading(true);

        send_request_graphql(formId, GET_ANNOTATION_COMMENTS(thread.annotationId))
            .then((res) => {
                setComments(res.data.getCommentsByAnnotationId);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, []);

    const handleOpenCloseThread = (openOrClose, annotationId) => {
        setReplyLoading(true);

        send_request_graphql(formId, CREATE_COMMENT(annotationId, username, "", "SYSTEM", openOrClose))
            .then((res) => {
                if (!res.data.createComment) throw "Error handling annotation";
                let annotationIndex = threads.findIndex((t) => t.annotationId === thread.annotationId);

                if (!threads[annotationIndex]) throw "Error handling annotation"; // Not found

                let updatedThread = {
                    ...threads[annotationIndex],
                    isOpen: openOrClose,
                };

                setThreads([...threads.slice(0, annotationIndex), updatedThread, ...threads.slice(annotationIndex + 1)]);
                setComments([...comments, res.data.createComment]); // Append comment
                setReplyLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setReplyLoading(false);
            });
    };

    const handlePostReply = (replyText, annotationId) => {
        if (!replyText) return; // No comment
        setReplyLoading(true);

        replyText = newLineToBreak(replyText);

        send_request_graphql(formId, CREATE_COMMENT(annotationId, username, replyText, "USER_TEXT", true))
            .then((res) => {
                if (!res.data.createComment) throw "Error creating comment";
                setComments([...comments, res.data.createComment]); // Append comment
                setReplyLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setReplyLoading(false);
            });
    };

    const getLoader = (key) => {
        return (
            <Grid container key={key}>
                <ThreadBoxSkeleton variant="rect" />
            </Grid>
        );
    };

    const getThreadBoxContent = () => {
        return (
            <Grid container>
                <TextBox>
                    <Text highlightColor={getRandomColor(thread.annotationCreator)}>{breakToNewLine(thread.highlightedText)}</Text>
                </TextBox>
                {!loading && comments
                    ? comments.map((comment, idx) => {
                          return (
                              <CommentBubble
                                  comment={comment}
                                  thread={thread}
                                  isEditingComment={isEditingComment}
                                  setIsEditingComment={setIsEditingComment}
                                  replyLoading={replyLoading}
                                  setReplyLoading={setReplyLoading}
                                  comments={comments}
                                  setComments={setComments}
                                  timezone={timezone}
                                  username={username}
                                  getLoader={getLoader}
                                  idx={idx}
                                  formId={formId}
                              />
                          );
                      })
                    : [1, 2, 3].map((idx) => getLoader(idx))}
                <Grid item xs={12}>
                    {replyLoading
                        ? getLoader("reply-box-loading-skeleton")
                        : !isEditingComment && (
                              <ReplyBox
                                  disabled={false}
                                  handlePostReply={handlePostReply}
                                  thread={thread}
                                  threads={threads}
                                  setThreads={setThreads}
                                  handleOpenCloseThread={handleOpenCloseThread}
                                  username={username}
                                  getLoader={getLoader}
                                  updateState={updateState}
                                  annotationCount={annotationCount}
                                  formId={formId}
                              />
                          )}
                </Grid>
            </Grid>
        );
    };

    return thread && getThreadBoxContent();
}

export default ThreadBoxContent;
