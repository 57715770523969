import React, {Component} from 'react';
import config from "../config";
const fileService = config.FILE_ADDRESS

class CoverImage extends Component {

  componentDidMount() {
    const canvas = this.refs.coverImage
    const id = this.props.coverImage.uploaded.id
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = `${fileService}/${id}`
    image.className = 'ReactCrop__image'
    image.alt = ''
    const img = image
    img.onload = () => {
      ctx.drawImage(img, -this.props.coverImage.crop.naturalX, -this.props.coverImage.crop.naturalY)
    }
  }

  render() {
    return (
        <canvas ref="coverImage" width={this.props.coverImage.crop.naturalWidth} height={this.props.coverImage.crop.naturalHeight} style={{width: "100%",height: "auto",objectFit: "cover"}} />
    );
  }
}

export default CoverImage;