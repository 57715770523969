import React from "react";
import { Grid, Slide, styled } from "@material-ui/core";
import { BorderColor, CheckCircle } from "@material-ui/icons";
import { Refresh } from "@material-ui/icons";
import RestoreIcon from "@material-ui/icons/Restore";

export const FormItem = styled("div")(({ isActive, isPageTitle, notInteractive, color }) => ({
    background: !isPageTitle && "rgba(248, 248, 248, 1)",
    border: !isPageTitle && "1px solid rgba(222, 222, 222, 1)",
    color: color,
    fontWeight: isActive ? "bold" : "normal",
    padding: !isPageTitle ? "8px" : "2px 8px",
    fontSize: !isPageTitle ? "14px" : "13px",
    borderRadius: "6px",
    marginLeft: isPageTitle ? "16px" : "0px",
    boxShadow: isActive && !isPageTitle ? "4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&:hover": {
        ...(!notInteractive && { cursor: "pointer", filter: !isActive && "brightness(98%)", color: !isActive && "rgba(0, 0, 0, 0.7)" }),
    },
}));

export const CustomGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "hidden",
});

export const SpanItem = styled("span")(({ keepSingleLine }) => ({
    ...(keepSingleLine && { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }),
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// Icons
export const CompletedIcon = styled(CheckCircle)({
    color: "rgba(85, 167, 122, 1)",
    marginRight: "8px",
});

export const EditingIcon = styled(BorderColor)(({ isActive }) => ({
    color: isActive ? "rgba(33, 150, 243, 1)" : "rgba(111, 111, 111, 1)",
    marginRight: "8px",
}));

export const RefreshIcon = styled(Refresh)(({ isActive }) => ({
    color: isActive ? "rgba(33, 150, 243, 1)" : "rgba(111, 111, 111, 1)",
    marginRight: "8px",
}));

export const BackIcon = styled(RestoreIcon)(() => ({
    color: "rgba(225, 0, 0, 1)",
    marginRight: "8px",
}));

export const ResponseBox = styled(Grid)(({ boxShadow, borderColor, bgColor }) => ({
    border: `1px solid ${borderColor}`,
    background: bgColor,
    padding: "8px",
    borderRadius: "4px",
    boxShadow: boxShadow ? "4px 4px 0px 0px rgba(0, 0, 0, 0.25)" : "none",
}));

export const Text = styled("span")(({ bold }) => ({
    fontSize: "14px",
    fontWeight: bold ? "bold" : "normal",
}));

export const getResponseSubmitted = (boxShadow) => {
    return (
        <Grid container>
            <ResponseBox item xs={12} boxShadow={boxShadow} borderColor="rgba(85, 167, 122, 1)" bgColor="rgba(85, 167, 122, 0.2)">
                <Grid container spacing={1} style={{ textAlign: "left"}}>
                    <Grid item xs={12}>
                        <Text bold>✅ Response submitted</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text> Your form session has been successfully submitted.</Text>
                    </Grid>
                </Grid>
            </ResponseBox>
        </Grid>
    );
};
