import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { DropZone as Drop } from "vardogyir-ui";
import { addPageData, deleteFileUpload } from "../../store/form/actions";
import Chip from "@material-ui/core/Chip";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import config from "../../config";

const styles = (theme) => ({
  checkbox: {
    color: "#afafaf",
    "&$checked": {
      color: "#2196F3",
    },
  },
  listtext: {},
  checked: {},
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: "#59B0F6",
    margin: "4px",
    height: '150px', minWidth: '200px'
    , '& .MuiChip-deleteIcon': {
      margin: "8px" ,
      position: "absolute",
      right: "0px",
      top: "1px"
},},

  chipNotImage: {
    backgroundColor: "#59B0F6",
    margin: "4px",
    height: '90px', minWidth: '130px'
    , '& .MuiChip-deleteIcon': {
      margin: "8px" ,
      position: "absolute",
      right: "0px",
      top: "1px"
    },},

});



class UploadMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upload_media: [],
      errorMessage: "",
      loading: false,
    };
  }
  componentWillUnmount() {

    this.state.upload_media.map((file, key) => {
          URL.revokeObjectURL(file.preview)
        }
    );
  }
  componentDidMount() {
    let answer_data = this.props.form.answer_data[this.props.data.key];

    if (answer_data && answer_data.answer) {
      let files = this.props.form.answer_data[this.props.data.key].answer[0];

      let files_array = [];

      if (files instanceof Array) {
        files_array = [...files];
      } else files_array.push(files);

      this.setState({
        upload_media: files_array,
      });
    }
  }

  onFilesDrop = async (files) => {

    files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)

    }));
    this.setState({
      loading: true,
    });
    let size = 0;
    for (let file of files) {
      size += file.size;

      if (size > config.UPLOAD_MEDIA_SIZE) {
        this.props.getDataFromComponent(
          { upload_media: this.state.upload_media },
          this.props.data.key,
          this.props.data.question_text,
          this.props.data.data
        );
        setTimeout(() => {
          this.setState({
            errorMessage: "File exceeds maximum size of "+config.UPLOAD_MEDIA_SIZE/1000000+" MB",
            upload_media: this.state.upload_media,
            loading: false,
          });
        }, 2000);
      } else {
        this.state.upload_media.push(file);

        this.props.getDataFromComponent(
          { upload_media: this.state.upload_media },
          this.props.data.key,
          this.props.data.question_text,
          this.props.data.data
        );
        setTimeout(() => {
          this.setState({
            errorMessage: "",
            upload_media: this.state.upload_media,
            loading: false,
          });
        }, 2000);
      }
    }
  };

  renderFileName = (uploadMedia) => {

    if (uploadMedia.name !== undefined) {
      return uploadMedia.name;
    } else {
      return uploadMedia;
    }
  };
  findLink=()=>{
    //this will try to find the link from answer_data in case the form session is saved.
    if(this.props.form.answer_data !== undefined && this.props.data.key !== undefined && this.props.form.answer_data[this.props.data.key] !== undefined)
    {
      let answer_data = this.props.form.answer_data[this.props.data.key]
      if (answer_data && answer_data.answer) {
        //the link returned will be the link to the zipped file
        // if more than one file is uploaded, so we don't have to
        // iterate through the whole answer.
        let file = this.props.form.answer_data[this.props.data.key].answer[0];
        return file.link;
      }
    }

  }

  removeItem = (key) => {
    let files = this.state.upload_media;
    files.splice(key, 1);

    this.setState({
      upload_media: files,
    });

    this.props.getDataFromComponent(
      { upload_media: this.state.upload_media },
      this.props.data.key,
      this.props.data.question_text,
      this.props.data.data
    );
  };


  render() {

    const { classes, form, data } = this.props;
    let accept_list = "";
    this.props.data.style.split(",").map((n) => {
      accept_list = accept_list + `.${n},`;
    });
    try {
      return (
        <div className={classes.root}>
          {!this.state.loading ? (
            <>
              <Dropzone
                style={{ width: "100%" }}
                multiple={false}
                disabled={this.props.data.read_only}

                onDrop={(acceptedFiles) => this.onFilesDrop(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{}}>
                      <Drop />
                      <input type="file" {...getInputProps()} />
                    </div>
                  </section>
                )}

              </Dropzone>
              <p style={{color: "rgba(134, 134, 134, 1)",  fontSize:"12px"}}>
                Maximum file size: {config.UPLOAD_MEDIA_SIZE/1000000} MB
              </p>
              <div style={{ paddingTop: 8 }}>
                {this.state.upload_media.map((file, key) => {
                  return (<Tooltip title={this.renderFileName(file)} placement={"top"}>
                   <Chip

                      key={key}
                      avatar={<Avatar
                          src={file.preview}
                          style={file.type && file.type.includes("image")?{ height: '100px', width:"170px",borderRadius: 0,
                            margin: "8px" ,position: "absolute",top: "30px" }:{ height: '40px', width:"40px",borderRadius: 0,
                            margin: "8px" ,position: "absolute",left: "0px" ,backgroundColor:"#59B0F6" ,bottom:"1px"}}
                      >
                        <img src="/svgs/fileIcon.svg" />
                      </Avatar>}
                      className={ file.type && file.type.includes("image")?classes.chip:classes.chipNotImage}
                      label={
                        <span style={{ margin: "8px" ,position: "absolute",left: "0px",top: "1px",maxWidth:"70%",display: "inline-block",
                          "whiteSpace": "nowrap",
                          "overflow": "hidden",
                          "textOverflow": "ellipsis"}}>
                          {this.renderFileName(file)}
                        </span>
                      }
                      onClick={() => {
                        if (file.name === undefined) {
                          //link after form submit
                          window.open(file);
                        }
                        else if(file.link)
                        {// link after form session save
                          // and user refreshes the page
                          window.open(file.link);
                        }
                        else{
                          //link after form session save
                          // and user stays on the same page
                          let link = this.findLink();
                          if(link !== undefined)
                          {
                            window.open(link);
                          }
                        }
                      }}

                      onDelete={this.props.data.read_only?undefined:() => {
                        this.removeItem(key);
                      }}
                      color="primary"
                    /></Tooltip>
                  );
                })}
                {this.state.errorMessage && (
                  <div
                    style={{
                      background: "#F7E5E9",
                      padding: 8,
                      marginBottom: 16,
                      border:"1px solid rgba(176, 0, 32, 1)",
                      width:"fit-content",
                      color: "rgba(0, 0, 0, 0.87)"
                  }}
                  >
                    <p className="errorTextFile" style={{ margin: 0 }}>
                      <ErrorIcon className="iconDiscard" />
                      <span>{this.state.errorMessage}</span>
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size={24} style={{ marginRight: 16 }} />
              <span>Uploading file...</span>
            </div>
          )}
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form,
  };
};

const mapDispatchToProps = {
  dispatchaddPageData: (key, answer, question_text) =>
    addPageData({ key, answer, question_text }),
  dispatchDeleteUpload: (key) => deleteFileUpload({ key }),
};

UploadMedia.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadMedia));
