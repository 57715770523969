import React, { useState, useEffect } from "react";

// MUI
import { styled } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { parseCalculatedVal, generateTableKeys } from "../Util/HandleCalc";

const StyledTextField = styled(TextField)(({ disabled }) => ({
  fontSize: "1em",
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    top: "-8px !important",
  },
  ...(disabled && {
    "& .MuiInputBase-root": {
      background: "rgba(248, 248, 248, 1) !important",
      color: "black !important",
    },
  }),
}));

function NumberInput({ data }) {
  const {
    answers,
    column,
    rowIndex,
    columnIndex,
    updateValue,
    isReadOnly,
    tableCells,
    size,
    isRS
  } = data;
  const [number, setNumber] = useState(null);

  useEffect(() => {
    // On initial load, set the number from the DB
    if (number == null) setNumber(answers[rowIndex][columnIndex].calculatedValue);

    // For deleting a row in repeatable sections
    else if (isRS && number != answers[rowIndex][columnIndex].calculatedValue) setNumber(answers[rowIndex][columnIndex].calculatedValue);

    // Else if table cells exist and the default value includes a ={}
    else if (
      tableCells != null &&
      tableCells[column.id][rowIndex].defaultValue.match(
        /(\={(?:\[??[^\[]*?\}))|"([0-9+ ]+)"/g
      ) != null &&
      number != null
    ) {
      // Get the table keys
      const getKeys = generateTableKeys(answers);

      // Get the updating value
      const updatingVal = {
        defaultValue: answers[rowIndex][columnIndex].defaultValue,
        calculatedValue: number,
      };

      // Perform the calculation on the value
      const calcVal = parseCalculatedVal(updatingVal, getKeys[0], getKeys[1]);

      // If the calculated value is different from the curr num val
      // And the answers calc value is also different
      // Update the value
      if (
        calcVal.calculatedValue != number &&
        answers[rowIndex][columnIndex].calculatedValue !=
          calcVal.calculatedValue
      ) {
        setNumber(calcVal.calculatedValue);
        updateValue(rowIndex, columnIndex, calcVal.calculatedValue, true);
      }
    }
  }, [answers]);

  // Only allow numbers to be entered
  const handleOnChange = (e) => {
    if (!isNaN(e.target.value)) setNumber(e.target.value);
  };

  const handleOnBlur = () => {
    if (isNaN(number) && answers[rowIndex][columnIndex].calculatedValue === "")
      return; // If not a number, return
    if (
      number.toString() ===
      answers[rowIndex][columnIndex].calculatedValue.toString()
    )
      return; // If same, return
    updateValue(rowIndex, columnIndex, number, false);
  };

  return (
    number != null && (
      <StyledTextField
        fullWidth
        variant="outlined"
        value={number}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={isReadOnly}
        id={`table-row-${data.rowIndex}-col-${data.columnIndex}`}
        size={size}
      />
    )
  );
}

export default NumberInput;
