import React, { useEffect, useState } from "react";

// MUI
import { Dialog, DialogContent, DialogTitle, Grid, styled, DialogActions, Button as MUIButton, CircularProgress, Tooltip } from "@material-ui/core";
import { ArrowBack, BorderColor, CheckCircle } from "@material-ui/icons";
import { CompletedIcon, CustomGrid, EditingIcon, FormItem, SpanItem, Transition } from "./CommonComponents";
import { Button } from "vardogyir-ui";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

function FormRenderItem({ formId, currFormId, formIdToSessionMap, formIdToNameMap, pagesMeta, visitedPages, handleNext, form, parsePlaceholder, projectSessionId, setShowRedirectDialog }) {
    const isActive = formId === currFormId;

    const [sessionInfo, setSessionInfo] = useState(null);

    useEffect(() => {
        let info = formIdToSessionMap[formId];

        if (info) {
            setSessionInfo(info);
            return;
        } else if (!projectSessionId) {
            // We have no session
            setSessionInfo({
                formSessionId: "",
            });
        }
    }, []);

    const renderPageItems = () => {
        return (
            <Grid container spacing={1}>
                {pagesMeta.map((aPage, index) => {
                    let currentIndex = parseInt(form.current_index);
                    let active = index === currentIndex;

                    if (visitedPages.has(index) || currentIndex + 1 == index) {
                        return (
                            <Grid
                                item
                                xs={12}
                                onClick={(event) => {
                                    handleNext(event, index);
                                }}
                            >
                                <FormItem isPageTitle isActive={active} color={active ? "rgba(33, 150, 243, 1)" : "rgba(111, 111, 111, 1)"}>
                                    <SpanItem keepSingleLine>{parsePlaceholder(aPage.pageTitle, form)}</SpanItem>
                                </FormItem>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid item xs={12}>
                                <FormItem isPageTitle isActive={active} color={active ? "rgba(33, 150, 243, 1)" : "rgba(111, 111, 111, 1)"}>
                                    <SpanItem keepSingleLine>{parsePlaceholder(aPage.pageTitle, form)}</SpanItem>
                                </FormItem>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        );
    };

    return sessionInfo ? (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tooltip title={formId === currFormId ? "" : "Click to navigate back to this form section/session"}>
                        <FormItem
                            isActive={isActive}
                            onClick={() => {
                                if (formId === currFormId) return;
                                setShowRedirectDialog(formId);
                            }}
                            color={isActive ? "rgba(33, 150, 243, 1)" : "rgba(111, 111, 111, 1)"}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>{sessionInfo.status === "SUCCESS" ? <CompletedIcon fontSize="small" /> : <EditingIcon isActive={isActive} fontSize="small" />}</Grid>
                                        <CustomGrid item xs>
                                            <SpanItem keepSingleLine>{formIdToNameMap[formId] ? formIdToNameMap[formId] : `Untitled`}</SpanItem>
                                        </CustomGrid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormItem>
                    </Tooltip>
                </Grid>
                {isActive && (
                    <Grid item xs={12}>
                        {renderPageItems()}
                    </Grid>
                )}
            </Grid>
        </>
    ) : (
        <></>
    );
}

export default FormRenderItem;
