import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import { Button } from "vardogyir-ui";
import Paper from "@material-ui/core/Paper";
import config from "../config";
import Amplify, { Auth } from "aws-amplify";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserAccount from "./component/UserAccount";

const uuid = require("uuid");
const styles = theme => ({
  paper: {
    minHeight: theme.spacing.unit * 90,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 20,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  textField: {
    width: theme.spacing.unit * 60,
    margin: "auto",
    position: "relative"
  },
  layout: {
    minHeight: "100vh",
    width: "80%",
    marginTop: theme.spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  Button: {
    boxShadow: "none",
    width: "15%",
    color: "#2196F3",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3"
    }
  },
  textArea: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "max-content",
    fontSize: 11
  }
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)(change to 'localhost' to work on local)
      domain: window.location.host.includes("localhost")
        ? "localhost"
        : config.DOMAIN,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).      
      secure: true,
      sameSite: "none"
    },
  }
});
class FormLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_logo: "",
      form_id: "",
      form_title: "",
      isReady:false
    };
  }
  componentDidMount() {
    //Auth.signOut();
    axios(
      `${config.API_ADDRESS}/form_meta?form_id=${this.props.match.params.form_id}`,
      {
        method: "get",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => {
        let data = res.data.data;
        this.setState({
          form_logo: data.form_logo,
          form_title: data.form_title,
          projectId: data.projectId,
          projectVersion: data.projectVersion,
          isReady:true
        });
      })
      .catch(e => {
        alert(e.response.data.msg);
         this.props.history.push("/form");
      });
  }
  handleChange = () => {
    this.setState(state => {
      return { confirmed: !state.confirmed };
    });
  };
  handleSubmit = () => {
    try {
      const session_id = uuid();
      axios(`${config.API_ADDRESS}/form_session`, {
        method: "post",
        data: {
          data: {},
          session_id: session_id,
          form_id: this.props.match.params.form_id,
          projectId: this.state.projectId,
          projectVersion: this.state.projectVersion
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          this.props.history.push(
            `/form/${this.props.match.params.form_id}/${session_id}`
          );
        })
        .catch(e => {
          alert(e);
        });
    } catch (e) {}
  };
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout} style={{minHeight:"unset"}}>
        <div className={classes.paper} style={{display:"flex",justifyContent: "center"}}>
          <div className="divForm">
            <div style={{display:"flex",justifyContent: "flex-end"}}>
              <UserAccount/>
            </div>
            <div>
              {
                this.state.form_logo == "DELETE" || this.state.form_logo == ""
                ?
                  (
                    <img
                      style={{maxHeight:"200px"}}
                      className="imageForm"
                      src={"/images/default-image-form.png"}
                    />
                  )
                :
                  (
                    <img
                      style={{maxHeight:"200px",marginBottom:24}}
                      className="imageForm"
                      src={this.state.form_logo}
                    />
                  )
              }
            </div>
            {this.state.form_title ? (
              <div>
                <h2
                  style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginBottom: 32
                  }}
                >
                  {this.state.form_title}
                </h2>
              </div>
            ) :
              (
                <div>
                  <h2
                    style={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginBottom: 32
                    }}
                  >
                    Untitled
                  </h2>
                </div>
              )}

            <Button onClick={this.handleSubmit} theme={"P_blue"} disabled={this.state.isReady?false:true}>
              {"Start"}
              {!this.state.isReady && (
                <CircularProgress size={30}/>
              )}
            </Button>
            <div style={{ textAlign: "center",marginTop:16 }}>
              <div className={classes.textArea} style={{width:"100%",display:"unset"}}>
                <span>By proceeding you agree to the </span>
                <span><a
                  target="_blank"
                  className="conditionText"
                  href={"https://www.workflow86.com/terms-and-conditions-of-use/"}
                >
                  {" "}
                  Conditions of Use
                </a></span>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(FormLanding);
