import React from "react";

// MUI
import { Dialog, DialogContent, DialogTitle, Grid, styled, DialogActions } from "@material-ui/core";
import { Transition, Text, getResponseSubmitted } from "./NavigationSidebar/CommonComponents";
import { KeyboardBackspace, Refresh } from "@material-ui/icons";
import { Button as VButton } from "vardogyir-ui";

function SubmitDialogs({ showSubmissionDialog, closeSubmissionDialog, formState }) {
    const handleRestart = () => {
        if (!formState || !formState.form_id) return;
        if (formState.formType !== "form") return;
        window.open(`/form/${formState.form_id}`, "_self");
    };

    return (
        <Dialog open={showSubmissionDialog} maxWidth="sm" TransitionComponent={Transition}>
            <DialogTitle>{getResponseSubmitted()}</DialogTitle>
            {formState.submMsg && (
                <DialogContent style={{ textAlign: "left"}}>
                    <Text>{formState.submMsg}</Text>
                </DialogContent>
            )}
            <DialogActions>
                <Grid container spacing={1} display="flex" justify="flex-end">
                    {formState.formType === "form" && formState.canAddSubmission && (
                        <Grid item>
                            <VButton variant="outlined" startIcon={<Refresh />} theme={"S_blue"} onClick={handleRestart}>
                                Restart
                            </VButton>
                        </Grid>
                    )}
                    <Grid item>
                        <VButton variant="outlined" onClick={closeSubmissionDialog} startIcon={<KeyboardBackspace />} theme={"S_blue"}>
                            Back to form
                        </VButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default SubmitDialogs;
