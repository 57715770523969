import React, { useEffect, useState } from "react";

// MUI
import { styled } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl } from "@material-ui/core";

const StyledSelect = styled(Select)(({ disabled }) => ({
    fontSize: "1em",
    "& .MuiOutlinedInput-notchedOutline": {
        top: "-8px !important",
    },
    ...(disabled && {
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            background: "rgba(248, 248, 248, 1) !important",
            color: "black !important",
        },
    }),
}));

function DropdownInput({ data }) {
    const { answers, column, rowIndex, columnIndex, updateValue, isReadOnly, size } = data;
    const [currVal, setCurrVal] = useState(null);

    useEffect(() => {
        setCurrVal(answers[rowIndex][columnIndex].calculatedValue);
    }, [answers]);

    const handleOnBlur = () => {
        if (currVal === answers[rowIndex][columnIndex].calculatedValue) return;
        updateValue(rowIndex, columnIndex, currVal, false);
    };

    return (
        currVal != null && (
            <FormControl size={size} fullWidth>
                <StyledSelect
                    fullWidth
                    variant="outlined"
                    value={currVal}
                    onChange={(e) => {
                        setCurrVal(e.target.value);
                    }}
                    onBlur={handleOnBlur}
                    disabled={isReadOnly}
                    id={`table-row-${data.rowIndex}-col-${data.columnIndex}`}
                >
                    {column.options.map((opt) => {
                        return (
                            <MenuItem key={opt.id} value={opt.value}>
                                {opt.value}
                            </MenuItem>
                        );
                    })}
                </StyledSelect>
            </FormControl>
        )
    );
}

export default DropdownInput;
