import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { DropDown, DropRight } from "vardogyir-ui";

export default class NavigationBar extends Component {
  render() {
    return (
      <div // NavBar
        style={{
          height: 40,
          display: "flex",
          backgroundColor: "white",
          color: "black"
        }}
      >
        <div style={{ width: 89 }}>
          <DropDown Title={"Results"}>
            {Object.values(this.props.AhvvaData).map((target, targetIndex) => {
              return (
                <DropRight Title={`Target ${targetIndex + 1}`}>
                  {target.data.map((vehicle, vehicleIndex) => {
                    return (
                      <DropRight
                        Title={`Vehicle ${target.carList[vehicleIndex]}`}
                      >
                        {vehicle.paths_list.map((path, pathIndex) => {
                          return (
                            <MenuItem
                              onClick={this.props.changePath.bind(
                                this,
                                targetIndex,
                                vehicleIndex,
                                pathIndex
                              )}
                            >{`${target.carList[vehicleIndex]} Path ${path.pair_id}`}</MenuItem>
                          );
                        })}
                      </DropRight>
                    );
                  })}
                </DropRight>
              );
            })}
          </DropDown>
        </div>
        <div style={{ width: 89 }}>
          <DropDown Title={"Help"}>
            <MenuItem
              onClick={() => {
                window.open("https://www.vardogyir.com/contact-us/");
              }}
            >
              Contact us
            </MenuItem>
          </DropDown>
        </div>
      </div>
    );
  }
}
