import config from "../../config";
import axios from "axios";

const requestGet = async (url) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
  return await axios.get(url, { headers });
}

const requestPost = async (url, data) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
  return await axios.post(url, data, headers);
}

const fetchInfoPreview = async (form_id, projectVersion) => {
  const url = `${config.API_ADDRESS}/form_meta?form_id=${form_id}&projectVersion=${projectVersion}&includeSingleUseForm=true`;
  const json = await requestGet(url).catch(e => {
    alert(e.response.data.msg);
  });
  return json.data;
}

const fetchDataPreview = async (form_id, projectVersion) => {
  const url = `${config.API_ADDRESS}/form_meta?form_id=${form_id}&projectVersion=${projectVersion}&includeSingleUseForm=true`;
  const json = await requestGet(url).catch(e => {
    alert(e.response.data.msg);
  });
  return json.data;
}

const handleSubmitStart = async (data) => {
  const url = `${config.API_ADDRESS}/form_session`;
  const json = await requestPost(url, data).catch(e => {
    alert(e.response.data.msg);
  });
  return json;
}

export {
  fetchInfoPreview,
  handleSubmitStart,
  fetchDataPreview
}