export const RequestActionTypes = {
  VERSION_GET_REQUEST: 'VERSION_GET_REQUEST',
  AUTHED_GET_REQUEST: 'AUTHED_GET_REQUEST',
  AUTHED_POST_REQUEST: 'AUTHED_POST_REQUEST',
  AUTHED_DELETE_REQUEST: 'AUTHED_DELETE_REQUEST',
  UNAUTHED_GET_REQUEST: 'UNAUTHED_GET_REQUEST',
  UNAUTHED_POST_REQUEST: 'UNAUTHED_POST_REQUEST',
  REQUEST_COMPLETED: 'REQUEST_COMPLETED',
  REQUEST_RESTARTED: 'REQUEST_RESTARTED',
  REQUEST_STOPPED: 'REQUEST_STOPPED',
  AUTHED_PUT_REQUEST: 'AUTHED_PUT_REQUEST'

};

export default RequestActionTypes;
