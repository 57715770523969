import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "./Calendar.css";
import React from "react";
import Comment from "./Comment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { timezones } from "../utils/timezones";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc)
dayjs.extend(timezone)

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar_answer: this.props.data.auto ? this.props.data.auto : null,
      convertedDateTime: null
    };
  }

  componentDidMount() {
    let answer_data = this.props.form.answer_data[this.props.data.key];

    if (answer_data && answer_data.answer) {
      let date = new Date(this.props.form.answer_data[this.props.data.key].answer[0]);
      this.setState({
        calendar_answer: date,
        convertedDateTime: this.convertDateTime(date),
      });
    } else if (this.props.data.auto) {
      // We are given a pre-filled ans
      // Set the date into the state
      let date = new Date(this.props.data.auto);

      console.log(date)
      this.setState({
        calendar_answer: date ? date.getTime() : date,
        convertedDateTime: this.convertDateTime(date),
      });

      // Set the date into the form answer state
      this.props.getDataFromComponent(
        { calendar_answer: date ? date.getTime() : date },
        this.props.data.key,
        this.props.data.question_text,
        this.props.data.data
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.auto.toString() != this.props.data.auto.toString()) {
      if (
        this.props.data.auto &&
        !this.props.form.answer_data[this.props.data.key]
      ) {
        this.props.getDataFromComponent(
          { calendar_answer: this.props.data.auto },
          this.props.data.key,
          this.props.data.question_text,
          this.props.data.data,
          this.props.data.outputFormat,
          this.props.data.timezone
        );
      }
    }
  }

  getTimezoneNameFromOffset = (offset) => {
    let timezone = timezones.find((tZ) => tZ.offset === offset);
    if (!timezone) return;
    return timezone.name;
  }

  convertDateTime = (dT) => {
    try {
      let offset = this.props.data.timezone;
      let timezoneName = this.getTimezoneNameFromOffset(offset);
      if (!timezoneName) return;

      let datetimeWithTimezone = dayjs.tz(dT, timezoneName);
      return datetimeWithTimezone.format("D MMMM YYYY, h:mm:ss a");
    } catch (e) {
      return;
    }
  }

  handleChange = (date) => {
    this.setState({ calendar_answer: date, convertedDateTime: this.convertDateTime(date) });
    let text = { calendar_answer: date.getTime() };

    if (this.props.data.outputFormat == "")
      this.props.getDataFromComponent(
        text,
        this.props.data.key,
        this.props.data.question_text,
        this.props.data.data
      );
    else
      this.props.getDataFromComponent(
        text,
        this.props.data.key,
        this.props.data.question_text,
        this.props.data.data,
        this.props.data.outputFormat,
        this.props.data.timezone
      );
  };

  parseFormat = (outputFormat) => {
    switch (outputFormat) {
      case "dd LLLL YYYY":
        return "dd LLLL yyyy";
      case "dd LLLL YYYY kk:mm":
        return "dd LLLL yyyy HH:mm";
      case "dd/MM/YY":
        return "dd/MM/yy";
      case "dd/MM/YY kk:mm":
        return "dd/MM/yy HH:mm";
      case "dd/MM/YYYY":
        return "dd/MM/yyyy";
      case "dd/MM/YYYY kk:mm":
        return "dd/MM/yyyy HH:mm";
      case "dd-MM-YYYY":
        return "dd-MM-yyyy";
      case "dd-MM-YYYY kk:mm":
        return "dd-MM-yyyy HH:mm";
      case "MM/dd/YYYY":
        return "MM/dd/yyyy";
      case "MM/dd/YYYY kk:mm":
        return "MM/dd/yyyy HH:mm";
      case "YYYY-MM-dd":
        return "yyyy-MM-dd";
      case "YYYY-MM-dd kk:mm":
        return "yyyy-MM-dd HH:mm";
      case "Unix":
        return "utc";
      default:
        return "dd LLLL yyyy HH:mm";
    }
  };
 
  render() {
    const { data, isAnnotationModeOn } = this.props;
    const { outputFormat } = data;
    let format = this.parseFormat(outputFormat);
    return (
      !isAnnotationModeOn ? 
      <Grid container>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {format.includes("m") ? (
              <DateTimePicker
                variant={"inline"}
                ampm={false}
                autoOk={true}
                disabled={data.read_only}
                value={this.state.calendar_answer}
                format={format}
                onChange={this.handleChange}
              />
            ) : (
              <DatePicker
                variant={"inline"}
                disabled={data.read_only}
                autoOk={true}
                format={format}
                value={this.state.calendar_answer}
                onChange={this.handleChange}
              />
            )}
          </MuiPickersUtilsProvider>
        </Grid>
        {this.state.convertedDateTime && 
          <Grid item xs={12}>
            <div style={{ color: "rgba(134, 134, 133, 0.87)", fontSize: "12px", marginTop: "10px" }}>{this.state.convertedDateTime} in {this.getTimezoneNameFromOffset(this.props.data.timezone)}</div>
          </Grid>
        }
      </Grid>
      : <span id={`${data.key}-answer-text`} style={{ fontSize: "14px", borderBottom: "1px solid #000", paddingBottom: "4px" }}>
        {this.state.calendar_answer && this.state.calendar_answer.toString()}
    </span>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: state.form,
  };
};

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Calendar);
