import React from "react";

import { dynamicRender } from "./dynamicRender";

var _ = require("lodash");
export const checkAllAnswered = _this => {
  let page_data =
    _this.props.form.request_data[_this.props.form.current_index].components;

  const answer = _this.props.form.answer_data;

  let required_list = [];
  for (let i in page_data) {
    if (page_data[i].required != "false" && page_data[i].key != "main_page") {
      if (
        !answer[page_data[i].key] ||
        answer[page_data[i].key].answer.length <= 0 ||
        (answer[page_data[i].key].answer.length > 0 &&
          !(
            answer[page_data[i].key].answer[0] != null &&
            answer[page_data[i].key].answer[0].toString()
          ))
      ) {
        required_list.push({
          question_text: dynamicRender(
            _this,
            page_data[i].views.question.view.data.default,
            page_data[i].views.question.view.data.dynamic
          ),
          index: i
        });
      }
    }
  }
  if (required_list.length > 0) {
    _this.setState({ required_list: required_list });
    return false;
  }

  return true;
};
export const parseJson = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const isContain = (data, container) => {
  function hasObject(element, target) {
    // console.log(element,target);
    return _.isEqual(element, target);
  }
  let i = container.findIndex(n => {
    let a = hasObject(n, data);
    if (a == true) {
      return true;
    }
  });
  // console.log(i)
  return i != -1;
};

export const unWrap_answer = (_this, data, container, current_deepth) => {
  console.log("unwrap", data, container, current_deepth);

  if (typeof data != "string" && typeof data != "number") {
    for (let key in data) {
      if (current_deepth == 0) {
        container.push(<ul>{key} </ul>);
      } else {
        let curr = container[container.length - 1];
        //  curr.props.children.push(<ul>{key}</ul>)
        console.log(curr.props.children);

        let tmp = React.Children.toArray(curr.props.children);
        tmp.push(<ul>{key}</ul>);

        container[container.length - 1] = React.cloneElement(curr, {
          children: tmp
        });
      }
      // if (container[current_deepth]){
      //     let curr = container[current_deepth];
      //      container[current_deepth].appendChild(<ul>{key}</ul>)
      //     // curr.props.children.push(<ul>{key}</ul>)
      //     // container[current_deepth] = curr
      // }
      // else {
      //     container[current_deepth] = (<ul>{key}</ul>)
      //
      //     // container[current_deepth].props.children = [key]
      // }
      unWrap_answer(_this, data[key], container, current_deepth + 1);
    }
  } else {
    if (current_deepth == 0) {
      container.push(<ul>{data} </ul>);
    } else {
      let curr = container[container.length - 1];
      // curr.props.children.push(<li>{data}</li>)
      let tmp = React.Children.toArray(curr.props.children);
      tmp.push(
        <ul>
          <li>{data}</li>
        </ul>
      );
      container[container.length - 1] = React.cloneElement(curr, {
        children: tmp
      });
    }
    // if (container[current_deepth]){
    //           let curr = container[current_deepth];
    //            container[current_deepth].appendChild(<li>{data}</li>)
    //           // curr.props.children.push(<li>{data}</li>)
    //           // container[current_deepth] = curr
    //       }
    // else {
    //           container[current_deepth] = (<li>{data}</li>)
    //       }
    // tmp.push(data)
    // container[current_deepth] = tmp
    _this.state.summary = container;
    // this.forceUpdate()
    return container;
  }
};
