import React, { createRef, Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import L from "leaflet";
import { withStyles } from "@material-ui/core/styles/index";
import { antPath } from "leaflet-ant-path";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Scatter } from "react-chartjs-2";
import "./Legend.css";
import {getLegendColor} from "./GetLegendColor";
import AhvvaContent from "./AhvvaContent";

const styles = theme => ({
  Button: {
    color: "#2196F3",
    backgroundColor: "#444444",
    "&:hover": {
      backgroundColor: "#2f2f2f"
    }
  },
  details: {
    alignItems: "center",
    width: "100%"
  },
  table: {
    width: "1000"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
});
  
class DisplayPath extends React.Component {
  constructor() {
    super();
    this.mapRef = React.createRef();

    this.state = {};
  }
  detectActive = (i, j) => {
    const likelihood = ["", "likely", "possible", "unlikely"];
    const consequence = ["", "minor", "moderate", "severe"];
    return [likelihood.lastIndexOf(i), consequence.lastIndexOf(j)];
  };

  generateMatrix = (likely, cons) => {
    const active = this.detectActive(likely.toLowerCase(), cons.toLowerCase());
    let matrix = [];
    const label = [
      [
        { name: "", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Minor", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Moderate", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Severe", defaultbg: "light_grey", activebg: "light_grey" }
      ],
      [
        { name: "Likely", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Low Risk", defaultbg: "c1f8d8", activebg: "109748" },
        { name: "Medium Risk", defaultbg: "fff6ce", activebg: "fed009" },
        { name: "High Risk", defaultbg: "ffd7c6", activebg: "e24100" }
      ],
      [
        { name: "Possible", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Low Risk", defaultbg: "c1f8d8", activebg: "109748" },
        { name: "Medium Risk", defaultbg: "fff6ce", activebg: "fed009" },
        { name: "Medium Risk", defaultbg: "fff6ce", activebg: "fed009" }
      ],
      [
        { name: "Unlikely", defaultbg: "light_grey", activebg: "light_grey" },
        { name: "Low Risk", defaultbg: "c1f8d8", activebg: "109748" },
        { name: "Low Risk", defaultbg: "c1f8d8", activebg: "109748" },
        { name: "Low Risk", defaultbg: "c1f8d8", activebg: "109748" }
      ]
    ];
    // console.log(active)
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (i == active[0] && j == active[1]) {
          matrix.push(
            <div className={"col-3"} style={{ padding: 0 }}>
              <div
                style={{
                  margin: 0,
                  color: "black",
                  position: "relative",
                  height: "70px"
                }}
              >
                <div style={{ border: "5px solid black" }}>
                  <img
                    src={`/images/${label[i][j].activebg}.jpg`}
                    style={{ margin: "auto", width: "100%", height: "60px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "25px",
                      margin: "auto",
                      width: "90%",
                      color: "black"
                    }}
                  >
                    <h4>{label[i][j].name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          matrix.push(
            <div className={"col-3"} style={{ padding: 0 }}>
              <div
                style={{
                  margin: 0,
                  color: "black",
                  position: "relative",
                  height: "70px"
                }}
              >
                <div style={{ border: "5px solid white" }}>
                  <img
                    src={`/images/${label[i][j].defaultbg}.jpg`}
                    style={{ margin: "auto", width: "100%", height: "60px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "25px",
                      margin: "auto",
                      width: "90%",
                      color: "black"
                    }}
                  >
                    <h4>{label[i][j].name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    }

    return matrix;
  };

  componentDidMount() {
    const myMap = L.map(`display-${this.props.data.title}`, { zoomControl: false }).setView(
      [-33.89911682453446, 151.2072569896768],
      8
    );
    const CartoDB_Voyager = L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        maxZoom: 18
      }
    );
    CartoDB_Voyager.addTo(myMap);
    L.control.scale().addTo(myMap);

    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      const labels = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<i style="background:' +
            getLegendColor(from + 1) +
            '; width: 24px; height: 12px; border-radius: 8px;"></i> ' +
            from +
            (to ? "&ndash;" + to : "+") +
            "km/h"
        );
      }

      div.innerHTML = labels.join("<br>");
      return div;
    };

    legend.addTo(myMap);

    this.props.data.coordlist.slice(0, -1).map((n, i) => {
      let speedlist = this.props.data.speedlist;
      let coordlist = this.props.data.coordlist;
      let coordinatepair = [coordlist[i], coordlist[i + 1]];
      let barrier = antPath(coordinatepair, {
        dashArray: [10, 1],
        pulseColor: AhvvaContent.get_segment_colour(speedlist[i + 1]) || "blue",
        opacity: 1,
        weight: 7,
        color: AhvvaContent.get_segment_colour(speedlist[i + 1]) || "blue",
        paused: true,
        hardwareAccelerated: true
      });
      myMap.addLayer(barrier);
      myMap.fitBounds(barrier.getBounds());
    });

    const icon_target = L.icon({
      iconUrl: "/images/impact_zone_radius.svg",
      iconSize: [80, 80], // size of the icon
      iconAnchor: [40, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
    });
    const icon_start = L.icon({
      iconUrl: "/images/start_marker.svg",
      iconSize: [15, 15], // size of the icon
      iconAnchor: [7.5, 7.5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -7.5] // point from which the popup should open relative to the iconAnchor
    });

    L.marker(this.props.data.coordlist[this.props.data.coordlist.length - 1], {
      icon: icon_target
    })
      .addTo(myMap)
      .bindPopup("Point of Impact");

    L.marker(this.props.data.coordlist[0], { icon: icon_start })
      .addTo(myMap)
      .bindPopup("Path Start");
  }

  render() {
    const { classes } = this.props;
    try {
	    let consequence_rating = AhvvaContent.get_consequence_rating(this.props.data.momentum);
    let likelihood_rating = AhvvaContent.get_likelihood_rating(this.props.data.sum_angle);

      return (
        <div>
          <div className={"subpage"}>
            <div style={{ width: "100%" }}>
              <h1 style={{ whiteSpace: "pre-line" }}>
                {`${this.props.data.title}  Page:${this.props.page_index}`}
              </h1>

              <div
                style={{ width: "100%", height: "16cm" }}
                id={`display-${this.props.data.title}`}
              ></div>

              <br />

              <div style={{ boxSizing: "unset", width: "100%" }}>
                <div
                  className={"row"}
                  style={{ margin: "auto", width: "100%" }}
                >
                  <div className={"col-4"}>
                    <div
                      style={{
                        position: "relative",
                        margin: "auto",
                        width: "100%",
                        color: "black",
                        padding: "4px",
                        height: "100%"
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          height: "100%",
                          verticalAlign: "middle"
                        }}
                        className="helper"
                      ></span>
                      <img
                        style={{ width: "100%" }}
                        src={`/images/${this.props.data.carType}.jpg`}
                      />
                    </div>
                  </div>
                  <div className={"col-4"}>
                    <div
                      style={{
                        position: "relative",
                        margin: "auto",
                        width: "100%",
                        color: "black",
                        padding: "4px",
                        height: "5cm"
                      }}
                    >
                      <img
                        src="/images/light_grey.jpg"
                        style={{
                          margin: "auto",
                          width: "100%",
                          height: "100%"
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "5mm",
                          margin: "auto",
                          width: "90%"
                        }}
                      >
                        <h5 style={{ textAlign: "center" }}>
                          <strong>Velocity</strong>
                        </h5>
                        <h2 style={{ color: "black" }}>
                          <strong>
                            {this.props.data.velocity == 0
                              ? 0
                              : this.props.data.velocity.toFixed(2)}
                          </strong>
                        </h2>
                        <p style={{ textAlign: "center" }}>
                          <strong>Kilometers per Hour</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={"col-4"}>
                    <div
                      style={{
                        position: "relative",
                        margin: "auto",
                        width: "100%",
                        color: "black",
                        padding: "4px",
                        height: "5cm"
                      }}
                    >
                      <img
                        src="/images/light_grey.jpg"
                        style={{
                          margin: "auto",
                          width: "100%",
                          height: "100%"
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "5mm",
                          margin: "auto",
                          width: "90%"
                        }}
                      >
                        <div>
                          <h5 style={{ textAlign: "center" }}>
                            <strong>Momentum</strong>
                          </h5>
                          <h2 style={{ color: "black" }}>
                            <strong>
                              {this.props.data.momentum == 0
                                ? 0
                                : this.props.data.momentum.toFixed(2)}
                            </strong>
                          </h2>
                          <p style={{ textAlign: "center" }}>
                            <strong>Newton seconds</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div style={{ height: "260px" }}>
                  <div className={"row"}>
                    <div className={"col-1"}>
                      <h3
                        style={{
                          writingMode: "vertical-lr",
                          position: "relative",
                          top: "120px",
                          transform: "rotate(180deg)",
                          textAlign: "right"
                        }}
                      >
                        Likelihood
                      </h3>
                    </div>
                    <div className={"col-11"}>
                      <div className={"row"}>
                        <div className={"col-12"}>
                          <h3 style={{ textAlign: "center" }}>Consequences</h3>
                        </div>
                        {
			  this.generateMatrix(
                          likelihood_rating,
                          consequence_rating
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ pageBreakAfter: "always" }}></div>
        </div>
      );
    } catch (e) {
      return <p>Loading...</p>;
    }
  }
}

export default withStyles(styles)(DisplayPath);
