import React from "react";

// mui
import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";

// mui icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// util
import IconBanner from "../Icon/IconBanner";

export const FormFailuresText = styled("div")({
    fontSize: "14px",
    lineHeight: "19px",
    color: "rgba(53, 53, 53, 1)",
    width: "fit-content",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
});

export const getFailedUploadsBanner = (failedUploads) => {
    return (
        <IconBanner
            bgColor="rgba(225, 0, 0, 0.1)"
            borderColor="rgba(225, 0, 0, 1)"
            icon={<ErrorOutlineIcon style={{ fontSize: "16px", color: "rgba(225, 0, 0, 1)" }} />}
            content={
                <Grid container>
                    <Grid item xs={12}>
                        <FormFailuresText>Uploaded file(s) could not be saved or submitted. Please check and re-upload the following files and try again:</FormFailuresText>
                    </Grid>
                    <Grid item xs={12}>
                        <ul style={{ marginBottom: 0, paddingBottom: 0, paddingLeft: "32px" }}>
                            {Object.values(failedUploads).map((item) => {
                                if (!item || !item.answer) return <></>;
                                return item.answer.map((file) => {
                                    if (!file || file.length <= 0) return <></>;
                                    return file.map((individualfile) => {
                                        return (
                                            <li>
                                                <FormFailuresText>{individualfile.name ? individualfile.name : individualfile.path}</FormFailuresText>
                                            </li>
                                        );
                                    });
                                });
                            })}
                        </ul>
                    </Grid>
                </Grid>
            }
        />
    );
};
