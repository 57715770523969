import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { convertTableDataToCorrectFormat, isCellDisabled, isCellReadOnly } from "./Util/Helpers";
import { emptyTableWithDatetime } from "./Util/HandleInitialiseTable";
import { getRepeatableSectionsAnswerKeys, parseRepeatableSectionsAnswerData, addEmptySection, calculateSectionsToShow, removeItemWithIndex } from "./Util/RSUtil";

// MUI
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

// Input types
import TextInput from "./Inputs/TextInput";
import DatetimeInput from "./Inputs/DatetimeInput";
import NumberInput from "./Inputs/NumberInput";
import DropdownInput from "./Inputs/DropdownInput";

const ColumnNameText = styled("span")({
    color: "rgba(0, 0, 0, 1)",
    fontSize: "14px",
    fontWeight: "bold",
});

const DescriptionText = styled("span")({
    color: "#868685",
    fontSize: "13px",
});

const Table = styled(Grid)({
    margin: "16px 0px",
});

const Row = styled(Grid)(({ noMarginBottom }) => ({
    marginBottom: noMarginBottom ? "0px !important" : "32px !important",
    border: "1px solid #D9D9D9",
    padding: "16px",
    borderRadius: "6px",
}));

const RowItem = styled(Grid)({
    marginBottom: "16px",
});

const AddNewRowContainer = styled(Grid)({
    marginBottom: "16px",
    border: "1px solid #D9D9D9",
    color: "#000",
    padding: "4px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
        color: "rgba(0, 0, 0, 0.85) !important",
        backgroundColor: "rgba(0, 0, 0, 0.005)",
        cursor: "pointer",
    },
});

const AnnotationCell = styled("div")({
    border: "1px solid #d5d5d5",
    padding: "18.5px 14px",
    borderRadius: "4px",
    fontSize: "14px",
    marginTop: "5px",
    overflow: "hidden",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
});

const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    justifyContent: horizontal,
    alignItems: vertical,
}));

const RemoveIcon = styled(CancelOutlinedIcon)({
    height: "20px",
    width: "20px",
});

const RemoveIconDiv = styled("div")({
    color: "#868686",
    width: "fit-content",
    "&:hover": {
        cursor: "pointer !important",
        color: "rgba(0,0,0,0.8) !important",
    },
});

const AddIcon = styled("img")({
    marginRight: "4px",
});

const AddNewRowText = styled("span")({
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

function RepeatableSectionsInput(props) {
    const { data, getDataFromComponent, tableErrors, setRequiredTableErrors, isAnnotationModeOn } = props;

    const [columns, setColumns] = useState(null);
    const [numRows, setNumRows] = useState(null);
    const [exportType, setExportType] = useState(null);

    // Get the table answer keys
    const [tableAnswerKeys, setTableAnswerKeys] = useState(getRepeatableSectionsAnswerKeys(data));

    const [tableAnswers, setTableAnswers] = useState(null);

    const [sectionsShown, setSectionsShown] = useState(1);

    // Initial on load use effect
    useEffect(() => {
        // Set data structure
        setColumns(data.data);
        setNumRows(parseInt(data.numRows));
        setExportType(data.exportType);

        let answerData = props.form.answer_data[data.key];

        // If there already exists answer data,
        // Try to set it into the state
        // Else create an empty table
        if (answerData) {
            try {
                const sectionsToShow = calculateSectionsToShow(answerData);

                let parsedAnswerData = parseRepeatableSectionsAnswerData(answerData, data.data, data.exportType, data.rowLabels, sectionsToShow);
                if (parsedAnswerData == null) throw "Error loading answers";

                setSectionsShown(sectionsToShow);
                setTableAnswers(parsedAnswerData);
            } catch (e) {
                // If there is an error loading the table answers from the form session
                let noAnswerTable = emptyTableWithDatetime(data, sectionsShown);
                updateStateAndTable(noAnswerTable);
            }
        } else {
            // This is a new table with no answerData
            let noAnswerTable = emptyTableWithDatetime(data, sectionsShown);
            updateStateAndTable(noAnswerTable);
        }
    }, []);

    // Use effect for handling if the table structure changes
    useEffect(() => {
        let answerKeys = getRepeatableSectionsAnswerKeys(data);

        setTableAnswerKeys(answerKeys);
        // Converts the data into the correct format
        let updatedTable = {
            repeatable_sections_answer: convertTableDataToCorrectFormat(exportType, tableAnswers, columns, data.rowLabels),
        };

        if (updatedTable.repeatable_sections_answer != null) {
            // Save the data in the redux state
            getDataFromComponent(updatedTable, data.key, data.question_text, data.data, null, null, answerKeys, data.exportFormat);
        }
    }, [data.data, data.exportType, data.numRows, data.rowLabels]);

    // Updates both the local and global states
    // With the given answer table
    const updateStateAndTable = (givenAnswerTable) => {
        setTableAnswers(givenAnswerTable); // Set to empty table

        let updated_table = {
            repeatable_sections_answer: convertTableDataToCorrectFormat("rowsAsLists", givenAnswerTable, data.data, data.rowLabels),
        };

        getDataFromComponent(updated_table, data.key, data.question_text, data.data, null, null, tableAnswerKeys, data.exportFormat);
    };

    // Updates a value in the table of answers
    const handleUpdateValue = (rIdx, cIdx, value, isCalc) => {
        let newTable = [...tableAnswers];

        if (tableAnswers[rIdx][cIdx].value != null) {
            newTable[rIdx][cIdx].value.calculatedValue = value;

            // If it isn't a calc refresh, overwrite the default Value
            if (!isCalc) newTable[rIdx][cIdx].value.defaultValue = value;
        } else {
            newTable[rIdx][cIdx].calculatedValue = value;

            // If it isn't a calc refresh, overwrite the default Value
            if (!isCalc) newTable[rIdx][cIdx].defaultValue = value;
        }

        updateStateAndTable(newTable);

        let newTableErrors = { ...tableErrors };
        delete newTableErrors[data.key];
        setRequiredTableErrors(newTableErrors);
    };

    // Render the valid input type
    const renderInputType = (col, rowIdx, colIdx) => {
        if (rowIdx >= sectionsShown) return <></>;

        let dataObject = {
            answers: tableAnswers,
            column: col,
            rowIndex: rowIdx,
            columnIndex: colIdx,
            updateValue: handleUpdateValue,
            isReadOnly: data.read_only || isCellReadOnly(data.tableCells, col.id, rowIdx),
            tableCells: data.tableCells,
            size: "small",
            isRS: true,
        };

        if (!isAnnotationModeOn) {
            switch (col.type) {
                case "text":
                    return <TextInput data={dataObject} />;
                case "datetime":
                    return <DatetimeInput data={dataObject} />;
                case "number":
                    return <NumberInput data={dataObject} />;
                case "dropdown":
                    return <DropdownInput data={dataObject} />;
                default:
                    return "Not a valid type";
            }
        } else {
            return <AnnotationCell id={`${data.key}-${rowIdx}-${colIdx}`}>{tableAnswers[rowIdx][colIdx].calculatedValue}</AnnotationCell>;
        }
    };

    // Render each column
    const renderColumn = (rowIndex) => {
        if (rowIndex >= sectionsShown) return <></>;

        const showRemoveButton = sectionsShown > 1;

        return (
            <Row item xs={12} noMarginBottom={rowIndex + 1 == sectionsShown} wrap={"wrap"} key={rowIndex}>
                <Grid container>
                    {showRemoveButton && (
                        <CustomGrid item xs={12} vertical="center" horizontal="flex-end">
                            <RemoveIconDiv onClick={() => removeRepeatableSection(rowIndex)}>
                                <RemoveIcon />
                            </RemoveIconDiv>
                        </CustomGrid>
                    )}
                    <Grid item xs={12}>
                        <Grid container>
                            {columns.map((col, columnIndex) => {
                                return (
                                    <RowItem item xs={12} key={rowIndex.toString() + columnIndex.toString()}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <ColumnNameText id={`${data.key}-column-${columnIndex}`}>{col.question ? `${col.question} ${col.required ? "*" : ""}` : "Untitled"}</ColumnNameText>
                                            </Grid>
                                            {col.description && (
                                                <Grid item xs={12}>
                                                    <DescriptionText id={`${data.key}-column-${columnIndex}-description`}>{col.description}</DescriptionText>
                                                </Grid>
                                            )}
                                            {!isCellDisabled(data.tableCells, col.id, rowIndex) && (
                                                <Grid item xs={12} style={{ marginTop: "4px" }}>
                                                    {renderInputType(col, rowIndex, columnIndex)}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </RowItem>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Row>
        );
    };

    const addRepeatableSection = () => {
        if (sectionsShown >= numRows) return;

        // Also, add to table answers
        let updatedRepeatableSections = addEmptySection(data, tableAnswers);
        updateStateAndTable(updatedRepeatableSections);

        // Add to the sections shown
        setSectionsShown(sectionsShown + 1);
    };

    const removeRepeatableSection = (rowIdxToRemove) => {
        if (sectionsShown <= 1) return;
        const updated = removeItemWithIndex(tableAnswers, rowIdxToRemove);

        updateStateAndTable(updated);
        setSectionsShown(sectionsShown - 1); // Remove section shown
    };

    try {
        return (
            columns &&
            numRows &&
            exportType &&
            tableAnswers && (
                <Grid container>
                    <Grid item xs={12}>
                        <Table container>{[...Array(numRows)].map((e, i) => renderColumn(i))}</Table>
                    </Grid>
                    {sectionsShown < numRows && (
                        <AddNewRowContainer item xs={12} onClick={addRepeatableSection}>
                            <AddNewRowText>
                                <AddIcon src="/svgs/add_ad_section.svg" alt="" /> Add new section
                            </AddNewRowText>
                        </AddNewRowContainer>
                    )}
                </Grid>
            )
        );
    } catch (e) {
        return <h5>Error encountered</h5>;
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: state.form,
    };
};

const mapDispatchToProps = {};

RepeatableSectionsInput.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatableSectionsInput);
