import { transpose } from "./Helpers";
import { evaluate, format } from "mathjs";

// This function is the entry point for the parsing of a value
// It takes in the value object i.e: {defaultValue: "", calculatedValue: ""}
export const parseCalculatedVal = (value, tableCellKeys, tableColKeys) => {
  // If the default value is empty, just return the current value
  if (value.defaultValue == null || value.defaultValue === "") return value;

  // Split on all double quotes that are not escaped
  // let S = value.defaultValue.split(/((?<![\\])")/g);
  let S = value.defaultValue.split(/(?:^|\b|\s)"/g);

  let reconstructedStr = []; // Init the string to be reconstructed
  let stack = []; // Maintain a stack

  // Go over all the split strings
  for (let i = 0; i < S.length; i++) {

    // If it's a quote and stack is empty, push onto stack
    // This means we've started a string
    if (S[i] === '"' && stack.length === 0) stack.push(S[i]);
  
    // If it's a quote and the stack already has one
    // Pop it off the stack (this means we've reached the end of a string)
    else if (S[i] === '"' && stack.length != 0) stack.pop(S[i]);

    // Else, we need to either:
    // - Perform a calculation if the stack is empty (i.e: it's not a string)
    // - Add the string if it isn't empty, making sure to replace any pre-escaped
    //   strings inside
    else {
      if (stack.length === 0) {
        let currVal = S[i].trim();
        reconstructedStr.push(

          // Look for any ={} and replace with the calculated value
          currVal.replace(/(\={(?:\[??[^\[]*?\}))|"([0-9+ ]+)"/g, (val) => {
            let insideVal = val.slice(2, val.length - 1);
            return tryToCalc(insideVal, tableCellKeys, tableColKeys);
          })
        );
      } else reconstructedStr.push(S[i].replace(/\\/g, ""));
    }
  }

  // Join back the string and assign it to the calculated value
  value.calculatedValue = reconstructedStr.join("");
  return value;
};

// This function tries to perform the calculation
// If it fails, it will return an empty string
const tryToCalc = (val, tableCellKeys, tableColKeys) => {
  try {
    let subbedVal = substituteVals(val, tableCellKeys, tableColKeys);
    let evaluatedVal = format(evaluate(subbedVal), {notation: 'fixed'});
    
    if (evaluatedVal == null) return "";
    return evaluatedVal; // Try to mathjs evaluate
  } catch (e) {
    return "";
  }
};

// This function takes in the table cells and table col keys
// And returns the lowercase string with the replaced values
const substituteVals = (val, tableCellKeys, tableColKeys) => {
  return val
    .replace(/\b[A-Z][0-9]*\b(?!\()/g, (matchedVal) => {
      let trimmedVal = matchedVal.trim();
      if (trimmedVal in tableCellKeys)
        return tableCellKeys[trimmedVal].calculatedValue;
      else if (trimmedVal in tableColKeys) return tableColKeys[trimmedVal];
    })
    .toLowerCase();
};

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// Generate the table cell keys and table col keys
export const generateTableKeys = (answers) => {
  let table = transpose(answers);

  let tableCellKeys = {};
  let tableColKeys = {};

  // Generate keys
  for (let colIdx = 0; colIdx < table.length; colIdx++) {
    tableColKeys[alphabet[colIdx]] = table[colIdx].map((col) => {
      return col.calculatedValue;
    });
    for (let rowIdx = 0; rowIdx < table[colIdx].length; rowIdx++) {
      tableCellKeys[`${alphabet[colIdx]}${rowIdx + 1}`] = table[colIdx][rowIdx];
    }
  }

  return [tableCellKeys, tableColKeys];
};

// This performs any initial calculations pending on the table
export const performCalcOnInitialTable = (table, tableCells, cols) => {
  const getKeys = generateTableKeys(table);

  // Loop over rows and cols
  // Set to the parsed calculated val
  for (let rowIdx = 0; rowIdx < table.length; rowIdx++) {
    for (let colIdx = 0; colIdx < table[rowIdx].length; colIdx++) {
      let currCell = table[rowIdx][colIdx];
      if (tableCells != null && currCell.defaultValue != null) {
        table[rowIdx][colIdx] = parseCalculatedVal(currCell, getKeys[0], getKeys[1]);
        if (cols[colIdx].type != "datetime" || cols[colIdx].type != "dropdown") {
          table[rowIdx][colIdx] = parseCalculatedVal(currCell, getKeys[0], getKeys[1]);
        }
      }
    }
  }

  return table;
};
