const styles = theme => ({
  paper: {
    minHeight: theme.spacing.unit * 90,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 20,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  textField: {
    width: theme.spacing.unit * 60,
    margin: "auto",
    position: "relative"
  },
  layout: {
    minHeight: "100vh",
    width: "80%",
    marginTop: theme.spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  layoutPreview: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
  },
  paperPreview: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin:"0px 0px 24px -13px"
  },
  footerPreview: {
    background: "#E10050",
    color: "#fff",
    padding: "10px 0"
  },
  Button: {
    boxShadow: "none",
    width: "15%",
    color: "#2196F3",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3"
    }
  },
  textArea: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "max-content",
    fontSize: 11
  }
});

export {
  styles
}
