import React from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, Button } from "vardogyir-ui";
import { getResponseSubmitted } from "./component/NavigationSidebar/CommonComponents";
import { Refresh } from "@material-ui/icons";

const successHeader = <h2>Response submitted</h2>;

const failureHeader = <h2>Sorry, we've encountered an error</h2>;

const successText = <p>Your form session has been successfully submitted. Please keep this session ID for future reference and troubleshooting.</p>;

const failureText = <p>We’ve encountered an error while submitting your response. Please try again or contact us and reference the Form Session ID above</p>;

const SubmissionReceipt = (props) => {
    const onNewSubmission = () => {
        window.location.pathname = window.location.pathname.replace(window.location.pathname, `form/${props.formId}`);
    };

    const onBack = () => {
        if (props.testId !== undefined) {
            window.location.pathname = window.location.pathname.replace(window.location.pathname, `form/form_test/${props.formId}/${props.testId}/${props.sessionId}`);
        } else {
            window.location.pathname = window.location.pathname.replace(window.location.pathname, `form/${props.formId}/${props.sessionId}`);
        }
    };
    const dynamicSuccessText = props.submMsg;

    const getErrorState = () => {
        return (
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <i style={{ fontSize: 56, color: "#B00020" }} className="material-icons">
                        error
                    </i>
                </Grid>
                <Grid item xs={12}>
                    {failureHeader}
                </Grid>
                <Grid item xs={12} style={{ width: "inherit" }}>
                    <div style={{ textAlign: "left" }}>Session ID</div>
                    <TextField variant="outlined" value={props.sessionId} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                    {failureText}
                </Grid>
                <Grid item xs={12} style={{ width: "inherit" }}>
                    <Grid container direction="row-reverse" justify="flex-start" alignItems="center" spacing={2}>
                        {props.submissionState !== "success" && (
                            <Grid item>
                                <Button theme="P_blue" onClick={onBack}>
                                    Back
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const handleRestart = () => {
        if (!props || !props.formId) return;
        if (props.formType !== "form") return;
        window.open(`/form/${props.formId}`, "_self");
    }

    const getSuccessState = () => {
        return (
            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    {getResponseSubmitted(true)}
                </Grid>
                {dynamicSuccessText && (
                    <Grid item xs={12} style={{ textAlign: "left"}}>
                        {dynamicSuccessText}
                    </Grid>
                )}
                {props.formType === "form" && props.canAddSubmission &&
                    <Grid item xs={12} container alignContent="flex-end" justify="flex-end">
                        <Button variant="outlined" startIcon={<Refresh />} theme={"S_blue"} onClick={handleRestart}>
                            Restart
                        </Button>
                    </Grid>
                }
            </Grid>
        );
    };

    return <div style={{ maxWidth: "540px", padding: "0px 32px" }}>{props.submissionState && props.submissionState !== "failure" ? getSuccessState() : getErrorState()}</div>;
};

export default SubmissionReceipt;
