import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export function format(date, zone, format) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);

  date = String(date);

  if (date.includes("[UTC]")) date = date.substring(0, date.indexOf("[UTC]"));

  let utcCustomDate = new Date(date);

  if (date && date.includes && !date.includes("T")) {
    utcCustomDate = new Date(
      Date.UTC(
        utcCustomDate.getFullYear(),
        utcCustomDate.getMonth(),
        utcCustomDate.getDate(),
        utcCustomDate.getHours(),
        utcCustomDate.getMinutes(),
        utcCustomDate.getSeconds()
      )
    );
  }

  let timezonedDate = dayjs(new Date(Date.parse(utcCustomDate))).tz(zone);

  if (format === "relative") return toRelative(timezonedDate);
  if (format === "timeline") return print_timeline(timezonedDate)
  else return print_pretty(timezonedDate);
}

function print_pretty(date) {
  return dayjs(date).format("DD MMMM YYYY HH:mm");
}

function print_timeline(date) {
  return dayjs(date).format("DD MMMM YYYY") + "\n" + dayjs(date).format("HH:mm")
}

function toRelative(date) {
  return dayjs(date).fromNow();
}
