import React, { useState } from "react";
import { getRandomColor } from "../../ColourTextHelper";
import { format } from "../../utils/Date";

// MUI
import { Grid } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

// Util
import { send_request_graphql } from "../Request";
import { DELETE_COMMENT, EDIT_COMMENT } from "../Util/GraphQLQueries";
import EditBox from "./EditBox";
import { CommentContainer, EditOrDelete, PopoverDeleteText, ReplyItem, ReplyText, ReplyTopText, SystemItem, Time, Username } from "../Util/AnnotationStyledComponents";
import { breakToNewLine, newLineToBreak } from "../Util/AnnotationHelpers";
import CommentUsername from "./CommentUsername";

function CommentBubble({ comment, thread, comments, setComments, isEditingComment, setIsEditingComment, replyLoading, setReplyLoading, timezone, username, getLoader, idx, formId }) {
    const [anchorEl, setAnchorEl] = useState(null);

    // Handles deleting a given comment
    const handleDeleteComment = (comment) => {
        setReplyLoading(true);
        send_request_graphql(formId, DELETE_COMMENT(comment.commentId, username))
            .then((res) => {
                if (!res.data.deleteComment) return;

                // Remove the comment
                setAnchorEl(null);
                setReplyLoading(false);
                setComments(comments.filter((c) => c.commentId !== comment.commentId));
            })
            .catch((e) => {
                setReplyLoading(false);
                console.log(e);
            });
    };

    // Handles editing a comment
    const handleEditComment = (newValue, comment) => {
        if (newValue === comment.value) {
            setIsEditingComment(false);
            return;
        }

        newValue = newLineToBreak(newValue);

        const { commentId, creator } = comment;
        setReplyLoading(true);

        send_request_graphql(formId, EDIT_COMMENT(commentId, creator, newValue))
            .then((res) => {
                let editedComment = res.data.editComment;

                let commentIdx = comments.findIndex((c) => c.commentId === editedComment.commentId);
                if (!comments[commentIdx]) return;

                setIsEditingComment(false);
                setComments([...comments.slice(0, commentIdx), editedComment, ...comments.slice(commentIdx + 1)]);
                setReplyLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsEditingComment(false);
                setReplyLoading(false);
            });
    };

    // Gets the comment box
    // Either gets a user comment OR
    // A system comment i.e: a thread being opened or closed
    const getNewAnnotationBox = (comment) => {
        if (!comment) return;
        let calculatedcolour = getRandomColor(comment.creator);
        let date = comment.createdAt;

        // if (date.slice(-1) != "Z") date += "Z";
        let relativeDate = format(date, timezone, "relative");

        if (comment.commentType === "USER_TEXT") {
            return (
                <>
                    <Grid container id={comment.commentId} key={comment.commentId}>
                        <ReplyItem item calculatedcolour={calculatedcolour}>
                            <ReplyTopText item xs={12}>
                                <CommentContainer container direction="row">
                                    <CommentUsername username={comment.creator} isAuthed={comment.isAuthenticatedUser} />
                                    <Time>{relativeDate}</Time>
                                    {comment.creator === username && (
                                        <>
                                            <Time> | </Time>
                                            <EditOrDelete
                                                id={`${thread.annotationId}-edit-button-${idx}`}
                                                onClick={() => {
                                                    if (isEditingComment) setIsEditingComment(false);
                                                    else setIsEditingComment(comment);
                                                }}
                                            >
                                                {isEditingComment && isEditingComment.commentId === comment.commentId ? "Cancel Edit" : "Edit"}
                                            </EditOrDelete>
                                            <Time> | </Time>
                                            <>
                                                <EditOrDelete onClick={(e) => setAnchorEl(e.currentTarget)} id={`${thread.annotationId}-delete-button-${idx}`}>
                                                    Delete
                                                </EditOrDelete>
                                                <Popover
                                                    open={Boolean(anchorEl)}
                                                    anchorEl={anchorEl}
                                                    onClose={() => setAnchorEl(null)}
                                                    disableScrollLock={true}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            color: "#868686",
                                                            backgroundColor: "#ffffff",
                                                            padding: "8px",
                                                            paddingLeft: "0px",
                                                            fontSize: "12px",
                                                            borderRadius: "4px",
                                                            boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)",
                                                        },
                                                    }}
                                                >
                                                    <PopoverDeleteText onClick={() => handleDeleteComment(comment)} id={`${thread.annotationId}-confirm-delete-button-${idx}`}>
                                                        Permanently delete this comment
                                                    </PopoverDeleteText>
                                                </Popover>
                                            </>
                                        </>
                                    )}
                                </CommentContainer>
                            </ReplyTopText>
                            <ReplyText item xs={12}>
                                {breakToNewLine(comment.value)}
                            </ReplyText>
                        </ReplyItem>
                    </Grid>
                    {isEditingComment &&
                        isEditingComment.commentId === comment.commentId &&
                        (replyLoading ? getLoader("reply-box-loading-skeleton") : <EditBox comment={comment} handleEditComment={handleEditComment} thread={thread} />)}
                </>
            );
        } else if (comment.commentType === "SYSTEM") {
            return (
                <Grid container id={comment.commentId} key={comment.commentId}>
                    <SystemItem item calculatedcolour={calculatedcolour}>
                        <ReplyTopText item xs={12}>
                            <CommentContainer container direction="row">
                                <CommentUsername username={comment.creator} isAuthed={comment.isAuthenticatedUser} />
                                <Time>{relativeDate}</Time>
                            </CommentContainer>
                        </ReplyTopText>
                        <ReplyText item xs={12}>
                            {comment.value}
                        </ReplyText>
                    </SystemItem>
                </Grid>
            );
        }
    };
    return getNewAnnotationBox(comment);
}

export default CommentBubble;
