import { parsePlaceholder } from "../utils/detectAnswer";

var FormulaParser = require("hot-formula-parser").Parser;
var parser = new FormulaParser();
parser.setFunction("equal", function (params) {
  return params.toString();
});

export const createDefaultCondition = (statement) => {
  if (statement.Statement) {
    statement = statement.Statement;
  }

  if (statement.type === "IF") {
    //only for IF condition
    let condition = statement.content.Condition;

    if (condition) {
      let foundDefault = false;
      let lastValue = "";

      for (let a = 0; !foundDefault && a < condition.length; a++) {
        //going through all the condition and try to find the default value
        if (!condition[a].operators && condition[a].then.Statement) {
          foundDefault = true;
        } else {
          let val = handleValueStatement(condition[a].then.Statement);
          if (typeof val === "boolean") {
            //only handle boolean for now
            lastValue = val;
          } else {
            lastValue = val === "true";
          }
        }
      }

      if (!foundDefault) {
        //the default not found
        condition.push({
          then: {
            Statement: {
              type: "VALUE",
              content: !lastValue
            }
          }
        });
      }
    }
  }
};

export const executeStatement = (statement, formData, defaultValue) => {
  var result;
  if (statement.type === "VALUE") {
    result = handleValueStatement(statement);
  } else if (statement.type === "IF") {
    result = handleIFStatement(statement, formData, defaultValue);
  }

  return result;
};

const handleIFStatement = (statement, formData, defaultValue) => {
  let condition = statement.content.Condition;

  if (condition) {
    let toContinue = true;
    for (let a = 0; toContinue && a < condition.length; a++) {
      if (!condition[a].operators && condition[a].then.Statement) {
        //this is the default value
        toContinue = false;
        return executeStatement(
          condition[a].then.Statement,
          formData,
          defaultValue
        );
      }

      let operatorResult = evaluateConditionOperators(
        condition[a].operators,
        formData
      );

      if (operatorResult) {
        //true
        toContinue = false;
        return executeStatement(
          condition[a].then.Statement,
          formData,
          defaultValue
        );
      }
    }

    if (toContinue) {
      //we have gone through all the conditions but no result
      return defaultValue;
    }
  }
};

const evaluateConditionOperators = (operators, formData) => {
  let result = false;
  let toContinue = true;

  for (var a = 0; operators && toContinue && a < operators.length; a++) {
    let element = operators[a];
    if (!element.logic) {
      //no logic defined
      //this has to be element #1
      result = evaluateOperatorFunction(element, formData);
    } else {
      //there is a logic defined
      switch (element.logic) {
        case "AND":
          if (result) {
            let thisResult = evaluateOperatorFunction(element, formData);
            result = result && thisResult;
          } else {
            //the previous result was false, no need to continue
            toContinue = false;
          }
          break;
        case "OR":
          let thisResult = evaluateOperatorFunction(element, formData);
          result = result || thisResult;
          break;
      }
    }
  }

  return result;
};

const evaluateOperatorFunction = (operator, formData) => {
  let result = false;
  if (formData.answer_data[operator.key]) {
    var value = formData.answer_data[operator.key].answer;
    let realValue = "";

    if (Array.isArray(value)) {
      //array. it could be array of string or array of array
      for (var a = 0; a < value.length; a++) {
        let v = value[a];
        if (Array.isArray(v)) {
          realValue = realValue + v[0];
        } else {
          //assume it is a String
          realValue = realValue + v;
        }
      }
      value = realValue;
    }

    let expectedValue = operator.value;
    result = handleConditionalCases(operator, value, expectedValue, formData);
  } else {
    //no value, nothing to compare
    // result = false;
    var value = "";

    let expectedValue = operator.value;
    result = handleConditionalCases(operator, value, expectedValue, formData);
  }

  return result;
};

const handleConditionalCases = (operator, value, expectedValue, formData) => {

  value = parsePlaceholder(value, formData);
  expectedValue = parsePlaceholder(expectedValue, formData);
  
  let result = false;
  switch (operator.function) {
    case "GREATERTHAN":
      if (isNaN(value) || isNaN(expectedValue)) result = value > expectedValue;
      else result = parseInt(value) > parseInt(expectedValue);
      break;
    case "LESSTHAN":
      if (isNaN(value) || isNaN(expectedValue)) result = value < expectedValue;
      else result = parseInt(value) < parseInt(expectedValue);
      break;
    case "CONTAIN":
      result = value.includes(expectedValue);
      break;
    case "NOTCONTAIN":
      result = !value.includes(expectedValue);
      break;
    case "EQUAL":
      result = value == expectedValue;
      break;
    case "NOTEQUAL":
      result = value != expectedValue;
      break;
    case "HASNOTSELECTED":
      result = !value.includes(expectedValue);
      //result = value != expectedValue;
      break;
    case "HASSELECTED":
      result = value.includes(expectedValue);
      //result = value != expectedValue;
      break;
    case "MATCHES":
      result = value.trim() === expectedValue.trim();
      break;
    case "NOTMATCH":
      result = value.trim() !== expectedValue.trim();
      break;
    case "GREATERTHANOREQUAL":
      result = parseInt(value) >= parseInt(expectedValue);
      break;
    case "LESSTHANOREQUAL":
      result = parseInt(value) <= parseInt(expectedValue);
      break;
    case "EMPTY":
      result = value.trim() === "";
      break;
    case "NOTEMPTY":
      result = value.trim() !== "";
      break;
    default:
      result = value == expectedValue;
      break;
  }

  return result;
};

const handleValueStatement = (statement) => {
  return statement.content;
};

export const dynamicRender = (_this, data_default, data_dynamic) => {
  if (typeof data_dynamic === "undefined" || data_dynamic === "") {
    return data_default;
  } else {
    try {
      if (data_dynamic.Statement) {
        //new format
        let data = executeStatement(
          data_dynamic.Statement,
          _this.props.form,
          data_default
        );
        return data;
      } else {
        //assume old format
        let re = /\$\{\w*\}/g;
        let m;
        let patternMatched = false;
        while ((m = re.exec(data_dynamic))) {
          patternMatched = true;
          let key = m[0].replace("${", "").replace("}", "");
          let rep = "";
          if (_this.props.form.answer_data[key]) {
            if (
              typeof _this.props.form.answer_data[key].answer[0] == "object"
            ) {
              rep = `'`;
              console.log(key);
              for (let i in _this.props.form.answer_data[key].answer) {
                rep =
                  rep +
                  `${JSON.stringify(
                    _this.props.form.answer_data[key].answer[i]
                  )},`;
              }
              rep = rep.slice(0, -1) + `'`;
            } else {
              rep = `"${_this.props.form.answer_data[key].answer[0]}"`;
            }
          } else {
            rep = "";
            // return data_default;
          }

          data_dynamic = data_dynamic.replace(m[0], rep);
        }

        if (!patternMatched) {
          //the value is not the old dynamic formula pattern
          return data_dynamic;
        }

        const result = parser.parse(data_dynamic);
        console.log({ data_dynamic, result });
        if (result.error == null) {
          return result.result;
        } else {
          return data_default;
        }
      }
    } catch (e) {
      console.log(e);
      return data_default;
    }
  }
};
