import React from "react";
import JoditEditor from "jodit-pro-react";
import {withStyles} from "@material-ui/core/styles";
import ReactHtmlParser from 'react-html-parser';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";
import ErrorIcon from "@material-ui/icons/Error";
const styles = () => ({
    root: {

        "& .jodit-wysiwyg": {
            padding: "8px !important"
        }, marginTop: "1rem",
        "& .jodit-wysiwyg > p":{
            margin:"14px 0px !important"
        },
        "& .MuiAlert-standardError .MuiAlert-icon":{
            color:"#B00020"
        },
        "& .MuiAlert-standardError":{
            backgroundColor:"rgba(176, 0, 32, 0.15)",
            marginTop:"16px",width:"fit-content",
            color:"rgba(0, 0, 0, 0.87)",
            fontWeight:"400",
            border: "1px solid rgba(176, 0, 32, 1)"
        }
    },

});

const characterLimit = 1073741823;

class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: "",
            showWarning:false,
        };
    }
    setRef = (jodit) => (this.jodit = jodit);


    componentDidMount() {
        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            this.setState({answer: this.props.form.answer_data[this.props.data.key].answer[0] , showWarning:this.props.form.answer_data[this.props.data.key].answer[0].length> characterLimit})
        } else {
            if (this.props.data.data!==undefined && this.props.data.data.toString() && this.state.answer == '') {
                console.log(this.props.data.data);

                this.setState({answer: this.props.data.data, showWarning:this.props.data.data.length>characterLimit})
                this.props.getDataFromComponent({'text_answer': this.props.data.data}, this.props.data.key, this.props.data.question_text, this.props.data.data)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.data.data != prevProps.data.data) {

            this.props.getDataFromComponent({'text_answer': this.props.data.data}, this.props.data.key, this.props.data.question_text, this.props.data.data)
            this.setState({answer: this.props.data.data, showWarning:this.props.data.data.length>characterLimit})
        }

    }


    updateContent = (value,event) => {
        if((event && event.relatedTarget && event.relatedTarget.className && event.relatedTarget.className.startsWith("jodit")) )
        {
            // the above condition ensures that if any jodit button is clicked the focus is not lost
            return;
        }

        //can put it in on change as state change
        // causes the component to re-render
        if (value.length > characterLimit)
            this.setState({
                showWarning: true
            })
        else {
            this.setState({
                showWarning: false
            })
        }

        this.setState({
            answer: value,
        });
        this.props.getDataFromComponent({'text_answer': value}, this.props.data.key, this.props.data.question_text, this.props.data.data)


    };

    handleRecurseTree = (ele, key) => {
        let i = 0;

        const toBeTraversedArray = [ele];
        toBeTraversedArray.push(ele);

        while (toBeTraversedArray.length > 0) {
            let currentNode = toBeTraversedArray.shift();

            currentNode.id = `${key}-${i}`;
            if (i === 1) currentNode.className = "annotation-content-block";
            i++;

            if (currentNode.childNodes.length > 0) {
                toBeTraversedArray.push(...currentNode.childNodes);
            }
        }

        return ele;
    }


    addIdsToContentBlock = (html, key) => {
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(html, 'text/html');
        const newEle = this.handleRecurseTree(htmlDoc.body, key);
        return newEle.outerHTML;
    }

    doNothing = () => {
    }
    setRef = jodit => (this.jodit = jodit);

    JODIT_CONFIG={
        license: "CC7FX-1D761-AUOP5-JG0GX",
        limitChars:"1073741823", // java character limit
        limitHTML: true,
        defaultActionOnPaste: "insert_only_text",
        useIframeResizer: false,
        disablePlugins: ["keyboard", "google-search", "fullsize", "about", "classSpan", "google-maps", "export-docs", "source","paste","paste-code","button-generator","iframe-editor","search"],
        toolbarAdaptive: false,
        showXPathInStatusbar: false,
        minHeight: "500px",
        countHTMLChars:true,
        readonly: this.props.data.read_only,
        spellcheck: true
    }
    render() {
        const {data, classes, isAnnotationModeOn} = this.props;
        return (!isAnnotationModeOn ? <div className={classes.root}>

            <JoditEditor
                value={this.state.answer}
                config={this.JODIT_CONFIG}
                tabIndex={1} // tabIndex of textarea
                onBlur={(updatedData,event)=>this.props.data.read_only ? this.doNothing : this.updateContent(updatedData,event)}
             />
            <Alert severity={"error"}
                   icon={<ErrorIcon/>}
                   style={{
                       display: this.state.showWarning ? null : "none",
                   }}>
                You have reached the character limit for this editor
            </Alert>
            </div> : ReactHtmlParser(this.addIdsToContentBlock(this.state.answer, this.state.key)));
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {};


RichTextEditor.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RichTextEditor));

