export const CREATE_ANNOTATION_WITH_COMMENT = (annotationId, formId, formSessionId, itemId, startIndex, endIndex, highlightedText, creator, value, commentType) => {
    return `mutation {
          createAnnotation(annotationId: "${annotationId}", formId: "${formId}", formSessionId: "${formSessionId}", itemId: "${itemId}", startIndex: ${startIndex}, endIndex: ${endIndex}, highlightedText: "${highlightedText}" creator: "${creator}", value: "${value}", commentType: "${commentType}") {
            annotationId
            formId
            formSessionId
            createdAt
            itemId
            startIndex
            endIndex
            isOpen
            highlightedText
            annotationCreator
            isAuthenticatedUser
          }
      }`;
};

export const CREATE_ANNOTATION_WITHOUT_COMMENT = (annotationId, formId, formSessionId, itemId, startIndex, endIndex, highlightedText, annotationCreator) => {
    return `mutation {
          createAnnotationWithoutComment(annotationId: "${annotationId}", formId: "${formId}", formSessionId: "${formSessionId}", itemId: "${itemId}", startIndex: ${startIndex}, endIndex: ${endIndex}, highlightedText: "${highlightedText}" annotationCreator: "${annotationCreator}") {
            annotationId
            formId
            formSessionId
            createdAt
            itemId
            startIndex
            endIndex
            isOpen
            highlightedText
            annotationCreator
            isAuthenticatedUser
          }
      }`;
};

export const CREATE_ANNOTATION_AND_RETURN_ALL = (annotationId, formId, formSessionId, itemId, startIndex, endIndex, highlightedText, annotationCreator) => {
    return `mutation {
          createAnnotationAndReturnAll(annotationId: "${annotationId}", formId: "${formId}", formSessionId: "${formSessionId}", itemId: "${itemId}", startIndex: ${startIndex}, endIndex: ${endIndex}, highlightedText: "${highlightedText}" annotationCreator: "${annotationCreator}") {
            annotationId
            formId
            formSessionId
            createdAt
            itemId
            startIndex
            endIndex
            isOpen
            highlightedText
            annotationCreator
            isAuthenticatedUser
          }
      }`;
};

export const CREATE_COMMENT = (annotationId, creator, value, commentType, openOrClose) => {
    return `mutation {
          createComment(annotationId: "${annotationId}", creator: "${creator}", value: "${value}", commentType: "${commentType}", openOrClose: ${openOrClose}) {
            commentId
            createdAt
            creator
            value
            commentType
            isAuthenticatedUser
          }
      }`;
};

export const EDIT_COMMENT = (commentId, creator, value) => {
    return `mutation {
          editComment(commentId: "${commentId}", creator: "${creator}", value: "${value}") {
            commentId
            createdAt
            creator
            value
            commentType
            isAuthenticatedUser
          }
      }`;
};

export const DELETE_COMMENT = (commentId, currentUser) => {
    return `mutation {
          deleteComment(commentId: "${commentId}", currentUser: "${currentUser}")
      }`;
};

export const DELETE_ANNOTATION = (annotationId, currentUser) => {
    return `mutation {
          deleteAnnotation(annotationId: "${annotationId}", currentUser: "${currentUser}")
      }`;
};

export const GET_ALL_ANNOTATIONS = (formId, formSessionId) => {
    return `{
        getAnnotationsByFormIdAndFormSessionId(formId: "${formId}", formSessionId: "${formSessionId}") {
            annotationId
            formId
            formSessionId
            createdAt
            itemId
            startIndex
            endIndex
            isOpen
            highlightedText
            annotationCreator
            isAuthenticatedUser
          }
      }`;
};

export const GET_HIGHLIGHTS = (formId, formSessionId) => {
    return `{
        getAnnotationsByFormIdAndFormSessionId(formId: "${formId}", formSessionId: "${formSessionId}") {
            annotationId
            itemId
            startIndex
            endIndex
            annotationCreator
            isAuthenticatedUser
          }
      }`;
};

export const GET_HIGHLIGHT_COUNT = (formId, formSessionId) => {
    return `{
        getNumberOfAnnotationsByFormIdAndFormSessionId(formId: "${formId}", formSessionId: "${formSessionId}")
      }`;
};

export const GET_ANNOTATION_COMMENTS = (annotationId) => {
    return `{
        getCommentsByAnnotationId(annotationId: "${annotationId}") {
            commentId
            createdAt
            creator
            value
            commentType
            isAuthenticatedUser
          }
      }`;
};

export const GET_ANNOTATION_BY_ID = (annotationId) => {
    return `{
        getAnnotationById(annotationId: "${annotationId}") {
              annotationId
              formId
              formSessionId
              createdAt
              itemId
              startIndex
              endIndex
              isOpen
              isAuthenticatedUser
          }
      }`;
};
