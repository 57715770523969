export const JSON_EXCEPTION = 'JSON_EXCEPTION';
export const REQUEST_EXCEPTION = 'REQUEST_EXCEPTION';
export const UNKNOWN_EXCEPTION = 'UNKNOWN_EXCEPTION';
export const AppException = (type, code = 0, message = '', extraInfo = {}) => ({
  type: type,
  payload: {
    errorCode: code,
    message,
    extraInfo
  }
});

export const JSONException = (e, extraInfo = {}) => ({
  type: JSON_EXCEPTION,
  payload: {
    e,
    extraInfo
  }
});

export const UnknownException = (e, extraInfo = {}) => ({
  type: UNKNOWN_EXCEPTION,
  payload: {
    e,
    extraInfo
  }
});
