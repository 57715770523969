import {
  Grid,
  Container,
  TextField,
  withStyles,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Auth, Hub } from "aws-amplify";
import { Button } from "vardogyir-ui";
import React, { Component } from "react";
import UserAccount from "./component/UserAccount";
import axios from "axios";
import config from "../config";

const styles = (theme) => ({
  wrapper: {
    backgroundImage: 'radial-gradient(rgb(197, 197, 197) 1px, transparent 0px)',
    backgroundSize: '40px 40px',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'fit-content',
    maxWidth: '400px',
    margin: '24px auto',
    padding: '32px',
    borderRadius: '6px',
    border: '1px solid #000',
    background: '#FFF',
    boxShadow: '8px 8px 0px 0px rgba(0, 0, 0, 0.25)',
  },
  logo: {
    height: 'auto',
    minHeight: '40px',
    maxWidth: '100%',
    marginBottom: '20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '10px',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flexGrow: 1,
    marginRight: '10px',
  },
  button: {
    minWidth: '40px',
    height: '40px',
    padding: '0',
  },
});

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = { form_id: "", logged: false, loadingScreen: false };
  }
  componentDidMount() {
    this.setState({
      loadingScreen: true
    })
    let formURL = null
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "customOAuthState":
          formURL = data.payload.data
          break;
        default:
          break;
      }
    })

    Auth.currentSession().then(async (userSession) => {
      if (userSession) {
        let idToken = userSession.getIdToken().getJwtToken()
        if (idToken){
          await axios.post(
            `${config.USER_ADDRESS}/check-user-verified-and-completed`,
            "",
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }
          ).then((res) => {
            if (res.data){
              if (!res.data.verified || !res.data.isCompleted){
                window.open(config.ADMIN_DASHBOARD_URL, '_blank');
                if (formURL){
                  window.location.href = formURL
                }
                this.setState({
                  logged: true
                })
                setTimeout(() => {
                  this.setState({
                    loadingScreen: false
                  });
                },3000)

              }else {
                if (formURL){
                  window.location.href = formURL
                }
                this.setState({
                  logged: true
                })
                setTimeout(() => {
                  this.setState({
                    loadingScreen: false
                  });
                },3000)
              }
            }
          }).catch((error) => {
            console.log(error)
            this.setState({
              loadingScreen: false
            })
          })
        }
      }
    }).catch(() => {
      this.setState({
        loadingScreen: false
      })
    })

  }



  handleSubmit = () => {
    this.props.history.push(`/form/${this.state.form_id}`);
  };

  render() {
    const { classes } = this.props;
    const { logged } = this.state;

    if (!this.state.loadingScreen){
      return (
        <div className={classes.wrapper}>
          <Container className={classes.container}>
            <a href="https://www.workflow86.com" target="_blank" rel="noopener noreferrer">
              <img className={classes.logo} src={"/images/workflow86-logo-text.jpg"} alt="Workflow86" />
            </a>
            <p className={classes.title}>Looking for a form? Enter the form ID below</p>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter a form ID here"
                value={this.state.form_id}
                onChange={(event) => this.setState({ form_id: event.target.value })}
              />
              <Button
                className={classes.button}
                type="submit"
                theme="black"
                variant="contained"
                disabled={!this.state.form_id}
              >
                <span>&#8594;</span>
              </Button>
            </form>
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              direction={"row"}
              justify={"center"}
              style={{ marginTop: '16px' }}
            >
              {logged ? (
                <>
                  <p>You are currently logged in as </p>
                  <UserAccount />
                </>
              ) : (
                <p>Have an account? <a href="https://app.workflow86.com/">Sign in</a></p>
              )}
            </Grid>
          </Container>
        </div>
      );
    }else {
      return (
        <div className={classes.wrapper}>
          <CircularProgress size={50} />
        </div>
      )
    }
  }
}

export default withStyles(styles)(Verify);